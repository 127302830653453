import React, { useEffect, useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import axios from 'axios';
import Moment from 'react-moment';
import fileDownload from 'js-file-download';
import { decode } from 'js-base64';

import { API_BASE_URL, API_BASE_IMG_URL } from '../../config';
// @material-ui/core components
import { makeStyles, useTheme } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import Button from 'components/CustomButtons/Button.js';
import loader from '../../assets/img/loader.svg';

// components for table.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

const styles = {
  input: {
    height: '20px',
    width: '20px',
  },

  inputCursor: {
    cursor: 'pointer',
    height: '20px',
    width: '20px',
  },
};

const useStyles = makeStyles(styles);
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));
export function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'>
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'>
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'>
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'>
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function AmazonTransactions() {
  const classes = useStyles();
  const [msg, setMsg] = React.useState(null);
  const [errormsg, setErrormsg] = React.useState(null);
  const [redirects, setRedirects] = React.useState(false);
  const [paymentdetailid, setPaymentdetailid] = React.useState(null);
  const [sortBy, setSortBy] = useState({ name: 'status_updatedat', order: -1 });
  const [sortedPayments, setSortedPayments] = useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [payments, setPayments] = useState([]);
  const [facilityID, setFacilityID] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [button, setButton] = useState(false);
  const [total, setTotal] = useState(0);
  const [checkbox, setCheckbox] = useState(false);
  const [checkboxall, setCheckboxall] = useState(false);

  const changestatus = () => {
    setLoaded(true);

    let state = localStorage['appState'];
    let AppState;
    if (state) {
      AppState = JSON.parse(state);
    }

    let token = AppState && AppState.user && AppState.user.accessToken;

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    // API call to fetch data for table in transactions
    axios
      .get(API_BASE_URL + '/pending-giftcards', {
        headers: headers,
      })
      .then((response) => {
        return response;
      })
      .then((paymentdata) => {
        console.log('refresh');
        setPayments(paymentdata.data.data);
        setLoaded(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateAmount = () => {
    let total = 0;
    payments.forEach((payment) => {
      if (payment.refund_status === 'Pending') {
        if (payment.selected) {
          total += +payment.refund_amount;
        }
      }
    });
    setTotal(total);
  };

  const isAllSelected = (paymentsArray) => {
    const isSelected = paymentsArray.every((payment) => {
      return payment.selected || payment.refund_status === 'Completed';
    });
    setCheckboxall(isSelected);
  };
  const isAllComplete = () => {
    const isAllCompleted = payments.every((payment) => {
      return payment.refund_status === 'Completed';
    });
    return isAllCompleted;
  };
  const handleOnSelect = (position, isChecked) => {
    setSortedPayments((payment) => {
      const paymentArr = [...payment];
      paymentArr[position].selected = !isChecked;
      isAllSelected(paymentArr);
      console.log(position, isChecked);
      payments.map((obj, index) => {
        if (obj.selected === true) {
          setButton(true);
        }
      }, setButton(false));

      updateAmount();
      setCheckbox(!checkbox);

      return paymentArr;
    });
  };

  const handleSelectAll = (isChecked) => {
    setPayments((payments) => {
      const paymentArr = payments.map((payment) => {
        payment.selected = !isChecked;
        return payment;
      });
      !isChecked ? updateAmount() : setTotal(0);
      return paymentArr;
    });
    setButton(!isChecked);
    setCheckboxall(!isChecked);
  };

  const handleCoupon = (refund_id, facility_id) => {
    setLoaded(true);
    let slugs = refund_id;

    const formData = new FormData();
    formData.append('refund_id', slugs);
    axios
      .post(API_BASE_URL + '/amazon_payout', formData)
      .then((response) => {
        return response;
      })
      .then((res) => {
        console.log(res);
        if (res.data.error) {
          setLoaded(false);
          setErrormsg(res && res.data && res.data.msg);
          setTimeout(() => {
            setErrormsg(null);
            setMsg(null);
          }, 4000);
        } else {
          setLoaded(false);
          setMsg(res && res.data && res.data.msg);
          setTimeout(() => {
            setErrormsg(null);
            setMsg(null);
            setPaymentdetailid(
              res && res.data && res.data.resultdata && res.data.resultdata.id
            );
            setRedirects(true);
          }, 4000);
        }
        changestatus();
      });
  };

  const handleCouponAll = (e) => {
    payments.map(
      (obj) =>
        obj.refund_status == 'Pending' &&
        obj.selected == true &&
        handleCoupon(obj.id, obj.facility_id)
    );
  };

  // functions for pagination.
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, payments.length - page * rowsPerPage);

  useEffect(() => {
    setLoaded(true);

    let state = localStorage['appState'];
    let AppState;
    if (state) {
      AppState = JSON.parse(state);
    }
    let userId = AppState && AppState.user && AppState.user.id;

    let token = AppState && AppState.user && AppState.user.accessToken;
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(API_BASE_URL + '/pending-giftcards', { headers: headers })
      .then((response) => {
        return response;
      })
      .then((paymentdata) => {
        setPayments(paymentdata.data.data);
        // console.log(paymentdata.data);
        setLoaded(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const sortByColumn = (columnName) => {
    setSortBy({
      name: columnName,
      order:
        sortBy.name === columnName
          ? sortBy.order === 1
            ? -1
            : sortBy.order === -1
            ? 0
            : sortBy.order === 0
            ? 1
            : 1
          : 1,
    });
  };

  const sortArrow = (columnName) => {
    // console.log(sortBy.order);
    return columnName === sortBy.name ? (
      sortBy.order === 1 ? (
        <ArrowDownward fontSize='small' />
      ) : sortBy.order === -1 ? (
        <ArrowUpward fontSize='small' />
      ) : sortBy.order === 0 ? (
        ''
      ) : (
        ''
      )
    ) : (
      ''
    );
  };
  useEffect(() => {
    console.log(sortBy.order, sortBy.name);
    const array = [...payments];

    const sortedArray = array.sort((payment1, payment2) => {
      if (payment1[sortBy.name] < payment2[sortBy.name]) {
        return -1;
      }
      if (payment1[sortBy.name] > payment2[sortBy.name]) {
        return 1;
      }
      return 0;
    });
    setSortedPayments(
      sortBy.order === 1
        ? sortedArray
        : sortBy.order === -1
        ? sortedArray.reverse()
        : [...payments]
    );
  }, [payments, sortBy.order, sortBy.name]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color='primary'>
            <h4>Gift Card Transactions</h4>
          </CardHeader>

          <CardBody>
            <div>
              {loaded && (
                <div className='loaders'>
                  <img src={loader} />
                </div>
              )}
              <div className='t-header'>
                <div
                  className='t-header-left'
                  style={{ fontWeight: 'bold', fontSize: '15px' }}>
                  Total = $
                  {parseFloat(total).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
                <div className='t-header-right'>
                  {button ? (
                    <Button
                      style={{
                        cursor: 'pointer',
                      }}
                      color='primary'
                      id='dt'
                      onClick={(e) => handleCouponAll(e)}>
                      Generate Coupon
                    </Button>
                  ) : (
                    <Button disabled pointerEvents='none' id='dt'>
                      Generate Coupon
                    </Button>
                  )}
                </div>
              </div>
              <Table width='100%' id='template'>
                <TableHead>
                  <TableRow>
                    {/* <TableCell align='left'>
                      <input
                        type='checkbox'
                        id='allSelect'
                        name='allSelect'
                        checked={checkboxall}
                        disabled={isAllComplete()}
                        onChange={() => handleSelectAll(checkboxall)}
                        style={styles.inputCursor}
                      />
                    </TableCell> */}
                    <TableCell
                      align='left'
                      onClick={() => sortByColumn('status_updatedat')}>
                      Date {sortArrow('status_updatedat')}{' '}
                    </TableCell>
                    <TableCell
                      align='left'
                      onClick={() => sortByColumn('last_name')}>
                      Customer {sortArrow('last_name')}
                    </TableCell>
                    <TableCell
                      align='left'
                      onClick={() => sortByColumn('email')}>
                      Email {sortArrow('email')}
                    </TableCell>
                    <TableCell
                      align='left'
                      onClick={() => sortByColumn('additional_info')}>
                      Brand {sortArrow('additional_info')}
                    </TableCell>
                    <TableCell
                      align='left'
                      onClick={() => sortByColumn('refund_amount')}>
                      Amount($) {sortArrow('refund_amount')}
                    </TableCell>
                    <TableCell
                      align='left'
                      onClick={() => sortByColumn('refund_status')}>
                      Payment Status {sortArrow('refund_status')}
                    </TableCell>
                    {/* <TableCell align='left'></TableCell> */}
                  </TableRow>
                </TableHead>
                {sortedPayments && sortedPayments.length > 0 ? (
                  <TableBody>
                    {(rowsPerPage > 0
                      ? sortedPayments.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : sortedPayments
                    ).map((obj, index) => (
                      <TableRow className='txtalign' key={obj.id}>
                        {/* <TableCell width='15%'>
                          <input
                            type='checkbox'
                            id={obj.id}
                            name={obj.first_name}
                            style={
                              obj.refund_status == 'Completed'
                                ? styles.input
                                : styles.inputCursor
                            }
                            checked={
                              obj.refund_status == 'Completed'
                                ? true
                                : obj.selected ?? false
                            }
                            disabled={
                              obj.refund_status == 'Completed' ? true : false
                            }
                            onChange={() =>
                              handleOnSelect(index, obj.selected ?? false)
                            }
                          />
                        </TableCell> */}
                        <TableCell width='15%'>
                          {obj && obj.status_updatedat && (
                            <Moment format='MM-DD-YYYY'>
                              {obj && obj.status_updatedat}
                            </Moment>
                          )}
                        </TableCell>
                        <TableCell width='25%'>
                          {obj && obj.first_name} {obj && obj.last_name}
                        </TableCell>
                        <TableCell width='15%'>{obj && obj.email}</TableCell>
                        <TableCell width='20%'>
                          {obj && obj.additional_info}
                        </TableCell>
                        <TableCell width='5%'>
                          {parseFloat(obj && obj.refund_amount).toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            }
                          )}
                        </TableCell>
                        <TableCell width='25%'>
                          {obj && obj.refund_status && (
                            <a
                              style={{
                                cursor: 'pointer',
                                color:
                                  obj && obj.refund_status == 'Completed'
                                    ? ''
                                    : '#ff0000',
                              }}
                              onClick={(e) =>
                                changestatus(e, obj.refund_id, obj.comp_id)
                              }>
                              {obj && obj.refund_status == 'Completed'
                                ? 'Completed'
                                : 'Pending'}
                            </a>
                          )}
                        </TableCell>
                        {/* <TableCell width='15%'>
                          {obj && obj.refund_status && (
                            <a
                              style={{
                                color:
                                  obj && obj.refund_status == 'Completed'
                                    ? ''
                                    : '#ff0000',
                              }}>
                              {obj && obj.refund_status == 'Completed' ? (
                                <Button disabled pointerEvents='none' id='dt'>
                                  Generate Coupon
                                </Button>
                              ) : (
                                <Button
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  color='primary'
                                  id='dt'
                                  onClick={(e) =>
                                    handleCoupon(obj.id, obj.facility_id)
                                  }>
                                  Generate Coupon
                                </Button>
                              )}
                            </a>
                          )}
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell>No Gift Card Transaction Data Found</TableCell>
                    </TableRow>
                  </TableBody>
                )}
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: 'All', value: -1 },
                      ]}
                      colSpan={3}
                      count={payments.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
