import React, { Component } from 'react';

import ReactDOM from 'react-dom';
import { Link, Redirect } from 'react-router-dom';
import { API_BASE_URL, API_BASE_IMG_URL } from '../../config';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import axios from 'axios';
import $ from 'jquery';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardAvatar from 'components/Card/CardAvatar.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import FormControl from '@material-ui/core/FormControl';
import loader from '../../assets/img/loader.svg';

import avatar from 'assets/img/faces/marc.jpg';
import { getFacilities } from 'views/Facilities/Facilities';
const md5 = require('md5');

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

export default class UpdateCharity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      address: '',
      password: '',
      phone: '',
      redirect: false,
      image_upload: '',
      file: null,
      city: '',
      state: '',
      zipcode: '',
      status: '',
      fileimg: '',
      facility: '',
      url: '',
      description: '',
      facilities: [{}],
      loaded: false,
    };
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.autocomplete = null;
    this.handleName = this.handleName.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleAddress = this.handleAddress.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleCity = this.handleCity.bind(this);
    this.handleState = this.handleState.bind(this);
    this.handleZipcode = this.handleZipcode.bind(this);
    this.handleFacility = this.handleFacility.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  remove() {
    this.setState({ fileimg: false });
  }
  handleSubmit(event) {
    event.preventDefault();
    //this.props.dispatch(addParlor(this.state))
    this.clearForm();
  }

  handleFacility(event) {
    this.setState({ facility: event.target.value });
  }
  handleCity(event) {
    this.setState({ city: event.target.value });
  }

  handleState(event) {
    this.setState({ state: event.target.value });
  }
  handleZipcode(event) {
    this.setState({ zipcode: event.target.value });
  }
  handleUploadFile = (event) => {
    this.setState({
      file: event.target.files[0],
      fileimg: URL.createObjectURL(event.target.files[0]),
    });
  };
  cancelfrm = () => {
    this.setState({ redirect: true });
  };

  /*** functions to set input values in state***/

  handleName(event) {
    this.setState({ name: event.target.value });
  }
  handleEmail(event) {
    this.setState({ email: event.target.value });
  }
  handleAddress(event) {
    this.setState({ address: event.target.value });
  }
  handlePhone(event) {
    this.setState({ phone: event.target.value });
  }
  handleUrl = (e) => {
    this.setState({ url: e.target.value });
  };
  handleDescription = (e) => {
    this.setState({ description: e.target.value });
  };

  handlePlaceSelect() {
    let addressObject = this.autocomplete.getPlace();
    let address = addressObject.address_components;
    let addressdata = addressObject && addressObject.adr_address;
    let zip_code;
    let city_data;
    this.setState({ city: '', state: '', zipcode: '' });

    for (let i = 0; i < address.length; i++) {
      //console.log("objectdataff",address[i])
      if (address[i] && address[i].types[0] == 'postal_code') {
        zip_code = address[i].long_name;
      }
      if (address[i] && address[i].types[0] == 'locality') {
        city_data = address[i].long_name;
      }
    }

    if (address && address[4]) {
      this.setState({ zipcode: zip_code });
    }
    if (address && address[0]) {
      this.setState({ city: city_data });
    }
    if (address && address[2]) {
      this.setState({ state: address[2].long_name });
    }
    this.setState({ address: addressObject.formatted_address });
    /*this.setState({
		  name: addressObject.name,
		  street_address: `${address[0].long_name} ${address[1].long_name}`,
		  city: address[4].long_name,
		  state: address[6].short_name,
		  zipcode: address[8].short_name,
		  googleMapLink: addressObject.url
		})*/
    /*this.setState({
		  name: addressObject.name,
		  street_address: `${address[0].long_name} ${address[1].long_name}`,
		  city: address[4].long_name,
		  state: address[6].short_name,
		  zipcode: address[8].short_name,
		  googleMapLink: addressObject.url
		})*/
  }
  componentDidMount() {
    this.setState({ loaded: true });
    const google = window.google;
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      {}
    );

    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);

    let state = localStorage['appState'];
    let AppState;
    if (state) {
      AppState = JSON.parse(state);
    }

    let token = AppState && AppState.user && AppState.user.accessToken;

    if (AppState && AppState.user && AppState.user.admin_type != 'admin') {
      window.location.href = '/client/error';
    }

    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    };

    let slug =
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id;
    axios
      .post(API_BASE_URL + '/charitybyid_admin/' + slug)
      .then((response) => {
        return response;
      })
      .then((charity) => {
        //console.log("facility",facility)
        let charitydata = charity && charity.data;
        //console.log("facility",facilitydata)
        this.setState({
          facility: charitydata.facility_id,
          name: charitydata.name,
          email: charitydata.email,
          address: charitydata.address,
          phone: charitydata.phone,
          city: charitydata.city,
          state: charitydata.state,
          zipcode: charitydata.zipcode,
          status: charitydata.status,
          url: charitydata.url,
          description: charitydata.description,
          loaded: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    getFacilities({ facility: true })
      .then((facility) => {
        //console.log("facilities",facility && facility.data)
        this.setState({ facilities: facility && facility.data, loaded: false });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  /*** function to integrate api to update facility*/

  updatefacility = () => {
    let slug =
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id;

    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    if (this.state.name == '') {
      alert('Please enter charity name');
      $('#name').focus();
      return false;
    } else if (this.state.email == '') {
      alert('Please enter charity email');
      $('#email').focus();
      return false;
    } else if (this.state.city == '') {
      alert('Please enter city');
      $('#city').focus();
    } else if (this.state.state == '') {
      alert('Please enter state');
      $('#state').focus();
    } else if (this.state.zipcode == '') {
      alert('Please enter zipcode');
      $('#phone').focus();
    } else if (this.state.address == '') {
      alert('Please enter charity address');
      $('#address').focus();
      return false;
    } else if (this.state.phone == '') {
      alert('Please enter charity phone');
      $('#phone').focus();
    } else if (!Number(this.state.phone)) {
      alert('Phone should be in numbers only');
      $('#phone').focus();
      return false;
    } else if (!Number(this.state.phone)) {
      alert('Phone should be in numbers only');
      $('#phone').focus();
      return false;
    } else if (this.state.phone.length > 12) {
      alert('Phone number length should not be greater than 12');
      $('#phone').focus();
      return false;
    } else {
      this.setState({ loaded: true });

      const formData = new FormData();

      formData.append('name', this.state.name);
      formData.append('email', this.state.email);
      formData.append('address', this.state.address);
      formData.append('phone', this.state.phone);
      formData.append('city', this.state.city);
      formData.append('state', this.state.state);
      formData.append('zipcode', this.state.zipcode);
      formData.append('facility_id', this.state.facility);
      formData.append('url', this.state.url);
      formData.append('description', this.state.description);

      let state = localStorage['appState'];
      let AppState;
      if (state) {
        AppState = JSON.parse(state);
      }

      let token = AppState && AppState.user && AppState.user.accessToken;

      const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      };

      axios
        .put(API_BASE_URL + '/editcharity/' + slug, formData, {
          headers: headers,
        })
        .then((response) => {
          return response;
        })
        .then((res) => {
          this.setState({ redirect: true, loaded: false });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to='/admin/charities' />;
    }

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color='primary'>
                <h4>Update facility</h4>
              </CardHeader>
              <CardBody>
                {this.state.loaded && (
                  <div className='loaders'>
                    <img src={loader} />
                  </div>
                )}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      type='text'
                      labelText='Name'
                      onChange={this.handleName}
                      value={this.state.name}
                      id='name'
                      name='name'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Email'
                      id='email'
                      name='email'
                      onChange={this.handleEmail}
                      value={this.state.email}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Phone'
                      id='phone'
                      name='phone'
                      onChange={this.handlePhone}
                      value={this.state.phone}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Address'
                      id='autocomplete'
                      name='address'
                      ref='input'
                      onChange={this.handleAddress}
                      value={this.state.address}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='City'
                      id='city'
                      name='city'
                      onChange={this.handleCity}
                      value={this.state.city}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='State'
                      id='state'
                      name='state'
                      onChange={this.handleState}
                      value={this.state.state}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Zipcode'
                      id='zipcode'
                      name='zipcode'
                      onChange={this.handleZipcode}
                      value={this.state.zipcode}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Url'
                      id='url'
                      name='url'
                      onChange={this.handleUrl}
                      value={this.state.url}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Description'
                      id='description'
                      name='description'
                      onChange={this.handleDescription}
                      value={this.state.description}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    {/*<!--<label>Status</label>
                  <select name="status" className="MuiInputBase-input MuiInput-input" style={{border:"1px solid lightgrey",marginTop:'20px'}} id="status" onChange={this.handleStatus} value={this.state.status}>
				  <option value="1">Active</option>
				  <option value="0">Inactive</option>
  </select>-->*/}
                    <div className='custom-select'>
                      <FormControl>
                        <InputLabel id='demo-simple-select-label'>
                          Facilities
                        </InputLabel>
                        <Select
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          value={this.state.facility}
                          onChange={this.handleFacility}
                          className='full-width-elem'>
                          {this.state.facilities &&
                            this.state.facilities.length > 0 &&
                            this.state.facilities.map((obj, index) => (
                              <MenuItem value={obj && obj.id}>
                                {obj && obj.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Button color='primary' onClick={this.updatefacility}>
                      Update
                    </Button>
                    <Button
                      color='primary'
                      onClick={this.cancelfrm}
                      className='btn-cancel'
                      style={{ marginLeft: '5px' }}>
                      Cancel
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}></GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
