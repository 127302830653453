import React, { useState, useEffect } from 'react';
// react plugin for creating charts
import { Link, Redirect } from 'react-router-dom';

import { API_BASE_URL, API_BASE_IMG_URL } from '../../config';
import axios from 'axios';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';

import Accessibility from '@material-ui/icons/Accessibility';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';

import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import { CSVLink } from 'react-csv';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button from 'components/CustomButtons/Button.js';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import search from '../../assets/img/search.svg';
import { getFacilities } from 'views/Facilities/Facilities';
import BarChart from './BarChart';
import QuarterBarChart from './QuarterBarChart';
import DoughnutChart from './DoughnutChart';
import moment from 'moment';

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const [facilityCount, setFacilityCount] = useState(0);
  const [refunds, setRefunds] = useState(0);

  const [startdate, setStartdate] = useState(
    moment().subtract(1, 'month').toDate()
  );
  const [enddate, setEnddate] = useState(new Date());
  const [refundMethodsTransaction, setRefundMethodsTransaction] = useState([]);
  const [facilityID, setFacilityID] = useState(null);
  const [claimed, setClaimed] = useState(0);
  const [unclaimed, setUnclaimed] = useState(0);
  const [facility, setFacility] = useState(null);

  const [pieChartData, setPieChartData] = useState({
    claimed: 0,
    unclaimed: 0,
    escheated: 0,
  });
  // functions for refund charts.
  /************************** Change start date *********************/
  const changeDate = (event) => {
    setStartdate(event);
  };

  /**************************  Change end date **************************/
  const changeEndDate = (event) => {
    //console.log(event);
    setEnddate(event);
  };

  const handleFacility = (e) => {
    if (e.target.value === 'null') {
      setFacilityID(null);
    }

    setFacilityID(e.target.value);
  }; //  function to format the  start and end dates

  const searchReport = () => {
    if (startdate > enddate) {
      alert('Start date should be less than end date');
    } else {
      // sending the formdata with ID to backend and recieving respective data.
      axios
        .post(API_BASE_URL + '/search_refund_report_admin', {
          start_date: startdate,
          end_date: enddate,
          facility_id: facilityID,
        })
        .then((res) => {
          setPieChartData(res.data.totals);
          setRefundMethodsTransaction(res.data.countAndTotalPerMethod);
        })
        .catch((err) => {
          console.log('request error ' + err.message);
        });
    }
  };

  const handleClicks = (event) => {
    setRadiovalue(event.target.value);
  };

  const [radiovalue, setRadiovalue] = useState('transaction');

  useEffect(() => {
    let state = localStorage['appState'];
    let AppState;
    if (state) {
      AppState = JSON.parse(state);
    }
    if (AppState && AppState.user && AppState.user.admin_type != 'admin') {
      window.location.href = '/client/error';
    }

    axios
      .get(
        API_BASE_URL +
          '/dashboard' +
          '?startDate=' +
          startdate +
          '&endDate=' +
          enddate
      )
      .then((dashboard) => {
        setFacilityCount(
          dashboard && dashboard.data && dashboard.data.facility
        );
        setRefunds(dashboard && dashboard.data && dashboard.data.refunds);
      })
      .catch((err) => {
        console.log(err);
      });

    getFacilities({ facility: true })
      .then((res) => {
        //console.log(res);
        setFacility(res && res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    searchReport();
  }, []);

  // header for CSV file graphs.
  const headers1 = [
    { label: 'Refund Type', key: 'method' },
    { label: 'No. of Transaction', key: 'count' },
    { label: 'Refunded Amount', key: 'amount' },
  ];
  const headers3 = [
    { label: 'Refund Claimed', key: 'claimed' },
    { label: 'Refund UnClaimed', key: 'unclaimed' },
    { label: 'Refund Escheated', key: 'escheated' },
  ];

  // input data for CSV file
  const csvReport1 = {
    data: refundMethodsTransaction,
    headers: headers1,
    filename: 'Distribution_of_refund_methods.csv',
  };
  const csvReport3 = {
    data: [pieChartData],
    headers: headers3,
    filename: 'Refund_Claim_VS_Refund_UnClaim.csv',
  };

  return (
    <div>
      <div className='dash-tiles'>
        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color='warning' stats icon>
                <CardIcon color='warning'>
                  <Icon>business_icon</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Total Facilities</p>
                <h3 className={classes.cardTitle}>
                  {facilityCount} <small></small>
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Link to='/admin/facilities'>Total Facilities</Link>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color='success' stats icon>
                <CardIcon color='danger'>
                  <Icon>attach_money_icon</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Amount refunded</p>
                <h3 className={classes.cardTitle}>${pieChartData.claimed}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>Total amount refunded</div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color='success' stats icon>
                <CardIcon color='success'>
                  <Icon>leaderboard_icon</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Unclaimed Amount</p>
                <h3 className={classes.cardTitle}>${pieChartData.unclaimed}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>Amount outstanding</div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color='info' stats icon>
                <CardIcon color='info'>
                  <Accessibility />
                </CardIcon>
                <p className={classes.cardCategory}>
                  Total Refunds awaiting refund
                </p>
                <h3 className={classes.cardTitle}>{refunds}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  Total Refunds awaiting refund
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
      <div className='dash_card'>
        <GridContainer>
          <GridItem xs={12} sm={6} md={8}>
            <Card className='filterCard'>
              {/* <p className="filter_txt">Filter By:</p> */}
              <div>
                {' '}
                <div className='t-header search-facility-admin'>
                  <div className='t-header-left'>
                    From:&nbsp;&nbsp;
                    <DatePicker
                      className='full-width-elem-date-picker'
                      id='dt'
                      selected={startdate}
                      onChange={changeDate}
                    />
                  </div>
                  <div className='t-header-left'>
                    To:&nbsp;&nbsp;
                    <DatePicker
                      className='full-width-elem-date-picker'
                      id='dt'
                      selected={enddate}
                      onChange={changeEndDate}
                    />
                  </div>
                  <div className='t-header-right'>
                    <div>
                      <select
                        id='status'
                        className='full-width-elem'
                        onChange={handleFacility}>
                        <option value={'null'}>Facility</option>
                        {facility &&
                          facility.length > 0 &&
                          facility.map((obj, index) => (
                            <option value={obj.id} key={index}>
                              {obj.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className='search_t_head'>
                    <Button
                      type='button'
                      color='primary'
                      onClick={searchReport}>
                      <img src={search} className='search_ico' />
                    </Button>
                  </div>

                  {/* <div className="t-header-right">
                                <div className="custom-search-box">
                                    <div className="custom-select"></div>
                                    <span className="search-icon"></span>
                                </div>
                            </div> */}
                </div>
              </div>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={6}>
          <Card className='dashboardCard'>
            <h3 className='chart_heads'>Distribution of Refund Methods</h3>
            <div style={{ width: '100%', height: '80%', paddingTop: '10%' }}>
              <BarChart
                showCount={radiovalue === 'transaction'}
                data={refundMethodsTransaction}
              />
            </div>

            <div className='dashboardRadio'>
              <div style={{ fontSize: '12px' }}>
                <input
                  type='radio'
                  value='transaction'
                  onChange={handleClicks}
                  checked={radiovalue == 'transaction'}
                  name='radiocheck'
                />{' '}
                Number of Transactions
              </div>{' '}
              <div style={{ fontSize: '12px' }}>
                <input
                  type='radio'
                  value='refund'
                  checked={radiovalue == 'refund'}
                  onChange={handleClicks}
                  name='radiocheck'
                />{' '}
                Amount by Refund
              </div>
            </div>
            <CSVLink
              {...csvReport1}
              style={{ cursor: 'pointer' }}
              className='dashboardBtn'>
              Download Report
            </CSVLink>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6}>
          <Card className='dashboardCard'>
            <h3 className='chart_heads'>Refunded Vs Unclaimed($)</h3>

            <div
              style={{
                width: '100%',
                position: 'relative',
                height: '90%',
                paddingTop: '10%',
              }}>
              <DoughnutChart
                claimed={pieChartData.claimed}
                unclaimed={pieChartData.unclaimed}
                escheated={pieChartData.escheated}
              />
            </div>
            <CSVLink
              {...csvReport3}
              style={{ cursor: 'pointer' }}
              className='dashboardBtn'>
              Download Report
            </CSVLink>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
