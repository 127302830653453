import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CustomInput from 'components/CustomInput/CustomInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import loader from '../../assets/img/loader.svg';
import Button from 'components/CustomButtons/Button';
import { useEffect, useState } from 'react';
import axios from 'axios';
import CardBody from 'components/Card/CardBody';
import { API_BASE_URL } from 'config';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { loadMoov } from '@moovio/moov-js';

export default function AddMoovPaymentMethod() {
  const [loaded, setLoaded] = useState(false);
  const [formInputs, setFormInputs] = useState({
    accountHolderName: '',
    accountNumber: '',
    routingNumber: '',
    accountType: 'business',
    bankAccountType: 'checking',
    accountId: '',
  });
  const [moovAccounts, setMoovAccounts] = useState([]);

  const [message, setMessage] = useState('');
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const history = useHistory();

  const addMoovPaymentMethod = async (e) => {
    try {
      e.preventDefault();
      setLoaded(true);
      const token = (
        await axios.post(API_BASE_URL + '/getTokenToAddPaymentMethod', {
          moovAccountId: formInputs.accountId,
        })
      ).data.token;

      const moov = await loadMoov(token);
      console.log(moov);
      const accountID = formInputs.accountId;
      const bankAccount = {
        holderName: formInputs.accountHolderName,
        holderType: formInputs.accountType,
        accountNumber: formInputs.accountNumber,
        bankAccountType: formInputs.bankAccountType,
        routingNumber: formInputs.routingNumber,
      };
      const response = await moov.accounts.bankAccounts.link({
        accountID: moovAccounts.find((acc) => formInputs.accountId == acc.id)
          .account_id,
        bankAccount,
      });

      await axios.post(API_BASE_URL + '/addPaymentMethodToDB', {
        holderName: response.holderName,
        bankAccountID: response.bankAccountID,
        moovAccountId: formInputs.accountId,
      });
      setLoaded(false);
      history.push('/admin/MoovPage');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    axios.get(API_BASE_URL + '/getMoovAccounts').then((res) => {
      console.log(res.data);
      setMoovAccounts(res.data);
    });
  }, []);

  const cancelfrm = () => {
    history.push('/admin/MoovPage');
  };

  return (
    <div>
      <form onSubmit={addMoovPaymentMethod}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color='primary'>
                <h4>Add Moov Payment Method</h4>
              </CardHeader>
              <CardBody>
                {loaded && (
                  <div className='loaders'>
                    <img src={loader} />
                  </div>
                )}

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className='custom-select'>
                      <FormControl>
                        <InputLabel id='demo-simple-select-tango'>
                          Moov Account
                        </InputLabel>
                        <Select
                          labelId='demo-simple-select-tango'
                          id='demo-simple-select'
                          value={formInputs.accountId}
                          onChange={onInputChange}
                          name='accountId'
                          className='full-width-elem'>
                          {moovAccounts.map((obj, index) => (
                            <MenuItem value={obj && obj.id}>
                              {obj && obj.account_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      type='text'
                      labelText='Account Holder Name'
                      onChange={onInputChange}
                      value={formInputs.accountHolderName}
                      name='accountHolderName'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className='custom-select'>
                      <FormControl>
                        <InputLabel id='demo-simple-select-tango'>
                          Account Type
                        </InputLabel>
                        <Select
                          labelId='demo-simple-select-tango'
                          id='demo-simple-select'
                          value={formInputs.accountType}
                          onChange={onInputChange}
                          name='accountType'
                          className='full-width-elem'>
                          <MenuItem value='individual'>Individual</MenuItem>
                          <MenuItem value='business'>Business</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className='custom-select'>
                      <FormControl>
                        <InputLabel id='demo-simple-select-tango'>
                          Bank Account Type
                        </InputLabel>
                        <Select
                          labelId='demo-simple-select-tango'
                          id='demo-simple-select'
                          value={formInputs.bankAccountType}
                          onChange={onInputChange}
                          name='bankAccountType'
                          className='full-width-elem'>
                          <MenuItem value='savings'>Savings</MenuItem>
                          <MenuItem value='checking'>Checking</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Routing Number'
                      name='routingNumber'
                      onChange={onInputChange}
                      value={formInputs.routingNumber}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Account Number'
                      name='accountNumber'
                      onChange={onInputChange}
                      value={formInputs.accountNumber}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>

                {message && (
                  <GridContainer>
                    <GridItem xs={8} sm={8} md={6}>
                      <p style={{ color: 'red', padding: '10px 0' }}>
                        {message}
                      </p>
                    </GridItem>
                  </GridContainer>
                )}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Button color='primary' type='submit'>
                      Create
                    </Button>
                    <Button
                      color='primary'
                      onClick={cancelfrm}
                      className='btn-cancel'
                      style={{ marginLeft: '5px' }}>
                      Cancel
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}></GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}
