import moment from 'moment';

export const utcToLocal = (utcDate, format) => {
  return moment.utc(utcDate).local().format(format);
};

export const REFUND_TYPES = {
  REGULAR: 'regular',
  INSURANCE: 'insurance',
};

export const BUSINESS_UNITS_FEATURE_FLAG = 'facility-business-units';
export const NEW_DATA_MAPPING_CONFIG_FEATURE_FLAG = 'new-data-mapping-config';
