import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom';
import axios from "axios";	

import { API_BASE_URL, API_BASE_IMG_URL } from '../../config';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Icon from "@material-ui/core/Icon";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

//const useStyles = makeStyles(styles);


	
	export default	class ManageTemplate extends Component {
	
	constructor(props) {
		super(props);	
		this.state = {			
		templates:[{}],
		template_delete_count:[{}]
		};
		

	}
	
	changestatus(id){
		
		 axios.get(API_BASE_URL+"/api/updatestatus/"+id).then(response => { return response; }).then(templatestatus => {    
	   
	 
	   
	   if(templatestatus && templatestatus.data && templatestatus.data.success){
		   
		    axios.get(API_BASE_URL+"/api/managetemplate").then(response => { return response; }).then(templatedatas => {      
	   
					this.setState({templates:templatedatas && templatedatas.data && templatedatas.data.templatedata,template_delete_count:templatedatas && templatedatas.data && templatedatas.data.template_delete_count});	
        
            }).catch(err => {
              console.log(err);
        
            });
		   
	   }
	
        
            }).catch(err => {
              console.log(err);
        
            });
			
		
		
	}
	
	deletetemplate(id){
		
		axios.get(API_BASE_URL+"/api/deletestatus/"+id).then(response => { return response; }).then(deletestatus => {    
	   
	 
	   
	   if(deletestatus && deletestatus.data && deletestatus.data.success){
		   
		    axios.get(API_BASE_URL+"/api/managetemplate").then(response => { return response; }).then(templatedatas => {      
	   
					this.setState({templates:templatedatas && templatedatas.data && templatedatas.data.templatedata,template_delete_count:templatedatas && templatedatas.data && templatedatas.data.template_delete_count});	
        
            }).catch(err => {
              console.log(err);
        
            });
		   
	   }
	
        
            }).catch(err => {
              console.log(err);
        
            });
			
	}
	  componentDidMount(){
		    axios.get(API_BASE_URL+"/api/managetemplate").then(response => { return response; }).then(templatedatas => {
      
	   console.log("templatedata",templatedatas.data)
	   this.setState({templates:templatedatas && templatedatas.data && templatedatas.data.templatedata,template_delete_count:templatedatas && templatedatas.data && templatedatas.data.template_delete_count});
	
        
            }).catch(err => {
              console.log(err);
        
            });
			
					    axios.get("http://127.0.0.1:3500/users").then(response => { return response; }).then(users => {
      
	   console.log("usersusersusersusers",users)
	
	
        
            }).catch(err => {
              console.log(err);
        
            });
		  
	  }
 // const classes = useStyles();
  render(){
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 >Manage Template</h4>
            
          </CardHeader>
		 
          <CardBody>
		  <div>
		  <table width="100%" id="template">
		  <tr><td> <Link to={`/admin/createtemplate`}><span className="material-icons">
add
</span></Link> <a href="#"><span className="material-icons">
delete
</span></a><a href="#">({this.state.template_delete_count && this.state.template_delete_count.length})</a></td></tr>
		  </table>
          <table width="100%" id="template">
		 
		  <thead>
		  <tr><th width="20%">TEMPLATE</th>
		   <th width="20%">NAME</th>
		   <th width="20%">STATUS</th>
		    <th width="20%">REGISTERED</th>
			<th width="20%">ACTIONS</th>
			</tr>
		  </thead>
		  <tbody>
		  {this.state.templates && this.state.templates.length>0 && this.state.templates.map((obj, index) => (
		  <tr className="txtalign"><td width="20%"><img width="100" src={`${API_BASE_IMG_URL}template/${obj.template_atribute && JSON.parse(obj.template_atribute).template_image}`} /></td>
					<td width="20%">{obj && obj.template_atribute && JSON.parse(obj.template_atribute).name}</td>
					<td width="20%">{obj && obj.status==0?"Inactive":"Active"}</td>
					<td width="20%">{obj && obj.created_at}</td>
					<td width="20%"><a style={{cursor:"pointer"}} onClick={()=>this.changestatus(obj.id)}><span className="material-icons">
					vpn_key
					</span></a><Link to={`/admin/updatetemplate/${obj.id}`}><span className="material-icons">
					edit
					</span></Link> <a style={{cursor:"pointer"}} onClick={()=>this.deletetemplate(obj.id)}> <span className="material-icons">
					delete
					</span></a></td>
				</tr>
		   ))}
		  </tbody>
		  </table>
		  </div>
          </CardBody>
        </Card>
      </GridItem>
      
    </GridContainer>
  );
}
	}
