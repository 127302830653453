import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const DoughnutChart = ({ claimed, unclaimed, escheated }) => {
  return (
    <Doughnut
      plugins={[
        ChartDataLabels,
        {
          id: 'legendMargin',
          beforeInit: function (chart) {
            const fitValue = chart.legend.fit;
            chart.legend.fit = function fit() {
              fitValue.bind(chart.legend)();
              return (this.height += 40);
            };
          },
        },
      ]}
      options={{
        //update lable to have $ in front of the label
        layout: {
          padding: {
            bottom: 20,
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function (tooltipItem, data) {
                console.log();
                // let value = data.datasets[0].data[tooltipItem.index];
                return '$' + (tooltipItem.formattedValue ?? '0.00');
              },
            },
          },

          datalabels: {
            formatter: function (value, context) {
              return '';
            },
            anchor: 'center',
            align: 'right',
            clamp: false,
            color: '#ffffff',
            font: {
              weight: 'bold',
              size: 12,
            },
          },

          legend: {
            display: true,
            // labels: {
            //   boxWidth: 5,
            // },
            labels: {
              padding: 0,
              boxWidth: 10,
              boxHeight: 10,
              boxPadding: 0,
            },
            align: 'center',
            fullSize: true,
            position: 'top',
            textDirection: 'rtl',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            display: false,
            grid: {
              color: 'rgba(0, 0, 0, 0)',
            },
          },
          x: {
            display: false,
            grid: {
              color: 'rgba(0, 0, 0, 0)',
            },
          },
        },
      }}
      data={{
        labels: [
          ['Claimed', '$' + claimed],
          ['Unclaimed', '$' + unclaimed],
          // ['Dormant', '$' + escheated],
        ],
        datasets: [
          {
            data: [+claimed, +unclaimed + +escheated],
            backgroundColor: ['#36A2EB', '#BE3144', '#FFCE56'],
            borderWidth: 0,
          },
        ],
      }}
    />
  );
};

export default DoughnutChart;
