import { Component } from 'react'

import { Link, Redirect } from 'react-router-dom'
import {
  API_BASE_URL
} from '../../config'

// @material-ui/core components
import axios from 'axios'

// core components
import { Box, Divider, Drawer, List, ListItem, Modal, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import InfoIcon from '@material-ui/icons/Info'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import Button from 'components/CustomButtons/Button.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { modalStyle } from 'views/Clients/Claim'
import loader from '../../assets/img/loader.svg'
import { TablePaginationActions } from './common'

export const labelStyle = {
  fontSize: '12px',
  color: 'grey',
}


export default class UpdateOrganization extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      loaded: false,
      page: 0,
      rowsPerPage: 25,
      name: '',
      giftcards: '',
      donations: '',
      domains: [],
      organizationId: '',
      organization: {},
      modalOpen: false,
      domainName: '',
      modalError: '',
      modalDomainInfoOpen: false,
      modalDomainName: '',
      modalDomainInfo: {}
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleName = this.handleName.bind(this)
    this.handleGiftCards = this.handleGiftCards.bind(this)
    this.handleDonations = this.handleDonations.bind(this)
  }

  handleSubmit(event) {
    event.preventDefault()
    this.clearForm()
  }

  handleName(event) {
    this.setState({ name: event.target.value })
  }
  handleGiftCards(event) {
    this.setState({ giftcards: event.target.value })
  }
  handleDonations(event) {
    this.setState({ donations: event.target.value })
  }
  handleState(event) {
    this.setState({ state: event.target.value })
  }
  cancelfrm = () => {
    this.setState({ redirect: true })
  };

  getOrganizationData(organizationId) {
    axios.get(API_BASE_URL + '/getOrganization/' + organizationId).then((res) => {
      this.setState({ name: res.data.organization.name })
      this.setState({ giftcards: res.data.organization.tango_giftcards_uids.join(',') })
      this.setState({ donations: res.data.organization.tango_donations_uids.join(',') })
    })
  }

  getOrganizationDomains(organizationId) {
    axios.get(API_BASE_URL + '/admin/getOrganizationDomains/' + organizationId).then((res) => {
      this.setState({ domains: res.data.domains })
    })
  }

  /*** function to integrate api to create facility*/
  componentDidMount() {
    let organizationId = this.props?.match?.params?.id
    this.setState({ organizationId })

    this.getOrganizationData(organizationId)
    this.getOrganizationDomains(organizationId)

    let state = localStorage['appState']
    let AppState
    if (state) {
      AppState = JSON.parse(state)
    }
    if (AppState?.user?.admin_type != 'admin') {
      window.location.href = '/client/error'
    }
  }

  updateOrganization = async (e) => {
    e.preventDefault()

    if (this.state.name == '') {
      alert('Please enter Organization name')
      return false
    }

    this.setState({ loaded: true })

    const formData = new FormData()
    formData.append('name', this.state.name)
    formData.append('organizationId', this.state.organizationId)

    let state = localStorage['appState']
    let AppState
    if (state) {
      AppState = JSON.parse(state)
    }

    let token = AppState?.user?.accessToken

    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    }

    axios
      .post(API_BASE_URL + '/organization', formData, { headers: headers })
      .then((res) => {
        if (!res.data?.err) {
          this.setState({ redirect: true, loaded: false })
        } else {
          alert(res.data?.msg)
          this.setState({ loaded: false, message: res.data.msg })
        }
      })
      .catch((err) => {
        this.setState({ loaded: false, message: err.response?.data?.msg })
        console.log(err)
      })
  };

  openAddDomainModal = () => {
    this.setState({ modalOpen: true })
  }

  openDomainInfoModal = (domain) => {
    this.setState({ modalDomainName: domain })

    axios.get(API_BASE_URL + '/admin/getCustomDomainInfo/' + domain).then((res) => {
      this.setState({ modalDomainInfoOpen: true })
      this.setState({ modalDomainInfo: res.data.domainInfo })
      console.log(res.data)
    }).catch((err) => {
      console.log(err)
      // this.setState({ loaded: true })
    }).finally(() => {
      // this.setState({ loaded: false })
    })
  }

  closeModal = () => {
    this.setState({ modalOpen: false })
    this.setState({ domainName: '' })
    this.setState({ modalError: '' })
  }

  closeDomainInfoModal = () => {
    this.setState({ modalDomainInfoOpen: false })
    this.setState({ modalDomainName: '' })
    this.setState({ modalDomainInfo: {} })
  }

  addDomain = async (e) => {
    try {
      e.preventDefault()
      await axios.post(API_BASE_URL + '/admin/addOrganizationDomain', {
        organizationId: this.state.organizationId,
        domain: this.state.domainName,
      })
      this.closeModal()
      this.getOrganizationDomains(this.state.organizationId)
    } catch (err) {
      this.setState({ modalError: err.response?.data?.message || 'Could not add domain to organization!' })
    }
  }

  deleteDomain = async (id) => {
    if (window.confirm('Are you sure to delete the domain?')) {
      this.setState({ loaded: true })

      let token = this.state?.user?.accessToken
      const headers = { Authorization: `Bearer ${token}` }
      axios
        .delete(API_BASE_URL + '/admin/deleteOrganizationDomain/' + id, { headers: headers })
        .then(() => {
          console.log('Domain deleted')
          this.getOrganizationDomains(this.state.organizationId)
          this.setState({ loaded: false })
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to='/admin/organizations' />
    }

    return (
      <div>
        <form onSubmit={this.updateOrganization}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color='primary'>
                  <h4>Update Organization</h4>
                </CardHeader>
                <CardBody>
                  {this.state.loaded && (
                    <div className='loaders'>
                      <img src={loader} />
                    </div>
                  )}

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        type='text'
                        labelText='Name'
                        onChange={this.handleName}
                        value={this.state.name}
                        id='name'
                        name='name'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Tango giftcards uids'
                        id='giftcards'
                        name='giftcards'
                        onChange={this.handleGiftcards}
                        value={this.state.giftcards}
                        type='text'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Tango donations uids'
                        id='donations'
                        name='donations'
                        onChange={this.handleDonations}
                        value={this.state.donations}
                        type='text'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  {this.state.message && (
                    <GridContainer>
                      <GridItem xs={8} sm={8} md={6}>
                        <p style={{ color: 'red', padding: '10px 0' }}>
                          {this.state.message}
                        </p>
                      </GridItem>
                    </GridContainer>
                  )}

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <Button color='primary' type='submit'>
                        Create
                      </Button>
                      <Button
                        color='primary'
                        onClick={this.cancelfrm}
                        className='btn-cancel'
                        style={{ marginLeft: '5px' }}>
                        Cancel
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}></GridItem>
                  </GridContainer>

                  <GridContainer style={{ paddingTop: '30px' }}>
                    <GridItem xs={12} sm={12} md={12}>
                      <h4><b>Domains</b></h4>
                      <br />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                      <Button onClick={this.openAddDomainModal} className='link' color='primary'>
                        <span className='add-icon'>
                          <AddIcon />
                        </span>
                        Add Domain
                      </Button>
                    </GridItem>

                    <Table width='100%' id='template'>
                      <TableHead>
                        <TableRow>
                          <TableCell align='left'>Name</TableCell>
                          <TableCell align='left'>Status</TableCell>
                          <TableCell align='left'>Created by</TableCell>
                          <TableCell align='left'>Created at</TableCell>
                          <TableCell align='left'>Actions</TableCell>
                        </TableRow>
                      </TableHead>

                      {this.state.domains && this.state.domains.length > 0 ? (
                        <TableBody>
                          {(this.state.rowsPerPage > 0
                            ? this.state.domains.slice(
                              this.state.page * this.state.rowsPerPage,
                              this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                            )
                            : this.state.domains
                          ).map((obj, index) => (
                            <TableRow className='txtalign' key={index}>
                              <TableCell width='20%'><b>{obj && obj.domain}</b>
                                <Link onClick={() => this.openDomainInfoModal(obj.domain)}>
                                  <span className='info-icon'>
                                    <InfoIcon />
                                  </span>
                                </Link>
                              </TableCell>
                              <TableCell width='20%'><b>{obj && obj.domain_status}</b></TableCell>
                              <TableCell width='20%'>{obj && obj.created_by}</TableCell>
                              <TableCell width='10%'>{obj && obj.created_at}</TableCell>
                              <TableCell width='10%'>
                                {obj && obj.id && (
                                  <Link
                                    title='Delete Organization Domain'
                                    onClick={() => this.deleteDomain(obj.id)}
                                  >
                                    <span className='material-icons'>delete</span>
                                  </Link>
                                )}{' '}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : (
                        <TableBody>
                          <TableRow className='txtalign'>
                            <TableCell colSpan={7} align='center'>No Domains assigned!</TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                      <TableFooter>
                        <TableRow align='center'>
                          <TablePagination
                            rowsPerPageOptions={[
                              5,
                              10,
                              25,
                              { label: 'All', value: -1 },
                            ]}
                            colSpan={7}
                            count={this.state.domains.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            SelectProps={{
                              inputProps: { roboto: 'rows per page' },
                              native: true,
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </GridContainer>

                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </form>

        {/* Add domain modal */}
        <Modal open={this.state.modalOpen} onClose={this.closeModal}>
          <form onSubmit={this.addDomain}>
            <Box
              sx={modalStyle}
              className='makeStyles-card-96'
              noValidate
              autoComplete='off'>
              <CardHeader
                style={{ transform: 'translateY(-50%)' }}
                color='primary'>
                <h4>Add Organization Domain</h4>
              </CardHeader>
              <CardBody style={{ marginTop: '-20px' }}>
                {this.state.modalError && (
                  <p
                    style={{
                      color: 'red',
                      padding: '0',
                      margin: 0,
                      fontSize: '15px',
                    }}>
                    {this.state.modalError}
                  </p>
                )}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      type='text'
                      labelText='Name'
                      onChange={(e) => this.setState({ domainName: e.target.value })}
                      autoComplete='off'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <main
                    style={{
                      padding: '20px',
                      gap: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}>
                  </main>
                </GridContainer>
                <div>
                  <GridContainer>
                    <Button variant='contained' color='primary' type='submit' style={{ margin: '5px 20px' }}>
                      Add
                    </Button>
                    <Button variant='contained' color='secondary' onClick={this.closeModal} style={{ margin: '5px 20px' }}>
                      Close
                    </Button>
                  </GridContainer>
                </div>
              </CardBody>
            </Box>
          </form>
        </Modal>

        {/* Domain info modal */}
        <Modal open={this.state.modalDomainInfoOpen} onClose={this.closeDomainInfoModal} loaded={!!this.modalDomainInfo?.id}>
          <Box
            sx={modalStyle}
            className='makeStyles-card-96'
            noValidate
            autoComplete='off'>
            <CardHeader
              style={{ transform: 'translateY(-50%)' }}
              color='primary'>
              <h4>Domain info</h4>
            </CardHeader>
            <CardBody style={{ marginTop: '-20px' }}>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4><b>Domain:</b> {this.state.modalDomainName}</h4>
                  <br />
                  <Divider />
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <b>Domain status: {this.state.modalDomainInfo?.state}</b>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <b>Created at:</b> {this.state.modalDomainInfo?.created_at}
                    </GridItem>
                  </GridContainer>
                  <br />
                  <Divider />
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <p>TXT records (known as SPF & DKIM) are required to send and receive email with custom domain. Please add them to your DNS records.</p>
                    </GridItem>
                    {this.state.modalDomainInfo?.sending_dns_records && this.state.modalDomainInfo?.sending_dns_records.map((record, index) => (
                      <GridItem xs={12} sm={12} md={12}>
                        {record.value.includes('k=rsa') ?
                          <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            padding: '20px',
                          }}>
                            <label style={labelStyle}><b>DKIM</b></label>
                            <label style={labelStyle}><b>Status:</b> {record.valid}</label>
                            <label style={labelStyle}><b>Domain:</b> {record.name}</label>
                            <TextField
                              variant='outlined'
                              multiline
                              inputProps={{
                                style: {
                                  fontSize: '14px',
                                  lineHeight: '1.3',
                                  width: '100%',
                                },
                              }}
                              minRows={3}
                              maxRows={6}
                              width='100%'
                              value={record.value ?? ''}
                              name='dkim'
                            />
                          </div> : ''}
                        {record.value.includes('v=spf') ?
                          <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            padding: '20px',
                          }}>
                            <label style={labelStyle}><b>SPF</b></label>
                            <label style={labelStyle}><b>Status:</b> {record.valid}</label>
                            <label style={labelStyle}><b>Domain:</b> {record.name}</label>
                            <TextField
                              variant='outlined'
                              multiline
                              inputProps={{
                                style: {
                                  fontSize: '14px',
                                  lineHeight: '1.3',
                                  width: '100%',
                                },
                              }}
                              minRows={3}
                              maxRows={6}
                              width='100%'
                              value={record.value ?? ''}
                              name='dkim'
                            />
                          </div> : ''}
                        {record.record_type == 'CNAME' ?
                          <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            padding: '20px',
                          }}>
                            <label style={labelStyle}><b>CNAME</b></label>
                            <label style={labelStyle}><b>Status:</b> {record.valid}</label>
                            <label style={labelStyle}><b>Domain:</b> {record.name}</label>
                            <TextField
                              variant='outlined'
                              multiline
                              inputProps={{
                                style: {
                                  fontSize: '14px',
                                  lineHeight: '1.3',
                                  width: '100%',
                                },
                              }}
                              minRows={3}
                              maxRows={6}
                              width='100%'
                              value={record.value ?? ''}
                              name='dkim'
                            />
                          </div> : ''}
                      </GridItem>
                    ))}
                  </GridContainer>

                </GridItem>
              </GridContainer>
              <div>
                <GridContainer>
                  <Button variant='contained' color='secondary' onClick={this.closeDomainInfoModal} style={{ margin: '5px 20px' }}>
                    Close
                  </Button>
                </GridContainer>
              </div>
            </CardBody>
          </Box>
        </Modal >

      </div >
    )
  }
}