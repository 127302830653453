import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams, Redirect } from 'react-router-dom';

import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { API_BASE_URL, API_BASE_IMG_URL } from '../../config';
import axios from 'axios';
import $ from 'jquery';

import logo from '../../assets/img/logo.png';
import verification from '../../assets/img/verification.svg';
import otp from '../../assets/img/otp.svg';
import '../../assets/css/App.css';
import '../../assets/scss/global.scss';

export default function Clients() {
  // First Popup
  const [open, setOpen] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [redirects, setRedirects] = React.useState(false);
  const [epname, setEpname] = React.useState(null);
  const [otpone, setOtpone] = React.useState(null);
  const [otptwo, setOtptwo] = React.useState(null);
  const [otpthree, setOtpthree] = React.useState(null);
  const [otpfour, setOtpfour] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [otperror, setOtperror] = React.useState(null);
  const [logoimage, setLogoimage] = React.useState(null);
  const [msgotp, setMsgotp] = React.useState(null);
  const [open3, setOpen3] = React.useState(false);
  const [flag, setFlag] = React.useState(false);
  const [charity, setCharity] = React.useState(false);
  const [charities, setCharities] = React.useState([{}]);
  const [charityid, setCharityid] = React.useState([{}]);

  const [Facilityid, setFacilityid] = React.useState(null);
  const [refundId, setRefundId] = React.useState(null);
  const [refundamount, setRefundamount] = React.useState(null);
  const [refundemail, setRefundemail] = React.useState(null);
  const [charityname, setCharityname] = React.useState(null);
  const [refundName, setRefundName] = React.useState(null);

  const params = useParams();
  const handleEPName = (event) => {
    setEpname(event.target.value);
  };

  const handleCharityid = (event) => {
    setCharityid(event.target.value);
    let slug = event.target.value;

    axios
      .get(API_BASE_URL + '/charitybyid/' + slug)
      .then((response) => {
        return response;
      })
      .then((res) => {
        console.log('ssscharity', res && res.data);
        setCharityname(res && res.data && res.data.name);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOtpone = (event) => {
    setOtpone(event.target.value);
    $('#two').focus();
  };

  const handleOtptwo = (event) => {
    setOtptwo(event.target.value);
    $('#three').focus();
  };

  const handleOtpthree = (event) => {
    setOtpthree(event.target.value);
    $('#four').focus();
  };

  const handleOtpfour = (event) => {
    setOtpfour(event.target.value);
  };

  const handleClickOpen = () => {
    if (epname == null) {
      setError('Please enter email/phone.');
    } else {
      const formData = new FormData();

      formData.append('keyword', epname);

      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      //console.log("params",params.id)
      let slug = params && params.id;

      axios
        .post(API_BASE_URL + '/generateotp/' + slug, formData, {
          headers: headers,
        })
        .then((response) => {
          return response;
        })
        .then((res) => {
          console.log('sss', res);
          //this.setState({redirect:true})
          if (res.status == 200) {
            setOpen2(true);
          } else {
            setError(res.data && res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    //setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const resentotp = () => {
    const formData = new FormData();

    formData.append('keyword', epname);

    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    //console.log("params",params.id)
    let slug = params && params.id;

    axios
      .post(API_BASE_URL + '/generateotp/' + slug, formData, {
        headers: headers,
      })
      .then((response) => {
        return response;
      })
      .then((res) => {
        console.log('sss', res);
        //this.setState({redirect:true})
        if (res.status == 200) {
          setMsgotp('Otp has been sent at email/phone');
        } else {
          setError(res.data && res.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Second Popup
  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleDonate = () => {
    //console.log("Charityid",charityid);
    const formData = new FormData();
    formData.append('facility_id', Facilityid);
    formData.append('charity_id', charityid);
    formData.append('refundamount', refundamount);
    formData.append('refundemail', refundemail);
    //formData.append('Facilityid', Facilityid);
    let slug = params && params.id;

    axios
      .post(API_BASE_URL + '/add_donation/' + slug, formData)
      .then((response) => {
        return response;
      })
      .then((res) => {
        console.log('sss', res);

        //this.setState({redirect:true})
        /*	if(res.status==200){
				setOpen2(true);
			} else {
				setError(res.data && res.data.msg);
			}*/
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose2 = () => {
    if (
      otpone == null &&
      otptwo == null &&
      otpthree == null &&
      otpfour == null
    ) {
      setOtperror('Your One Time Password cannot be blank');
    } else {
      const formData = new FormData();

      let otpdata = otpone + otptwo + otpthree + otpfour;

      formData.append('otpdata', otpdata);

      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      //console.log("params",params.id)
      let slug = params && params.id;

      axios
        .post(API_BASE_URL + '/matchotp/' + slug, formData, {
          headers: headers,
        })
        .then((response) => {
          return response;
        })
        .then((res) => {
          console.log('sss', res);
          //this.setState({redirect:true})
          if (res.status == 200) {
            setOpen2(false);
            setOpen3(true);

            //setRedirect(true)
            //alert("You are authenticated successfully.");
          } else {
            setOtperror(res.data && res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    //setOpen2(false);
  };

  if (redirect) {
    let slug = params && params.id;
    return <Redirect to={`/client/paymentdetail/${slug}`} />;
  }

  //Third Popup

  const handleClickOpen3 = () => {
    setOpen3(true);
  };

  const handleClose3 = () => {
    //setOpen3(false);
    setCharity(true);
    setOpen3(false);
  };

  // to switch between text

  const handleAltText = () => {
    setFlag(true);
  };

  return (
    <div className='submit-mail-wrapper welcome-banner'>
      <header className='s-header'>
        <div className='container'>
          <div className='s-header_inner'>
            <img src={logo} alt='' />
          </div>
        </div>
      </header>
      <div className='main-content'>
        <div className='container'>
          <div className='email-box-wrapper'>
            <div className='email-box'>
              <div className='email-box-header' style={{ float: 'right' }}>
                {logoimage && (
                  <img src={logoimage} width='142' height='65' alt='' />
                )}
              </div>
              <div className='email-box-header'>
                <h1>
                  Welcome to <br />
                  <strong>ClientPayDirect!</strong>
                </h1>
              </div>
              <div className='email-box-body'>
                <p>
                  You are just a couple steps away from accessing your
                  relocation assistance funds.
                </p>
                <p>
                  Please help us authenticate you below by entering the email.
                  After this, we will send you a text for dual factor
                  authentication.
                </p>
                {error && <p style={{ color: '#ff0000' }}>{error}</p>}
                <div className='submit-box'>
                  <input
                    type='text'
                    name='epname'
                    placeholder='Enter Email or Phone'
                    onChange={handleEPName}
                  />
                  <Button
                    variant='contained'
                    className='btn-submit'
                    onClick={handleClickOpen}>
                    Submit
                  </Button>
                </div>
              </div>
              <div className='email-box-footer'>
                <p>
                  Questions or concerns? Please don’t hesitate to contact us
                  here.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'>
        <div className='dialog-body'>
          <div className='dialog-icon'>
            <span>
              <img src={verification} alt='' />
            </span>
          </div>
          <h3 className='dialog-title'>Your Verification</h3>
          <p className='dialog-desc'>
            Please enter your Email Address/Phone Number to
            <br /> receive a verification code.
          </p>
          <form noValidate>
            <div className='dialog-field'>
              <input
                type='text'
                className='d-field'
                placeholder='Email/Phone Number'
              />
            </div>
            <div className='dialog-action'>
              <Button
                variant='contained'
                className='btn-solid'
                onClick={() => {
                  handleClose();
                  handleClickOpen2();
                }}>
                Submit
              </Button>
            </div>
          </form>
        </div>
      </Dialog>

      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby='form-dialog-title'
        className='otp-dialog'>
        <div className='dialog-body'>
          <div className='dialog-icon'>
            <span>
              <img src={otp} alt='' />
            </span>
          </div>
          {/* <h3 className='dialog-title'>One Time Password</h3> */}
          <p style={{ fontSize: '24px' }} className='dialog-desc'>
            <br /> Your one time password has been sent to you. Do not close
            this window. Check your email in a new window, get your 6 digit
            password and enter it below.
          </p>
          {otperror && <p style={{ color: '#ff0000' }}>{otperror}</p>}
          <form noValidate>
            <div className='multi-field'>
              <span className='digit'>
                <input
                  type='text'
                  className='d-field'
                  onChange={handleOtpone}
                  name='one'
                  id='one'
                  maxlength='1'
                />
              </span>
              <span className='digit'>
                <input
                  type='text'
                  className='d-field'
                  onChange={handleOtptwo}
                  name='two'
                  id='two'
                  maxlength='1'
                />
              </span>
              <span className='digit'>
                <input
                  type='text'
                  className='d-field'
                  onChange={handleOtpthree}
                  name='three'
                  id='three'
                  maxlength='1'
                />
              </span>
              <span className='digit'>
                <input
                  type='text'
                  className='d-field'
                  onChange={handleOtpfour}
                  name='four'
                  id='four'
                  maxlength='1'
                />
              </span>
            </div>
            <div className='missed-otp'>
              <p>Don't Receive OTP?</p>
              {msgotp && <p style={{ color: 'green' }}>{msgotp}</p>}
              <a className='resend-otp' onClick={resentotp}>
                Resend OTP
              </a>
            </div>
            <div className='dialog-action'>
              <Button
                variant='contained'
                className='btn-solid'
                onClick={handleClose2}>
                Submit
              </Button>
            </div>
          </form>
        </div>
      </Dialog>
      <Dialog
        open={open3}
        onClose={handleClose3}
        aria-labelledby='form-dialog-title'
        className='otp-dialog'>
        <div className='dialog-body'>
          <div className='dialog-icon'>
            <span>
              <img src={otp} alt='' />
            </span>
          </div>
          <h3 className='dialog-title'>Payment</h3>
          {flag ? (
            <div className='alternate-text'>
              <p className='dialog-desc'>
                You want Instant payment or you can wait for 7 days ?
              </p>
              <div className='v-actions'>
                <div className='v-action'>
                  <Button
                    variant='contained'
                    className='btn-outline'
                    onClick={handleAltText}>
                    No
                  </Button>
                </div>
                <div className='v-action'>
                  <Button
                    variant='contained'
                    className='btn-solid'
                    onClick={handleAltText}>
                    Yes
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className='alternate-text'>
              <p className='dialog-desc'>Do you want to Donate</p>
              <div className='v-actions'>
                <div className='v-action'>
                  <Button
                    variant='contained'
                    className='btn-outline'
                    onClick={handleClose3}>
                    No
                  </Button>
                </div>
                <div className='v-action'>
                  <Button
                    variant='contained'
                    className='btn-solid'
                    onClick={handleClose3}>
                    Yes
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
}
