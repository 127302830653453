import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

import { useParams, Redirect } from 'react-router-dom';
import { Base64 } from 'js-base64';
// or if you prefer no Base64 namespace
import { encode, decode } from 'js-base64';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { API_BASE_URL, API_BASE_IMG_URL } from '../../config';
import axios from 'axios';

import logo from '../../assets/img/logo.png';
import ticon from '../../assets/img/thankyou-icon.svg';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={4}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
export default function Thankyou(props) {
  const classes = useStyles();
  const params = useParams();
  const [paymenttype, setPaymenttype] = React.useState(0);
  const [charityname, setCharityname] = React.useState(null);
  const [refundAmount, setRefundAmount] = React.useState(0);
  const [trans_id, setTrans_id] = React.useState(0);
  const [id, setId] = React.useState(0);

  useEffect(() => {
    axiosInstance
      .post('/payment_details')
      .then((res) => {
        console.log('paymentdetails', res && res.data);

        setPaymenttype(res.data.refund_method);
        setCharityname(res.data.additional_info);
        setRefundAmount(parseFloat(res.data.refund_amount).toFixed(2));
        setTrans_id(res.data.id);
        setId(res.data.id);
        sessionStorage.removeItem('otpForPayment');
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className='submit-mail-wrapper  thankyou-page'>
      <header className='s-header'>
        <div className='container'>
          <div className='s-header_inner'>
            <div className='inner-brand'>
              <img src={logo} alt='' />
            </div>
            <div className='site-links'>
              <ul className='n-links'>
                {/* <li>
                  <Link to='/' className='n-link'>
                    FAQ
                  </Link>
                </li> */}
                <li>
                  <a
                    href='https://www.clientpaydirect.com/contact'
                    className='n-link'>
                    Contact us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <div className='main-content p-b-30'>
        <div className='container'>
          <div className='payment thankyoubg'>
            <div className='payment-right'>
              {/* {paymenttype == 2 || paymenttype == 3 || paymenttype == 5 || paymenttype == 7 || paymenttype == 4 ? */}
              <div className='payment-right_inner'>
                <div className='text-center t-icon'>
                  <img src={ticon} alt='' />
                </div>
                <h2 className='page-heading'>Thank You</h2>
                {paymenttype ? (
                  paymenttype === 6 ? (
                    <div className='page-desc'>
                      Thank you for using Client Pay Direct. Your funds will be
                      donated to the below charity.
                      <br />
                      <p className='page-desc'>
                        Charity name: <b>{charityname}</b> <br />
                      </p>
                      <p className='page-desc'>
                        Amount: <b>{'$' + refundAmount}</b> <br />
                      </p>
                      <p className='page-desc'>
                        Confirmation number: <b>{trans_id}</b>
                      </p>
                      <br />
                      <p className='page-desc'>
                        <b>
                          Please print / save this page for your tax records.
                        </b>
                      </p>
                    </div>
                  ) : paymenttype === 7 ? (
                    <p className='page-desc'>
                      Thank you for using Client Pay Direct. Your funds will be
                      deposited into your account in 3-5 business days. Your
                      confirmation number is {trans_id}. Please print/ save this
                      page for your records. If you have any questions, please
                      write us at
                      <a href='mailto:hello@clientpaydirect.com'>
                        <strong> hello@clientpaydirect.com</strong>
                      </a>
                      .
                    </p>
                  ) : paymenttype === 4 ? (
                    <p className='page-desc'>
                      Thank you for you using Client Pay Direct. Your funds will
                      be sent to you via our Gift Card partner, Tango Card,
                      shortly from noreply@tangocard.com. If you have any
                      questions, please write us at
                      <a href='mailto:hello@clientpaydirect.com'>
                        <strong> hello@clientpaydirect.com</strong>
                      </a>
                      .
                    </p>
                  ) : (
                    <p className='page-desc'>
                      Thank you for using Client Pay Direct. Your funds have
                      been sent to you via
                      <b>
                        {paymenttype == 2
                          ? ' Paypal'
                          : paymenttype == 3
                          ? ' Venmo'
                          : paymenttype == 5
                          ? ' Check'
                          : paymenttype == 4
                          ? 'Gift Card'
                          : paymenttype == 6
                          ? ' Charity'
                          : paymenttype == 7
                          ? ' Direct Deposit'
                          : null}
                      </b>
                      .You will receive an email confirmation shortly. If you
                      have any questions, please write us at{' '}
                      <a href='mailto:hello@clientpaydirect.com'>
                        <strong>hello@clientpaydirect.com</strong>
                      </a>
                      .
                    </p>
                  )
                ) : (
                  ''
                )}

                <div className='tab-section'></div>
              </div>
              {/* : null} */}
              {/* {paymenttype == 6 && */}
              {/* <div className="payment-right_inner">
                  <h2 className="page-heading">Thank You</h2>
                  <p className="page-desc">Thank you for using Client Pay Direct. Your funds will be donated to the following charity <b>{charityname}</b>. Your will receive an email confirmation shortly. If you have any questions, please write us at <a href="mailto:hello@clientpaydirect.com"><strong>hello@clientpaydirect.com</strong></a>.  </p>

                  <div className="tab-section">

                  </div>
                </div> */}
              {/* } */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
