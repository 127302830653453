import { useEffect, useState } from 'react';
import loader from '../../assets/img/loader.svg';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from './Facilities';
import { API_BASE_URL } from 'config';
import axios from 'axios';

export const getBusinessUnits = async () => {
  return (await axios.get(API_BASE_URL + '/business-units')).data;
};

export default function BusinessUnits() {
  const [loading, setLoading] = useState(false);
  const [businessUnits, setBusinessUnit] = useState([]);

  useEffect(() => {
    fetchBusinessUnits();
  }, []);

  const fetchBusinessUnits = async () => {
    setLoading(true);
    setBusinessUnit(await getBusinessUnits());
    setLoading(false);
  };

  const deleteBusinessUnit = async (id) => {
    const isConfirm = confirm(
      'Are you sure you want to delete this business unit?'
    );
    if (!isConfirm) return;
    await axios.delete(API_BASE_URL + '/business-units/' + id);
    fetchBusinessUnits();
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color='primary'>
            <h4>Business Unit</h4>
            {/* {this.state.errmsg && (
            <p style={{ color: '#ff0000' }}>{this.state.errmsg}</p>
          )} */}
          </CardHeader>

          <CardBody>
            <div>
              {loading && (
                <div className='loaders'>
                  <img src={loader} />
                </div>
              )}
              <div className='t-header'>
                <div className='t-header-left'>
                  <Link to={`/facility/create-business-unit`} className='link'>
                    <span className='add-icon'>
                      <AddIcon />
                    </span>
                    Create Business Unit
                  </Link>
                </div>
                <div className='t-header-right'>
                  <div className='custom-search-box'>
                    <input
                      type='search'
                      className='seacrh-field'
                      // value={this.state.search_keyword}
                      // onChange={this.handleKeyUp}
                      placeholder='Search by name,email and phone'
                    />
                    <span className='search-icon'>
                      <SearchIcon />
                    </span>
                  </div>
                </div>
              </div>
              {/* <table width="100%" id="template">
  <tr><td> <Link to={`/admin/createFacility`}><span className="material-icons">
add
</span>Create Facility</Link> </td></tr>
  </table> */}
              <Table width='100%' id='template'>
                <TableHead>
                  <TableRow>
                    <TableCell align='left'>Business Unit Name</TableCell>
                    <TableCell align='left'>Email</TableCell>
                    <TableCell align='left'>Phone</TableCell>
                    <TableCell align='left'>Identifiers</TableCell>

                    <TableCell align='left'>Actions</TableCell>
                  </TableRow>
                </TableHead>

                {businessUnits.length ? (
                  <TableBody>
                    {businessUnits.map((obj, index) => (
                      <TableRow className='txtalign' key={index}>
                        <TableCell width='20%'>{obj.name}</TableCell>
                        <TableCell width='20%'>{obj.email}</TableCell>
                        <TableCell width='20%'>{obj.phone}</TableCell>
                        <TableCell width='20%'>
                          {obj.identifiers.join(',')}
                        </TableCell>

                        <TableCell width='20%'>
                          <Link
                            to={`/facility/edit-business-unit/${obj.id}`}
                            title='Update Facility'>
                            <span className='material-icons'>edit</span>
                          </Link>{' '}
                          <a
                            style={{ cursor: 'pointer' }}
                            onClick={() => deleteBusinessUnit(obj.id)}
                            title='Delete Facility'>
                            {' '}
                            <span className='material-icons'>delete</span>
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell>No Business Unit Data Found!</TableCell>
                    </TableRow>
                  </TableBody>
                )}
                <TableFooter>
                  <TableRow align='center'>
                    {/* <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: 'All', value: -1 },
                      ]}
                      colSpan={3}
                      count={this.state.facilities.length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      SelectProps={{
                        inputProps: { roboto: 'rows per page' },
                        native: true,
                      }}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    /> */}
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
