import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CustomInput from 'components/CustomInput/CustomInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import loader from '../../assets/img/loader.svg';
import Button from 'components/CustomButtons/Button';
import { useState } from 'react';
import axios from 'axios';
import CardBody from 'components/Card/CardBody';
import { API_BASE_URL } from 'config';
import { useHistory } from 'react-router-dom';

export default function CreateTangoGroup() {
  const [loaded, setLoaded] = useState(false);
  const [formInputs, setFormInputs] = useState({
    customerIdentifier: '',
    displayName: '',
  });

  const [message, setMessage] = useState('');
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const history = useHistory();

  const CreateTangoGroup = async (e) => {
    e.preventDefault();
    setLoaded(true);
    await axios.post(API_BASE_URL + '/createTangoGroup', formInputs);
    setLoaded(false);
    history.push('/admin/TangoPage');
  };

  const cancelfrm = () => {
    history.push('/admin/TangoPage');
  };

  return (
    <div>
      <form onSubmit={CreateTangoGroup}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color='primary'>
                <h4>Create Tango Group</h4>
              </CardHeader>
              <CardBody>
                {loaded && (
                  <div className='loaders'>
                    <img src={loader} />
                  </div>
                )}

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      type='text'
                      labelText='Customer Identifier'
                      onChange={onInputChange}
                      value={formInputs.customerIdentifier}
                      name='customerIdentifier'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Display Name'
                      name='displayName'
                      onChange={onInputChange}
                      value={formInputs.displayName}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>

                {message && (
                  <GridContainer>
                    <GridItem xs={8} sm={8} md={6}>
                      <p style={{ color: 'red', padding: '10px 0' }}>
                        {message}
                      </p>
                    </GridItem>
                  </GridContainer>
                )}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Button color='primary' type='submit'>
                      Create
                    </Button>
                    <Button
                      color='primary'
                      onClick={cancelfrm}
                      className='btn-cancel'
                      style={{ marginLeft: '5px' }}>
                      Cancel
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}></GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}
