import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/CustomButtons/Button.js';

import { useParams, Redirect } from 'react-router-dom';
import loaderSvg from '../../assets/img/loader.svg';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import { API_BASE_URL, API_BASE_IMG_URL, RECAPTCHA_KEY } from '../../config';
import axios from 'axios';
import logo from '../../assets/img/logo.png';
import ReCAPTCHA from 'react-google-recaptcha';

import '../../assets/css/App.css';
import '../../assets/scss/global.scss';

import { Base64, encode } from 'js-base64';
import { Modal, Box } from '@mui/material';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import PaymentModal from 'components/Card/PaymentModal.js';

export const modalStyle = {
  display: 'flex',
  flexDirection: 'column',
  minWidth: '300px',
  width: '90vw',
  maxWidth: '800px',
  maxHeight: '93vh',
  overflowY: 'show',
  position: 'fixed',
  paddingTop: '20px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-47%)',
  borderRadius: '5px',
  backgroundColor: '#fff',
  // '& .MuiTextField-root': { m: 1, width: '25ch' },
};

export default function Claim() {
  const [refundName, setRefundName] = React.useState(null);

  const [msg, setMsg] = React.useState('');
  const [errormsg, setErrormsg] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);
  const [uniqueCode, setUniqueCode] = React.useState('');
  const [zipCode, setZipCode] = React.useState('');
  const params = useParams();
  const [modalErrMsg, setModalErrMsg] = React.useState('');
  const [open, setOpen] = React.useState(true);
  const [open3, setOpen3] = React.useState(false);
  const [refundId, setRefundId] = React.useState(null);
  const [charity, setCharity] = React.useState(false);

  // function to handle submit check button.

  const handleCancel = () => {
    setCharity(false);
    setOpen3(true);
  };
  const handleDonation = () => {
    setCharity(true);
    setOpen3(false);
  };
  const onChangeUniqueCode = (e) => {
    const value = e.target.value;
    if (value.length < 7) setUniqueCode(value.toUpperCase());
  };
  const onChangeZipCode = (e) => {
    setZipCode(e.target.value);
  };

  const recaptchaRef = useRef();
  const verifyCode = async (e) => {
    e.preventDefault();
    setLoaded(true);
    try {
      const reCaptchaToken = await recaptchaRef.current.executeAsync();
      const response = await axios.post(API_BASE_URL + '/verify_code', {
        uniqueCode,
        zipCode,
        reCaptchaToken,
      });
      setLoaded(false);
      console.log(response.data);
      sessionStorage.setItem('otpForPayment', response.data.token);

      if (response.data.error) {
        return setModalErrMsg(response.data.message);
      }
      console.log(response.data);
      sessionStorage.setItem('isDocument', true);
      setRefundId(response.data?.id);
      setOpen(false);
      setOpen3(true);
      setMsg(
        'You claim code was successfully confirmed. Please click ‘Submit’ to receive you refund via check.'
      );
    } catch (err) {
      console.log(err);
      setLoaded(false);
      setModalErrMsg(err.response.data.message);
    }
  };

  return (
    <div className='submit-mail-wrapper' style={{ height: '100vh' }}>
      {refundId && (
        <PaymentModal
          charity={charity}
          handleCancel={handleCancel}
          open3={open3}
          handleDonation={handleDonation}
          id={refundId}
        />

        // <Redirect to={'/client/chequedetail/' + Base64.encode(refundId)} />
      )}
      <header className='s-header'>
        <div className='container'>
          <div className='s-header_inner'>
            <div className='inner-brand'>
              <img src={logo} alt='' />
            </div>
            <div className='site-links'>
              <ul className='n-links'>
                {/* <li>
                  <Link to='/' className='n-link'>
                    FAQ
                  </Link>
                </li> */}
                <li>
                  <a
                    href='https://www.clientpaydirect.com/contact'
                    className='n-link'
                    target='_blank'>
                    Contact us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>

      <Modal open={open}>
        <form onSubmit={verifyCode}>
          <ReCAPTCHA
            ref={recaptchaRef}
            size='invisible'
            sitekey={RECAPTCHA_KEY}
          />
          <Box
            sx={modalStyle}
            className='makeStyles-card-96'
            noValidate
            autoComplete='off'
            st>
            <CardHeader
              style={{ transform: 'translateY(-50%)' }}
              color='primary'>
              <h4>Confirm your claim code and zip code</h4>
            </CardHeader>
            <CardBody style={{ marginTop: '-20px' }}>
              <p
                style={{
                  padding: '0',
                  margin: 0,
                  fontSize: '15px',
                  fontWeight: 'bold',
                }}>
                Please note: This is for mailed letter based refunds only.  If
                you received an email, please use the "claim refund" button in
                the body of the email to access your funds.
              </p>
              {modalErrMsg && (
                <p
                  style={{
                    color: 'red',
                    padding: '0',
                    margin: 0,
                    fontSize: '15px',
                  }}>
                  Unable to confirm claim code / zip code. Please try again. If
                  you have trouble please email us at help@clientpaydirect.com
                  and reference your claim code.
                </p>
              )}

              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='text'
                    labelText='Unique Code'
                    onChange={onChangeUniqueCode}
                    value={uniqueCode}
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText='Zip Code'
                    type='number'
                    onChange={onChangeZipCode}
                    value={zipCode}
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <div>
                <Button variant='contained' color='primary' type='submit'>
                  Verify
                </Button>
              </div>
            </CardBody>
          </Box>
        </form>
      </Modal>
    </div>
  );
}
