import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';


import { useParams, Redirect } from 'react-router-dom';
import { Base64 } from 'js-base64';
// or if you prefer no Base64 namespace
import { encode, decode } from 'js-base64';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { API_BASE_URL, API_BASE_IMG_URL } from '../../config';
import axios from "axios";

import logo from '../../assets/img/logo.png';
import ticon from '../../assets/img/thankyou-icon.svg';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={4}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));
export default function Error() {
    const classes = useStyles();
    const params = useParams();

    const handleClickOpen = () => {

        let state = localStorage["appState"];
        let AppState;
        if (state) {
            AppState = JSON.parse(state);
            // this.setState({ isLoggedIn: AppState.isLoggedIn, user: AppState.user });
        }
        if (AppState && AppState.user && AppState.user.admin_type != "admin") {
            window.location.href = "/facility/facilitydashboard";
        } else if (AppState && AppState.user && AppState.user.admin_type == "admin") {
            window.location.href = "/admin/dashboard";
        }


    }







    return (
        <div className="submit-mail-wrapper  thankyou-page">
            <header className="s-header">
                <div className="container">
                    <div className="s-header_inner">
                        <div className="inner-brand">
                            <img src={logo} alt="" />
                        </div>
                        <div className="site-links">
                            {/* <ul className="n-links">
                                <li>
                                    <Link to="/" className="n-link">FAQ</Link>
                                </li>
                                <li>
                                    <Link to="/" className="n-link">Contact us</Link>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </header>
            <div className="main-content p-b-30">
                <div className="container">
                    <div className="payment thankyoubg">
                        <div className="payment-right">

                            <div className="payment-right_inner">
                                <div className="text-center t-icon">
                                    {/* <img src={ticon} alt="" /> */}
                                </div>
                                <h1 className="page-heading">404 Error</h1>
                                <h2 className="page-heading">You are not authorized</h2>
                                <Button variant="contained" className="btn-submit" onClick={handleClickOpen}>Back To Dashboard</Button>



                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
