import { Switch } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import { useStyles } from 'components/TemplateModal/TemplateModal';
import { useEffect, useState } from 'react';
import CustomInput from 'components/CustomInput/CustomInput';
import { fileToDataUri } from './CreateFacility';
import axios from 'axios';
import { API_BASE_URL } from 'config';
import EconsentModal from 'views/Clients/EconsentModal';
import moment from 'moment';
import { PUBLIC_ASSETS_BASE_URL } from 'config';

const {
  Box,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} = require('@mui/material');
let facilityLogo;
const Econsent = () => {
  const [formInputs, setFormInputs] = useState({
    title: '',
    heading: '',
    body: ``,
    logo: '',
    enabled: true,
  });
  const [econsentAgreed, setEconsentAgreed] = useState(true);

  const onClose = () => {
    setEconsentAgreed(true);
  };
  const onOpen = () => {
    setEconsentAgreed(false);
  };
  const onUpdate = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData();
      formData.append('title', formInputs.title);
      formData.append('heading', formInputs.heading);
      formData.append('body', formInputs.body);
      formData.append('logo', formInputs.logo);

      const response = await axios.post(
        API_BASE_URL + '/insertOrUpdateEconsent/',
        formData
      );
    } catch (err) {
      console.log(err);
    }
  };
  const [enabled, setEnabled] = useState(false);
  const onSwitchChange = async () => {
    await axios.post(API_BASE_URL + '/toggleEconsent/', {
      enabled: !enabled,
    });
    setEnabled(!enabled);
  };
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormInputs({ ...formInputs, [name]: value });
  };
  const onLogoChange = async (e) => {
    setFormInputs({
      ...formInputs,
      ['logo']: e.target.files[0] ? await fileToDataUri(e.target.files[0]) : '',
    });
  };

  const onCheckInputChange = () => {
    setFormInputs({
      ...formInputs,
      ['logo']: facilityLogo,
    });
  };
  const clearImage = () => {
    setFormInputs({ ...formInputs, ['logo']: '' });
  };
  const labelStyle = {
    fontSize: '12px',
    color: 'grey',
  };

  const getEconsent = async () => {
    try {
      const response = await axios.get(API_BASE_URL + '/getEconsent');
      setFormInputs({
        ...response.data,
        logo: PUBLIC_ASSETS_BASE_URL + response.data.logo,
      });
      setEnabled(response.data.enabled);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(async () => {
    const AppState = JSON.parse(localStorage['appState'] ?? '{}');
    const logo = AppState?.user?.image_upload
      ? PUBLIC_ASSETS_BASE_URL + AppState?.user?.image_upload
      : '';
    facilityLogo = logo;
  }, []);

  useEffect(() => {
    getEconsent();
  }, []);

  const classes = useStyles();
  return (
    <>
      <div className='container' style={{ marginTop: '30px' }}>
        <Box
          style={{ borderRadius: '7px', minHeight: '60vh', background: '#fff' }}
          className='makeStyles-card-96'
          noValidate
          autoComplete='off'
          st>
          <CardHeader
            style={{ transform: 'translateY(-50%)', display: 'flex' }}
            color='primary'>
            <Switch
              color='primary'
              checked={enabled ?? false}
              onChange={onSwitchChange}
            />
            <h4>Enable E-Consent</h4>
          </CardHeader>
          <CardBody style={{ height: '100%' }}>
            {console.log(enabled)}
            {enabled && (
              <form onSubmit={onUpdate}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}>
                      <label style={labelStyle}>Title</label>
                      <TextField
                        variant='outlined'
                        className={classes.root}
                        multiline
                        inputProps={{
                          style: {
                            fontSize: '14px',
                            lineHeight: '1.3',
                            width: '100%',
                          },
                        }}
                        minRows={1}
                        maxRows={2}
                        value={formInputs.title}
                        onChange={onInputChange}
                        name='title'
                      />
                    </div>
                  </GridItem>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      padding: '20px',
                    }}>
                    <label style={labelStyle}>Heading</label>
                    <TextField
                      variant='outlined'
                      className={classes.root}
                      multiline
                      inputProps={{
                        style: {
                          fontSize: '14px',
                          lineHeight: '1.3',
                          width: '100%',
                        },
                      }}
                      minRows={2}
                      maxRows={3}
                      value={formInputs.heading}
                      onChange={onInputChange}
                      name='heading'
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      padding: '20px',
                    }}>
                    <label style={labelStyle}>Terms and Conditions</label>
                    <TextField
                      variant='outlined'
                      className={classes.root}
                      multiline
                      inputProps={{
                        style: {
                          fontSize: '14px',
                          lineHeight: '1.3',
                          width: '100%',
                        },
                      }}
                      minRows={9}
                      maxRows={12}
                      value={formInputs.body}
                      onChange={onInputChange}
                      name='body'
                    />
                  </div>
                  <GridItem xs={12} sm={12} md={6}></GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className='custom-upload m-b-30 m-t-27'>
                      <label htmlFor='signature'>Upload Logo</label>
                      <br />
                      <label htmlFor='signature' className='custom-upload-btn'>
                        <span>Browse</span>
                        <CustomInput
                          id='signature'
                          name='signature'
                          onChange={onLogoChange}
                          type='file'
                          value={''}
                          accept='image/*'
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </label>
                    </div>
                    {
                      <div className='preview-image' width='130'>
                        <img src={formInputs.logo} width='130' />
                      </div>
                    }
                    {formInputs.logo ? (
                      <span
                        className='material-icons'
                        style={{ cursor: 'pointer' }}
                        onClick={clearImage}>
                        delete
                      </span>
                    ) : (
                      ''
                    )}
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='facilityLogo'
                            onChange={onCheckInputChange}
                            checked={formInputs.logo === facilityLogo}
                          />
                        }
                        label='Use existing logo'
                      />
                    </FormGroup>
                  </GridItem>
                </GridContainer>
                <div>
                  <Button variant='contained' color='primary' type='submit'>
                    Save
                  </Button>

                  <Button
                    style={{ marginLeft: '10px' }}
                    variant='contained'
                    color='primary'
                    onClick={onOpen}>
                    Preview
                  </Button>
                </div>
              </form>
            )}
          </CardBody>
        </Box>
        <EconsentModal
          econsentAgreed={econsentAgreed}
          econsent={formInputs}
          onClose={onClose}
          agreeEconsent={onClose}
        />
      </div>
    </>
  );
};

export default Econsent;
