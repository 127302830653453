import React, { Component, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import Moment from 'react-moment';
import fileDownload from 'js-file-download';

// react-CSV downloader
import { CSVLink } from 'react-csv';

import { API_BASE_URL, API_BASE_IMG_URL } from '../../config';

import GridItem from 'components/Grid/GridItem.js';

import GridContainer from 'components/Grid/GridContainer.js';

import Card from 'components/Card/Card.js';
import SearchIcon from '@material-ui/icons/Search';

import CardBody from 'components/Card/CardBody.js';
import Button from '../../components/CustomButtons/Button';

import loader from '../../assets/img/loader.svg';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { Modal } from '@material-ui/core';
import { Box } from '@mui/system';
import ReportPreview from './ReportPreview';
import { TableFooter, TablePagination, TableRow } from '@mui/material';
import CheckPermissionsComponent from 'views/Facilities/CheckPermissions';
import { checkPermission } from 'views/Facilities/CheckPermissions';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

//const useStyles = makeStyles(styles);

export default function Payments() {
  const [loaded, setLoaded] = useState(false);
  const [refundReport, setRefundReport] = useState([]);
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const closePreview = () => {
    setIsPreviewOpen(false);
  };
  const [status, setStatus] = useState('all');
  const selectstatus = (event) => {
    setStatus(event.target.value);
  };
  const changeDate = (event) => {
    const endDateFormatted = new Date(startDate);
    const startDateFormatted = new Date(event);
    console.log(
      startDateFormatted,
      new Date(),
      startDateFormatted > new Date()
    );
    if (startDateFormatted > new Date()) {
      return toast.error('Start date should be smaller than or equal to today');
    }
    if (!endDate) return setStartDate(event ?? '');
    if (endDateFormatted < startDateFormatted) {
      console.log('hello');
      toast.error('Start date should be smaller than end date');
      return;
    }
    setStartDate(event ?? '');
  };

  /*** Change end date */
  const changeEndDate = (event) => {
    const endDateFormatted = new Date(event);
    const startDateFormatted = new Date(startDate);
    if (endDateFormatted > new Date()) {
      return toast.error('End date should be smaller than or equal to today');
    }
    if (!startDate) return setEndDate(event ?? '');
    if (endDateFormatted < startDateFormatted) {
      console.log('hello');
      toast.error('End date should be greater than start date');
      return;
    }
    setEndDate(event ?? '');
  };

  const headers = [];

  if (refundReport.length > 0) {
    for (const key of Object.keys(refundReport[0])) {
      headers.push({ label: key, key: key });
    }
  }

  const getReport = async () => {
    try {
      console.log(search);
      setLoaded(true);
      const response = await axios.get(
        `${API_BASE_URL}/getreportforfacility?startDate=${startDate}&endDate=${endDate}&status=${status}&search=${search}`
      );
      setLoaded(false);
      setRefundReport(response.data.data);
    } catch (err) {
      setLoaded(false);
      console.log(err);
    }
  };

  const onSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const clearAllInputs = () => {
    setStartDate('');
    setEndDate('');
    setStatus('all');
    setSearch('');
  };
  useEffect(() => {
    getReport();
  }, [startDate, endDate, status, search]);

  const downloadFile = async () => {
    try {
      setLoaded(true);
      const response = await axios.get(
        `${API_BASE_URL}/getreportforfacility_download?startDate=${startDate}&endDate=${endDate}&status=${status}&search=${search}&download=true`
      );
      setLoaded(false);
      fileDownload(response.data, 'report.csv');
    } catch (err) {
      setLoaded(false);
      console.log(err);
    }
  };
  // const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <h2 className='subHeading'>Download Transactions</h2>
        <Card style={{ height: 'max-content' }} className='dashboardCard'>
          <CardBody style={{ height: 'max-content' }}>
            <div
              onClick={clearAllInputs}
              style={{
                position: 'absolute',
                right: '30px',
                textDecoration: 'underline',
                fontWeight: 'bold',
                color: '#3AB795',
                cursor: 'pointer',
              }}>
              Clear Results{' '}
            </div>
            <div style={{ marginTop: '15px' }}>
              {loaded && (
                <div className='loaders'>
                  <img src={loader} />
                </div>
              )}
              <div className='t-header'>
                <div className='t-header-left'>
                  {/* <CSVLink
                    {...csvReport2}
                    style={{
                      cursor: 'pointer',
                      fontSize: '16px',
                      fontWeight: '600',
                    }}
                    onClick={aggregatereports}>
                    {' '}
                    Download Aggregate Report
                  </CSVLink> */}
                </div>
                <div className='t-header-left'>
                  From:&nbsp;&nbsp;
                  <DatePicker
                    className='full-width-elem-date-picker'
                    id='dt'
                    autoComplete='off'
                    selected={startDate}
                    onChange={changeDate}
                  />
                </div>
                <div className='t-header-left'>
                  To:&nbsp;&nbsp;
                  <DatePicker
                    className='full-width-elem-date-picker'
                    id='dt'
                    autoComplete='off'
                    selected={endDate}
                    onChange={changeEndDate}
                  />
                </div>
                <div className='t-header-right'>
                  <div className='custom-search-box'>
                    <div className='custom-select'>
                      <select
                        id='status'
                        className='full-width-elem'
                        value={status}
                        onChange={selectstatus}>
                        <option value='all'>All</option>
                        <option value='initiated'>Initiated</option>
                      </select>
                    </div>
                    <span className='search-icon'></span>
                  </div>
                </div>

                <div className='t-header-left'>
                  {!loaded && (
                    <Button
                      style={{ marginRight: '10px' }}
                      color='primary'
                      onClick={() => setIsPreviewOpen(true)}>
                      View Report
                    </Button>
                  )}

                  {checkPermission(
                    'refund_reporting',
                    'refund_reporting',
                    'download'
                  ) && (
                    <Button
                      color='primary'
                      style={{ marginRight: '10px' }}
                      onClick={() => {
                        downloadFile();
                        toast.success(
                          'Downloading report for the selected date range',
                          {
                            position: 'bottom-right',
                            autoClose: 5000,
                          }
                        );
                      }}>
                      Download Report
                    </Button>
                  )}
                </div>

                <div className='t-header-right' style={{ padding: '10px' }}>
                  <div className='custom-search-box'>
                    <input
                      type='search'
                      className='seacrh-field'
                      value={search}
                      onChange={onSearchChange}
                      placeholder='Search by Name, Email and Customer Reference No'
                    />
                    <span className='search-icon'>
                      <SearchIcon />
                    </span>
                  </div>
                </div>
              </div>
              {isPreviewOpen && (
                <main style={{ height: '500px', width: '100%' }}>
                  <ReportPreview refundReport={refundReport} />
                </main>
              )}
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
