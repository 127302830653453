import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Moment from 'react-moment';

import { API_BASE_URL } from '../../config';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import loader from '../../assets/img/loader.svg';
import uploadIcon from '../../assets/img/upload.svg';
import { utcToLocal } from 'utility';
import { usePostHog } from 'posthog-js/react';
import { NEW_DATA_MAPPING_CONFIG_FEATURE_FLAG } from 'utility';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

const Uploadfile = () => {
  const [templates, setTemplates] = useState([{}]);
  const [refundsData, setRefundsData] = useState([{}]);
  const [templateDeleteCount, setTemplateDeleteCount] = useState([{}]);
  const [facilities, setFacilities] = useState([{}]);
  const [facilityId, setFacilityId] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [facilityFolder, setFacilityFolder] = useState('');
  const [file, setFile] = useState(null);
  const [filesList, setFilesList] = useState([{}]);
  const [uploadFileText, setUploadFileText] = useState('Browse for file');
  const [uploadFileStatus, setUploadFileStatus] = useState(false);
  const [uploadSuccessMessage, setUploadSuccessMessage] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState(false);
  const [csvStructure, setCsvStructure] = useState(null);
  const [dataMappings, setDataMappings] = useState([]);
  const [selectedDataMapping, setSelectedDataMapping] = useState(null);
  const [user, setUser] = useState(null);
  const posthog = usePostHog();

  const uploadFile = async () => {
    try {
      const fileName = file && file.name;
      const fileExtension = fileName.split('.').pop();
      if (file == null) {
        alert('Please upload file');
        setUploadFileText('Browse for file');
        setUploadFileStatus(false);
        return false;
      } else if (
        posthog.isFeatureEnabled(NEW_DATA_MAPPING_CONFIG_FEATURE_FLAG) &&
        !selectedDataMapping
      ) {
        alert('Please select a data mapping');
      } else {
        setLoaded(true);
        const formData = new FormData();
        formData.append('file', file);
        formData.append(
          'folder',
          user && user.name.toLowerCase().replace(/ /g, ''),
        );
        formData.append('data_mapping_id', selectedDataMapping);
        formData.append('user_id', user && user.id);

        await axios.post(API_BASE_URL + '/upload_file', formData);

        setFile(null);
        setUploadFileStatus(false);
        setLoaded(false);

        const uploadedFiles = await axios.get(
          API_BASE_URL + '/refund_file_list',
        );

        setFilesList(uploadedFiles && uploadedFiles.data.refundFiles);
        setUploadSuccessMessage(true);
        setUploadFileText('Browse for file');
        setLoaded(false);
      }
    } catch (error) {
      setLoaded(false);
      console.log(error);
    }
  };

  const handleUploadFile = (event) => {
    if (event.target.files[0] !== undefined) {
      setFile(event.target.files[0]);
      setUploadFileText('Upload file');
      setUploadFileStatus(true);
    } else {
      alert('please select a file');
    }
  };

  const download = () => {
    window.location.assign('/sample.csv');
  };

  useEffect(() => {
    setLoaded(true);
    const state = localStorage['appState'];
    let AppState;
    if (state) {
      AppState = JSON.parse(state);
      setUser(AppState.user);
    }

    const userId = AppState && AppState.user && AppState.user.id;
    if (!userId) return (window.location.href = '/user/facilitylogin');
    const token = AppState && AppState.user && AppState.user.accessToken;
    if (AppState && AppState.user && AppState.user.admin_type == 'admin') {
      window.location.href = '/client/error';
    }
    setFacilityFolder(
      state.user && state.user.name.toLowerCase().replace(/ /g, ''),
    );
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(API_BASE_URL + '/refund_file_list')
      .then((response) => {
        return response;
      })
      .then((uploadedFiles) => {
        setFilesList(uploadedFiles && uploadedFiles.data.refundFiles);
        setCsvStructure(uploadedFiles.data.csvFileStructure);
        setLoaded(false);
      })
      .catch((err) => {
        setLoaded(false);
      });

    axios.get(API_BASE_URL + '/data-mappings').then((response) => {
      if (response.data.length > 1) {
        setDataMappings(response.data);
        setSelectedDataMapping(response.data[0].id);
      }
      if (response.data.length === 1) {
        setSelectedDataMapping(response.data[0].id);
      }
    });
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <h2 className='subHeading'>Upload Refund File</h2>
        <Card className='dashboardCard table_card_height'>
          <CardBody>
            <div>
              {loaded && (
                <div className='loaders'>
                  <img src={loader} />
                </div>
              )}
              {uploadSuccessMessage && (
                <div className='uploadTxt'>
                  <p>
                    File Uploaded SuccessFully.It can take up to 30mins to
                    process your file.
                  </p>{' '}
                </div>
              )}
              {uploadErrorMessage && (
                <div className='uploadTxt'>
                  <p style={{ color: 'red' }}>
                    You have already uploaded this file.
                  </p>{' '}
                </div>
              )}
              <div className='t-header uploadCard'>
                <div className='t-header-left'>
                  <input
                    type='file'
                    onChange={handleUploadFile}
                    value=''
                    id='logoimg'
                  />
                  <img src={uploadIcon} />
                  <p>{uploadFileText}</p>
                </div>
              </div>
              <div className='uploadTxt'>
                {uploadFileStatus && (
                  <button className='btn' onClick={uploadFile}>
                    Upload
                  </button>
                )}
                <p>
                  <a href={API_BASE_URL + '/getSampleRefundFile'}>
                    {' '}
                    Download Sample Template{' '}
                  </a>{' '}
                </p>
              </div>
            </div>
            {!!dataMappings.length && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <div className='custom-search-box'>
                  <div className='custom-select'>
                    <select
                      value={selectedDataMapping}
                      className='full-width-elem'
                      id='status'
                      onChange={(e) => setSelectedDataMapping(e.target.value)}>
                      <option style={{ fontWeight: 'bold' }} value='null'>
                        Data Mapping
                      </option>
                      {dataMappings.map((item) => {
                        return (
                          <option
                            value={item.id}
                            className='parent'
                            style={{ fontWeight: 'bold' }}
                            key={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <span className='search-icon'></span>
                </div>
              </div>
            )}
          </CardBody>
        </Card>

        <Card className='dashboardCard table_card_height'>
          <CardBody>
            <table width='100%' id='template' className='paymentTable'>
              <thead>
                <tr>
                  <th width='15%'>Uploaded Date</th>
                  <th width='15%'>File Name</th>
                  <th width='15%'>File Status</th>
                  <th width='15%'>Total Amount</th>
                  <th width='15%'>Record Count</th>
                  <th width='15%'>Processed Date</th>
                </tr>
              </thead>
              {filesList && filesList.length > 0 ? (
                <tbody>
                  {filesList.map((obj, index) => (
                    <tr className='txtalign' key={index}>
                      <td width='15%'>
                        {utcToLocal(obj.created_at, 'MM/DD/YYYY')}
                      </td>
                      <td width='15%'>{obj && obj.filename}</td>
                      <td width='15%'>
                        {obj && obj.process_status == 0
                          ? 'Processing'
                          : 'Processed'}
                      </td>
                      <td width='15%'>
                        {obj.total_amount === null ? 'N/A' : obj.total_amount}
                      </td>
                      <td width='15%'>
                        {obj.total_records === null ? 'N/A' : obj.total_records}
                      </td>
                      <td width='15%'>
                        {obj && obj.process_status == 0
                          ? 'NA'
                          : obj && obj.upload_date}{' '}
                        {obj.processed_at ? (
                          <Moment format='MM-DD-YYYY HH:mm'>
                            {obj.processed_at}
                          </Moment>
                        ) : (
                          '-'
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td>No File Uploaded</td>
                  </tr>
                </tbody>
              )}
            </table>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Uploadfile;
