import React, { Component } from "react";

import ReactDOM from "react-dom";
import { Link, Redirect } from 'react-router-dom';
import { API_BASE_URL, API_BASE_IMG_URL } from '../config';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";


import avatar from "assets/img/faces/marc.jpg";
import logoimg from "assets/img/imgpsh_fullsize_anim_front.png";

const md5 = require('md5');
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

//const useStyles = makeStyles(styles);

//const classes = useStyles();


export default class NewUserReset extends Component {

    constructor(props) {
        super(props);
        this.state = {
          
            newpassword: '',
            confirmpassword: '',
           

        };
        this.handleNewpassword = this.handleNewpassword.bind(this);
        this.handleConfirmpassword = this.handleConfirmpassword.bind(this);

    }



    handleNewpassword(event) {
        this.setState({ newpassword: event.target.value });
    }
    handleConfirmpassword(event) {
        this.setState({ confirmpassword: event.target.value });
    }

    resetpassword = (e)=> {
e.preventDefault()

        var newPassword = this.state.newpassword;
        var minNumberofChars = 8;
        var maxNumberofChars = 25;
        var regularExpression = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        /*const requestOptions = {
            body: JSON.stringify({ email: this.state.email, password: md5(this.state.password) })
        };*/
        if (this.state.newpassword == "") {
            alert("Please enter new password");
        } else if (newPassword.length < minNumberofChars || newPassword.length > maxNumberofChars) {
            alert("Please enter minimum 8 character and maximum 25 character");
        } else if (!regularExpression.test(newPassword)) {
            alert("Password should contain atleast one upper,one lower,one number and one special character");

        } else if (this.state.confirmpassword == "") {
            alert("Please enter confirm password");
        } else if (this.state.confirmpassword != this.state.newpassword) {
            alert("Password does not match");
        } else {
            const formData = new FormData();
            const token =  localStorage.getItem('resetToken')
            if(!token){
                window.location.href = "/user/Facilitylogin";
                return
            }
            console.log(token);
            formData.append('password', this.state.newpassword);
            formData.append('resetToken',token);

            axios.post(API_BASE_URL + "/new-user-reset/", formData).then(response => { return response; }).then(res => {
                console.log(res);
                if(res.data.err){
                    alert(res.data.msg)
                }

                this.setState({ message: res.data.msg })
                localStorage.removeItem('resetToken')
                window.location.href = res.data.redirect === 'admin' ?"/user/login": "/user/Facilitylogin";

            }).catch(err => {
                console.log("dfdf", err);
                window.location.href = err.response.data.redirect === 'admin' ?"/user/login": "/user/Facilitylogin"
               

            });
        }

    }

    render() {
        // console.log("redirectredirect", this.state.user);
        // if (this.state.redirect) {
        //     window.location.href = "/admin/dashboard";

        // }

        return (
            <form onSubmit={this.resetpassword} >
                <GridContainer alignItems="center" justify="center">
                    <GridItem xs={8} sm={8} md={8}>
                        <Card>
                            <CardHeader color="primary">



                                <GridContainer alignItems="center" justify="space-between"><GridItem xs={8} sm={8} md={6}>
                                    <h4>Reset Password</h4>
                                </GridItem>
                                    <GridItem xs={8} sm={8} md={6}>
                                        <img src={logoimg} width="130" />

                                    </GridItem>



                                </GridContainer>
                            </CardHeader>
                            <CardBody>

                                {this.state.message && <GridContainer>

                                    <GridItem xs={8} sm={8} md={6}><p style={{ color: "green" }}>{this.state.message}</p></GridItem></ GridContainer>}
                                <GridContainer>

                                    <GridItem xs={8} sm={8} md={6}>
                                        <CustomInput
                                            labelText="New Password"
                                            id="heading"
                                            name="heading"
                                            type="password"
                                            onChange={this.handleNewpassword}
                                            value={this.state.newpassword}

                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={8} sm={8} md={6}>
                                        <CustomInput
                                            labelText="Confirm Password"
                                            id="subheading"
                                            type="password"
                                            name="subheading"
                                            onChange={this.handleConfirmpassword}
                                            value={this.state.confirmpassword}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>

                                </GridContainer>

                                <GridContainer alignItems="center">
                                    <GridItem xs={8} sm={8} md={6}>
                                        <Button color="primary" type="submit" >Submit</Button>
                                    </GridItem>

                                </GridContainer>

                            </CardBody>


                        </Card>
                    </GridItem>

                </GridContainer>
            </form >
        );
    }
}
