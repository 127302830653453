import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useEffect, useState } from 'react';

const QuarterBarChart = ({ data }) => {
  // data = [{month: "Q1"
  //   refundClaim
  //   :
  //   "758.00"
  //   refundSent
  //   :
  //   "2891.00"}]

  const [labels, setLabels] = useState([]);
  const [refundClaimed, setRefundClaimed] = useState([]);
  const [refundSent, setRefundSent] = useState([]);

  useEffect(() => {
    data.map((item) => {
      setLabels(data.map((item) => item.month));
      setRefundClaimed(data.map((item) => item.refundClaim));
      setRefundSent(data.map((item) => item.refundSent));
    });
  }, [data]);

  return (
    <Bar
      // plugins={[ChartDataLabels]}
      options={{
        plugins: {
          tooltip: {
            callbacks: {
              label: function (tooltipItem, data) {
                console.log();
                // let value = data.datasets[0].data[tooltipItem.index];
                return '$' + (tooltipItem.formattedValue ?? '0.00');
              },
            },
          },
          datalabels: {
            formatter: function (value, context) {
              return '$' + value;
            },
            anchor: 'end',
            align: 'right',
            clamp: false,
            color: 'grey',
            font: {
              weight: 'bold',
              size: 10,
            },
          },
          legend: {
            display: true,
            labels: {
              boxWidth: 5,
            },

            position: 'top',
            textDirection: 'rtl',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            grid: {
              color: 'rgba(0, 0, 0, 0)',
            },
          },
          x: {
            grid: {
              color: 'rgba(0, 0, 0, 0)',
            },
          },
        },
      }}
      data={{
        labels,
        datasets: [
          {
            data: refundSent,
            backgroundColor: '#557C55',
            borderWidth: 0,
            label: 'Refund Sent',
            borderRadius: 3,
          },
          {
            data: refundClaimed,
            backgroundColor: '#36A2EB',
            borderWidth: 0,
            label: 'Refund Claimed',
            borderRadius: 3,
          },
        ],
      }}
    />
  );
};

export default QuarterBarChart;
