import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useStyles } from 'components/TemplateModal/TemplateModal';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import { API_BASE_URL } from 'config';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  TextField,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomInput from 'components/CustomInput/CustomInput';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { type } from 'jquery';
import { Delete } from '@material-ui/icons';

const labelStyle = {
  fontSize: '12px',
  color: 'grey',
  cursor: 'pointer',
};
export default function CreateOrEditDataMappings({ dataMappingId }) {
  const [csvMapping, setCsvMapping] = useState({});
  const [dbNames, setDbNames] = useState({});
  const [updatingBlocks, setUpdatingBlocks] = useState([]);
  const [formValues, setFormValues] = useState({
    name: '',
    delimiter: '',
    contains_header: '',
    rows_to_skip: '0',
    refund_types: '',
    insurance_splitting_value: [],
    regular_splitting_value: [],
    file_source: '',
    file_destination: '',
    sftp_file_extension: '',
    is_encrypted: '',
    is_sftp_enabled: false,
  });

  const [originalFormValues, setOriginalFormValues] = useState({});

  const history = useHistory();

  const classes = useStyles();
  const regularInputList = [];
  const insuranceInputList = [];
  const { id: editId } = useParams();

  const addSplittingValue = (type) => {
    const splittingType =
      type === 'regular'
        ? 'regular_splitting_value'
        : 'insurance_splitting_value';
    setFormValues((values) => {
      return {
        ...values,
        [splittingType]: [...values[splittingType], ''],
      };
    });
  };

  const onSplittingValueChange = (e, index, type) => {
    const splittingType =
      type === 'regular'
        ? 'regular_splitting_value'
        : 'insurance_splitting_value';
    const newSplittingValues = formValues[
      splittingType
    ].map((splittingValue, i) =>
      i === index ? e.target.value : splittingValue,
    );
    setFormValues({
      ...formValues,
      [splittingType]: newSplittingValues,
    });
  };

  const removeSplittingValue = (index, type) => {
    const splittingType =
      type === 'regular'
        ? 'regular_splitting_value'
        : 'insurance_splitting_value';
    const newSplittingValues = formValues[splittingType].filter(
      (splittingValue, i) => i !== index,
    );
    setFormValues({
      ...formValues,
      [splittingType]: newSplittingValues,
    });
  };

  const onInputChange = (e, type) => {
    setFormValues((values) => {
      const dataMappingType =
        type === 'regular' ? 'regular_data_mapping' : 'insurance_data_mapping';

      return {
        ...values,

        [dataMappingType]: {
          ...values[dataMappingType],
          [e.target.name]: e.target.value,
        },
      };
    });
  };

  const getDefaultDataMappingValues = async () => {
    try {
      if (editId) return;
      const response = await axios.get(
        API_BASE_URL + '/data-mappings/default-data-mapping',
      );
      setFormValues((values) => ({
        ...values,
        regular_data_mapping: { ...response.data },
        insurance_data_mapping: { ...response.data },
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const getDataMapping = async (id) => {
    try {
      const response = await axios.get(API_BASE_URL + '/data-mappings/' + id);
      const formValues = {
        ...response.data,
        is_encrypted: response.data.is_encrypted.toString(),
        is_sftp_enabled: response.data.is_sftp_enabled,
        contains_header: response.data.contains_header.toString(),
        refund_types: response.data.refund_types ?? [],
      };
      setFormValues({
        ...formValues,
      });

      setOriginalFormValues({ ...formValues });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(async () => {
    if (editId) {
      await getDataMapping(editId);
    }
    getDefaultDataMappingValues();
  }, [editId]);

  const handleInputChange = (e) => {
    setFormValues((fs) => {
      return { ...fs, [e.target.name]: e.target.value };
    });
  };

  const createDataMapping = async () => {
    try {
      const response = await axios.post(
        API_BASE_URL + '/data-mappings/create-data-mapping-group',
        {
          ...formValues,
        },
      );
      history.push('/facility/manage-data-mapping');
    } catch (err) {
      console.log(err);
    }
  };

  const updateSftpConfiguration = async () => {
    try {
      if (!editId) return;
      const response = await axios.put(
        API_BASE_URL +
          '/data-mappings/' +
          '/update-sftp-configuration/' +
          editId,
        {
          is_sftp_enabled: formValues.is_sftp_enabled,
          file_source: formValues.file_source,
          file_destination: formValues.file_destination,
          sftp_file_extension: formValues.sftp_file_extension,
          is_encrypted: formValues.is_encrypted,
        },
      );
      setUpdatingBlocks((updates) => {
        return updates.filter((block) => block !== 'sftp-configuration');
      });
    } catch (err) {
      console.log(err);
    }
  };

  const updateFileConfiguration = async () => {
    try {
      if (!editId) return;
      const response = await axios.put(
        API_BASE_URL +
          '/data-mappings/' +
          '/update-file-configuration/' +
          editId,
        {
          name: formValues.name,
          delimiter: formValues.delimiter,
          contains_header: formValues.contains_header,
          rows_to_skip: formValues.rows_to_skip,
        },
      );
      setUpdatingBlocks((updates) => {
        return updates.filter((block) => block !== 'file-configuration');
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onFileConfigurationCancel = () => {
    setUpdatingBlocks((updates) => {
      return updates.filter((block) => block !== 'file-configuration');
    });

    setFormValues((values) => {
      return {
        ...values,
        name: originalFormValues.name,
        delimiter: originalFormValues.delimiter,
        contains_header: originalFormValues.contains_header,
        rows_to_skip: originalFormValues.rows_to_skip,
      };
    });
  };

  const onSftpConfigurationCancel = () => {
    setUpdatingBlocks((updates) => {
      return updates.filter((block) => block !== 'sftp-configuration');
    });

    setFormValues((values) => {
      return {
        ...values,
        is_sftp_enabled: originalFormValues.is_sftp_enabled,
        file_source: originalFormValues.file_source,
        file_destination: originalFormValues.file_destination,
        sftp_file_extension: originalFormValues.sftp_file_extension,
        is_encrypted: originalFormValues.is_encrypted,
      };
    });
  };

  const onDataMappingCancel = () => {
    setUpdatingBlocks((updates) => {
      return updates.filter((block) => block !== 'data-mapping');
    });

    setFormValues((values) => {
      return {
        ...values,
        refund_types: originalFormValues.refund_types,
        insurance_splitting_value: originalFormValues.insurance_splitting_value,
        regular_splitting_value: originalFormValues.regular_splitting_value,
        regular_data_mapping: { ...originalFormValues.regular_data_mapping },
        insurance_data_mapping: {
          ...originalFormValues.insurance_data_mapping,
        },
      };
    });
  };

  const updateDataMapping = async () => {
    try {
      if (!editId) return;
      const response = await axios.put(
        API_BASE_URL + '/data-mappings/update-data-mapping/' + editId,
        {
          refund_types: formValues.refund_types,
          insurance_splitting_value: formValues.insurance_splitting_value,
          regular_splitting_value: formValues.regular_splitting_value,
          regular_data_mapping: formValues.regular_data_mapping,
          insurance_data_mapping: formValues.insurance_data_mapping,
        },
      );
      setUpdatingBlocks((updates) => {
        return updates.filter((block) => block !== 'data-mapping');
      });
    } catch (err) {
      console.log(err);
    }
  };

  const isUpdating = (block) => updatingBlocks.includes(block) || !editId;

  const copyDataMappingFrom = (typeFrom) => {
    const dataMappingType =
      typeFrom === 'regular'
        ? 'insurance_data_mapping'
        : 'regular_data_mapping';
    const otherDataMappingType =
      typeFrom === 'regular'
        ? 'regular_data_mapping'
        : 'insurance_data_mapping';

    setFormValues((values) => {
      return {
        ...values,
        [dataMappingType]: { ...values[otherDataMappingType] },
      };
    });
  };

  const generateInput = (key, type) => {
    return (
      <GridItem key={key} style={{ margin: '10px', minWidth: '30%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}>
          <div
            onClick={() => {
              navigator.clipboard.writeText(`{{${key}}}`);
              toast.success('Copied to clipboard', { autoClose: 1000 });
            }}
            style={{ display: 'flex', alignItems: 'center' }}>
            <label style={labelStyle}>
              {' '}
              {key} - {'{{'}
              {key}
              {'}}'}
            </label>{' '}
            <AssignmentOutlinedIcon
              style={{ color: '#3AB795', fontSize: '20px', cursor: 'copy' }}
            />
          </div>

          <TextField
            fullWidth={true}
            disabled={!isUpdating('data-mapping')}
            key={key}
            variant='outlined'
            color='primary'
            className={classes.root}
            placeholder={key}
            multiline
            inputProps={{
              style: {
                outlineColor: '#00bcd4',
                borderColor: '#00bcd4',
                fontSize: '14px',
                lineHeight: '1',
                width: '300px',
              },
            }}
            minRows={1}
            maxRows={1}
            value={
              formValues?.[
                type === 'regular'
                  ? 'regular_data_mapping'
                  : 'insurance_data_mapping'
              ]?.[key]
            }
            onChange={(e) => onInputChange(e, type)}
            name={key}
          />
        </div>
      </GridItem>
    );
  };

  if (formValues?.regular_data_mapping) {
    for (const key of Object.keys(formValues.regular_data_mapping)) {
      regularInputList.push(generateInput(key, 'regular'));

      insuranceInputList.push(generateInput(key, 'insurance'));
    }
  }

  return (
    <div
      style={{
        marginTop: '-40px',
      }}>
      {/* <h2 className='subHeading'>
        {dataMappingId ? 'Edit Data Mapping' : 'Create Data Mapping'}
      </h2> */}

      <Card className='dashboardCard table_card_height'>
        <CardBody
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            // alignItems: 'center',
            height: '100%',
          }}>
          <h3 className='subHeading'>File Configuration</h3>

          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                type='text'
                labelText='Data Mapping Name'
                onChange={handleInputChange}
                value={formValues.name}
                name='name'
                disabled={!isUpdating('file-configuration')}
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <div className='custom-select'>
                <FormControl>
                  <InputLabel id='demo-simple-select-label'>
                    File Delimiter
                  </InputLabel>
                  <Select
                    disabled={!isUpdating('file-configuration')}
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    name='delimiter'
                    value={formValues.delimiter}
                    onChange={handleInputChange}
                    className='full-width-elem'>
                    <MenuItem value={'comma'}>Comma</MenuItem>
                    <MenuItem value={'pipe'}>Pipe</MenuItem>
                    <MenuItem value={'tab'}>Tab</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: '100%',
                }}>
                <FormControl>
                  <FormLabel
                    style={labelStyle}
                    id='demo-row-radio-buttons-group-label'>
                    Does the file contain a header with column names
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    value={formValues.contains_header}
                    onChange={handleInputChange}
                    name='contains_header'>
                    <FormControlLabel
                      value='true'
                      control={<Radio />}
                      label='Yes'
                      disabled={!isUpdating('file-configuration')}
                    />
                    <FormControlLabel
                      value='false'
                      control={<Radio />}
                      label='No'
                      disabled={!isUpdating('file-configuration')}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText='How many rows need to be bypassed from the top of the file'
                onChange={handleInputChange}
                type='number'
                value={formValues.rows_to_skip}
                disabled={!isUpdating('file-configuration')}
                name='rows_to_skip'
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
          </GridContainer>
          {editId && !updatingBlocks.includes('file-configuration') && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
              }}>
              <Button
                style={{ marginTop: '10px', marginBottom: '-10px' }}
                onClick={() =>
                  setUpdatingBlocks([...updatingBlocks, 'file-configuration'])
                }
                color='primary'>
                Edit
              </Button>
            </div>
          )}

          {updatingBlocks.includes('file-configuration') && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
              }}>
              <Button
                style={{ marginTop: '10px', marginBottom: '-10px' }}
                onClick={onFileConfigurationCancel}
                color='danger'>
                Cancel
              </Button>
              <Button
                style={{ marginTop: '10px', marginBottom: '-10px' }}
                onClick={updateFileConfiguration}
                color='primary'>
                Save File Configuration
              </Button>
            </div>
          )}
        </CardBody>
      </Card>
      <Card className='dashboardCard table_card_height'>
        <CardBody
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            // alignItems: 'center',
            height: '100%',
          }}>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <h3 className='subHeading'>SFTP Pick Up</h3>
            <Switch
              name='is_sftp_enabled'
              checked={formValues.is_sftp_enabled}
              disabled={!isUpdating('sftp-configuration')}
              onChange={(value) =>
                handleInputChange({
                  target: {
                    name: 'is_sftp_enabled',
                    value: value.target.checked,
                  },
                })
              }
            />
          </span>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                type='text'
                labelText='Folder location for file pickup'
                disabled={!isUpdating('sftp-configuration')}
                onChange={handleInputChange}
                value={formValues.file_source}
                name='file_source'
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                type='text'
                labelText='Folder location to move processed File'
                onChange={handleInputChange}
                disabled={!isUpdating('sftp-configuration')}
                value={formValues.file_destination}
                name='file_destination'
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                type='text'
                labelText='File extension that will be picked up'
                onChange={handleInputChange}
                disabled={!isUpdating('sftp-configuration')}
                value={formValues.sftp_file_extension}
                name='sftp_file_extension'
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: '100%',
                }}>
                <FormControl>
                  <FormLabel
                    style={labelStyle}
                    id='demo-row-radio-buttons-group-label'>
                    Is the file encrypted?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='is_encrypted'
                    value={formValues.is_encrypted}
                    onChange={handleInputChange}>
                    <FormControlLabel
                      value='true'
                      disabled={!isUpdating('sftp-configuration')}
                      control={<Radio />}
                      label='Yes'
                    />
                    <FormControlLabel
                      value='false'
                      disabled={!isUpdating('sftp-configuration')}
                      control={<Radio />}
                      label='No'
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </GridItem>
          </GridContainer>
          {editId && !updatingBlocks.includes('sftp-configuration') && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
              }}>
              <Button
                style={{ marginTop: '10px', marginBottom: '-10px' }}
                onClick={() =>
                  setUpdatingBlocks([...updatingBlocks, 'sftp-configuration'])
                }
                color='primary'>
                Edit
              </Button>
            </div>
          )}

          {updatingBlocks.includes('sftp-configuration') && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
              }}>
              <Button
                style={{ marginTop: '10px', marginBottom: '-10px' }}
                onClick={onSftpConfigurationCancel}
                color='danger'>
                Cancel
              </Button>
              <Button
                style={{ marginTop: '10px', marginBottom: '-10px' }}
                onClick={updateSftpConfiguration}
                color='primary'>
                Save SFTP Configuration
              </Button>
            </div>
          )}
        </CardBody>
      </Card>
      <Card className='dashboardCard table_card_height'>
        <CardBody
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            // alignItems: 'center',
            height: '100%',
          }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h3 className='subHeading'>Create your mapping</h3>
              <h2 style={{ fontSize: '17px' }} className='page-heading'>
                In the email / letter templates, please use the values in "
                {'{{}}'}" including the double curly brackets to populate
                fields:
              </h2>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <div className='custom-select'>
                    <FormControl component='fieldset'>
                      <FormLabel component='legend'>
                        payee category types In File
                      </FormLabel>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formValues.refund_types.includes(
                                'regular',
                              )}
                              onChange={(e) => {
                                const newRefundTypes = e.target.checked
                                  ? [...formValues.refund_types, 'regular']
                                  : formValues.refund_types.filter(
                                      (type) => type !== 'regular',
                                    );
                                handleInputChange({
                                  target: {
                                    name: 'refund_types',
                                    value: newRefundTypes,
                                  },
                                });

                                console.log(newRefundTypes);
                              }}
                              name='regular'
                              disabled={!isUpdating('data-mapping')}
                            />
                          }
                          label='Consumer Payments'
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formValues.refund_types.includes(
                                'insurance',
                              )}
                              onChange={(e) => {
                                const newRefundTypes = e.target.checked
                                  ? [...formValues.refund_types, 'insurance']
                                  : formValues.refund_types.filter(
                                      (type) => type !== 'insurance',
                                    );
                                handleInputChange({
                                  target: {
                                    name: 'refund_types',
                                    value: newRefundTypes,
                                  },
                                });
                              }}
                              name='insurance'
                              disabled={!isUpdating('data-mapping')}
                            />
                          }
                          label='Insurance Payments'
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}></GridItem>

                {formValues.refund_types.length > 1 && (
                  <GridItem xs={12} sm={12} md={6}>
                    <GridContainer>
                      {formValues.regular_splitting_value.map(
                        (splittingValue, index) => (
                          <GridItem xs={12} sm={12} md={12} key={index}>
                            <CustomInput
                              type='text'
                              labelText={`Consumer Payment Filter`}
                              onChange={(e) =>
                                onSplittingValueChange(e, index, 'regular')
                              }
                              value={splittingValue}
                              name='splittingValue'
                              formControlProps={{}}
                              disabled={!isUpdating('data-mapping')}
                            />
                            <Delete
                              onClick={() =>
                                removeSplittingValue(index, 'regular')
                              }
                              style={{ cursor: 'pointer' }}
                            />
                          </GridItem>
                        ),
                      )}
                    </GridContainer>
                    <div xs={12} sm={12} md={6} style={{ padding: 0 }}>
                      <Button
                        color='primary'
                        disabled={!isUpdating('data-mapping')}
                        onClick={() => addSplittingValue('regular')}>
                        Add Consumer Payment Filter
                      </Button>
                    </div>
                  </GridItem>
                )}

                {formValues.refund_types.length > 1 && (
                  <GridItem xs={12} sm={12} md={6}>
                    <GridContainer>
                      {formValues.insurance_splitting_value.map(
                        (splittingValue, index) => (
                          <GridItem xs={12} sm={12} md={12} key={index}>
                            <CustomInput
                              type='text'
                              labelText={`Insurance Filter`}
                              onChange={(e) =>
                                onSplittingValueChange(e, index, 'insurance')
                              }
                              value={splittingValue}
                              name='splittingValue'
                              formControlProps={{}}
                              disabled={!isUpdating('data-mapping')}
                            />
                            <Delete
                              onClick={() =>
                                removeSplittingValue(index, 'insurance')
                              }
                              style={{ cursor: 'pointer' }}
                            />
                          </GridItem>
                        ),
                      )}
                    </GridContainer>
                    <div xs={12} sm={12} md={6} style={{ padding: 0 }}>
                      <Button
                        color='primary'
                        disabled={!isUpdating('data-mapping')}
                        onClick={() => addSplittingValue('insurance')}>
                        Add Insurance Filter
                      </Button>
                    </div>
                  </GridItem>
                )}
              </GridContainer>

              {(formValues.refund_types.length > 1 ||
                formValues.refund_types.includes('regular')) && (
                <Accordion
                  style={{ marginTop: '20px' }}
                  key={'Consumer Payments'}
                  defaultExpanded={false}
                  disabled={!isUpdating('data-mapping')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel2-content'
                    id='panel2-header'>
                    <Typography>{'Consumer Payments'}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Card>
                      <CardBody>
                        {formValues.refund_types.length > 1 && (
                          <Button
                            color='primary'
                            disabled={!isUpdating('data-mapping')}
                            onClick={() => copyDataMappingFrom('insurance')}>
                            Copy From Below
                          </Button>
                        )}
                        <GridContainer
                          style={{ justifyContent: 'space-between' }}>
                          {regularInputList}
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </AccordionDetails>
                </Accordion>
              )}
              {(formValues.refund_types.length > 1 ||
                formValues.refund_types.includes('insurance')) && (
                <Accordion
                  key={'Insurance'}
                  defaultExpanded={false}
                  disabled={!isUpdating('data-mapping')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel2-content'
                    id='panel2-header'>
                    <Typography>{'Insurance'}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Card>
                      <CardBody>
                        {formValues.refund_types.length > 1 && (
                          <Button
                            color='primary'
                            disabled={!isUpdating('data-mapping')}
                            onClick={() => copyDataMappingFrom('regular')}>
                            Copy From Above
                          </Button>
                        )}
                        <GridContainer
                          style={{ justifyContent: 'space-between' }}>
                          {insuranceInputList}
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </AccordionDetails>
                </Accordion>
              )}
            </GridItem>
          </GridContainer>
          {editId && !updatingBlocks.includes('data-mapping') && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
              }}>
              <Button
                style={{ marginTop: '10px', marginBottom: '-10px' }}
                onClick={() =>
                  setUpdatingBlocks([...updatingBlocks, 'data-mapping'])
                }
                color='primary'>
                Edit
              </Button>
            </div>
          )}

          {updatingBlocks.includes('data-mapping') && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
              }}>
              <Button
                style={{ marginTop: '10px', marginBottom: '-10px' }}
                onClick={onDataMappingCancel}
                color='danger'>
                Cancel
              </Button>
              <Button
                style={{ marginTop: '10px', marginBottom: '-10px' }}
                onClick={updateDataMapping}
                color='primary'>
                Save Data Mapping
              </Button>
            </div>
          )}
        </CardBody>
      </Card>
      {!editId && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginTop: '20px',
          }}>
          <Button
            style={{ margin: '10px' }}
            onClick={createDataMapping}
            color='primary'>
            Save Mapping
          </Button>
        </div>
      )}
    </div>
  );
}
