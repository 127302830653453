import React, { Component } from 'react';

import ReactDOM from 'react-dom';
import { Link, Redirect } from 'react-router-dom';
import { API_BASE_URL, API_BASE_IMG_URL } from '../../config';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import axios from 'axios';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardAvatar from 'components/Card/CardAvatar.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import avatar from 'assets/img/faces/marc.jpg';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

//const useStyles = makeStyles(styles);

//const classes = useStyles();

export default class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      template_name: '',
      heading: '',
      subheading: '',
      contact_number: '',
      message: '',
      selectedTemplateimage: null,
      template_image: '',
      logo_image: '',
      selectedLogoimage: null,
      background_image: '',
      selectedBackgroundImage: null,
      redirect: false,
      dellogo: false,
    };
    this.handleName = this.handleName.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleTempName = this.handleTempName.bind(this);
    this.handleHeading = this.handleHeading.bind(this);
    this.handleSubheading = this.handleSubheading.bind(this);
    this.handleContactNum = this.handleContactNum.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
  }

  deletelogo() {
    this.setState({ dellogo: true });
  }

  /*** functions to set input values in state***/

  handleName(event) {
    this.setState({ name: event.target.value });
  }
  handleEmail(event) {
    this.setState({ email: event.target.value });
  }
  handleTempName(event) {
    this.setState({ template_name: event.target.value });
  }
  handleHeading(event) {
    this.setState({ heading: event.target.value });
  }
  handleSubheading(event) {
    this.setState({ subheading: event.target.value });
  }
  handleContactNum(event) {
    this.setState({ contact_number: event.target.value });
  }
  handleMessage(event) {
    this.setState({ message: event.target.value });
  }

  onTempFileChange = (event) => {
    this.setState({ selectedTemplateimage: event.target.files[0] });
  };

  onLogoFileChange = (event) => {
    this.setState({ selectedLogoimage: event.target.files[0] });
  };

  onBackgroundFileChange = (event) => {
    this.setState({ selectedBackgroundImage: event.target.files[0] });
  };

  /*** function to integrate api to create template*/

  createtemplate = () => {
    var formData = new FormData();
    if (this.state.template_name) {
      formData.append('template_name', this.state.template_name);
    }
    if (this.state.heading) {
      formData.append('heading', this.state.heading);
    }
    if (this.state.name) {
      formData.append('name', this.state.name);
    }
    if (this.state.email) {
      formData.append('email', this.state.email);
    }
    if (this.state.contact_number) {
      formData.append('contact_number', this.state.contact_number);
    }
    if (this.state.message) {
      formData.append('message', this.state.message);
    }
    if (this.state.subheading) {
      formData.append('subheading', this.state.subheading);
    }
    if (this.state.selectedTemplateimage) {
      formData.append('template_image', this.state.selectedTemplateimage);
    }
    if (this.state.selectedLogoimage) {
      formData.append('logo_image', this.state.selectedLogoimage);
    }
    if (this.state.selectedBackgroundImage) {
      formData.append('background_image', this.state.selectedBackgroundImage);
    }

    axios
      .post(API_BASE_URL + '/api/createtemplate', formData)
      .then((response) => {
        return response;
      })
      .then((res) => {
        this.setState({ redirect: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to='/admin/managetemplate' />;
    }

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color='primary'>
                <h4>Create Template</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Template Name'
                      id='Facility-disabled'
                      type='text'
                      onChange={this.handleTempName}
                      value={this.state.template_name}
                      name='template_name'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <label>Template Image</label>
                    <div
                      style={{
                        backgroundColor: 'rgb(0 149 255)',
                        padding: '13px',
                        color: '#ffffff',
                      }}>
                      <input
                        labelText='Template Image'
                        name='template_image'
                        onChange={this.onTempFileChange}
                        type='file'
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  {!this.state.dellogo && (
                    <GridItem xs={12} sm={12} md={6}>
                      <label>Logo Image</label>
                      <div style={{ padding: '13px' }}>
                        <input
                          name='logo_image'
                          onChange={this.onLogoFileChange}
                          type='file'
                        />
                        <a
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.deletelogo()}>
                          {' '}
                          <span className='material-icons'>delete</span>
                        </a>
                      </div>
                    </GridItem>
                  )}
                  <GridItem xs={12} sm={12} md={6}>
                    <label>Background Image</label>
                    <div style={{ padding: '13px' }}>
                      <input
                        name='background_image'
                        onChange={this.onBackgroundFileChange}
                        type='file'
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Heading'
                      id='heading'
                      name='heading'
                      onChange={this.handleHeading}
                      value={this.state.heading}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Sub Heading'
                      id='subheading'
                      type='text'
                      name='subheading'
                      onChange={this.handleSubheading}
                      value={this.state.subheading}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      type='text'
                      labelText='Name'
                      onChange={this.handleName}
                      value={this.state.name}
                      id='name'
                      name='name'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Email'
                      id='email'
                      name='email'
                      onChange={this.handleEmail}
                      value={this.state.email}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Contact Number'
                      id='contact_number'
                      name='contact_number'
                      onChange={this.handleContactNum}
                      value={this.state.contact_number}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText='Message'
                      id='message'
                      name='message'
                      onChange={this.handleMessage}
                      value={this.state.message}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color='primary' onClick={this.createtemplate}>
                  Create Template
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
