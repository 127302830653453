import React, { useEffect, useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import axios from 'axios';
import Moment from 'react-moment';
import fileDownload from 'js-file-download';
import { decode } from 'js-base64';

import { API_BASE_URL, API_BASE_IMG_URL } from '../../config';
// @material-ui/core components
import { makeStyles, useTheme } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import Button from 'components/CustomButtons/Button.js';
import loader from '../../assets/img/loader.svg';

// components for table.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';
import { TablePaginationActions } from 'views/AmazonTransactions/AmazonTransactions';
import { Checkbox } from '@material-ui/core';
import { toast } from 'react-toastify';

const styles = {
  input: {
    height: '20px',
    width: '20px',
  },

  inputCursor: {
    cursor: 'pointer',
    height: '20px',
    width: '20px',
  },
};

const useStyles = makeStyles(styles);
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));
const AchPayments = () => {
  const classes = useStyles();
  const [msg, setMsg] = React.useState(null);
  const [errormsg, setErrormsg] = React.useState(null);
  const [redirects, setRedirects] = React.useState(false);
  const [paymentdetailid, setPaymentdetailid] = React.useState(null);

  const [loaded, setLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [payments, setPayments] = useState([]);
  const [facilityID, setFacilityID] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [total, setTotal] = useState(0);
  const [selectedFacility, setSelectedFacility] = useState('');
  const [facilities, setFacilities] = useState([]);
  const [facility, setFacility] = useState();
  const [paymentType, setPaymentType] = useState('ACH');
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, payments.length - page * rowsPerPage);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handlePaymentChange = (e) => {
    setPaymentType(e.target.value);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getPaymentFileRecords = async () => {
    setLoading(true);
    const response = await axios.get(
      `${API_BASE_URL}/getPaymentFileRecords?${`paymentType=${paymentType}`}${
        facility ? `&facility=${facility}` : ''
      }`
    );
    setLoading(false);
    setPayments(response.data);
  };
  const isAllChecked = () => {
    if (!payments.length) return false;
    return payments.every((payment, index) => {
      if (rowsPerPage < 0) {
        return payment.isChecked || payment.refund_status === 'Completed';
      }

      return (page + 1) * rowsPerPage > index
        ? payment.isChecked || payment.refund_status === 'Completed'
        : true;
    });
  };
  const isAllCompleted = () => {
    if (!payments.length) return false;
    return payments.every((payment, index) => {
      if (rowsPerPage < 0) {
        return payment.refund_status === 'Completed';
      }

      return (page + 1) * rowsPerPage > index
        ? payment.refund_status === 'Completed'
        : true;
    });
  };

  const warnToast = () => {
    toast.warning('Please select a facility', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onAllCheck = (e) => {
    if (!facility) return warnToast();
    console.log(page + 1, rowsPerPage);
    const newPayments = payments.map((payment, index) => {
      if (
        payment.refund_status === 'Pending' ||
        payment.refund_status === 'Pending-sent' ||
        payment.refund_status === 'ACH-sent' ||
        payment.refund_status === 'Pending-pp-sent'
      ) {
        if (rowsPerPage < 0) {
          payment.isChecked = e.target.checked;
          return payment;
        }
        if ((page + 1) * rowsPerPage > index)
          payment.isChecked = e.target.checked;
      }

      return payment;
    });
    setPayments(newPayments);
  };

  const handleOnCheck = (position, isChecked) => {
    if (!facility) return warnToast();
    setPayments((payment) => {
      const paymentArr = [...payment];
      paymentArr[position].isChecked = !isChecked;
      return paymentArr;
    });
  };

  const calculateTotalChecked = () => {
    return payments.reduce((total, current) => {
      if (current.isChecked) {
        return total + +current.refund_amount;
      }
      return total;
    }, 0);
  };

  const onGenerateFile = async () => {
    const ids = payments
      .filter((payment) => payment.isChecked)
      .map((payment) => payment.id);
    if (!ids.length) {
      setErrormsg('Please select atleast one payment');
      return;
    }
    const response = await axios.post(`${API_BASE_URL}/generateAchFile`, {
      ids,
      facility_id: facility,
      paymentType,
    });
    getPaymentFileRecords();
    console.log(response);
  };
  const selectFacility = (event) => {
    setFacility(event.target.value);
  };

  useEffect(async () => {
    const facilityRes = await axios
      .get(API_BASE_URL + '/facilitiesPro')
      .then((response) => {
        return response;
      });
    setFacilities(facilityRes.data);
  }, []);

  useEffect(async () => {
    getPaymentFileRecords();
  }, [facility, paymentType]);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color='primary'>
            <h4>ACH / Check Transactions</h4>
          </CardHeader>

          <CardBody>
            <div>
              {loading && (
                <div className='loaders'>
                  <img src={loader} />
                </div>
              )}
              <div className='t-header'>
                <div
                  className='t-header-left'
                  style={{ fontWeight: 'bold', fontSize: '15px' }}>
                  Total = $
                  {parseFloat(calculateTotalChecked()).toLocaleString(
                    undefined,
                    {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }
                  )}
                </div>
                <div className='t-header-right'>
                  <div
                    // style={{ margin: '0 20px' }}
                    className='custom-search-box'>
                    <div className='custom-select'>
                      <select
                        className='full-width-elem'
                        id='Facility'
                        onChange={selectFacility}>
                        <option value='' style={{ fontWeight: 'bold' }}>
                          Facilities
                        </option>
                        {facilities.map((item) => {
                          return (
                            <>
                              <option
                                value={item.facilityID}
                                className='parent'
                                style={{ fontWeight: 'bold' }}
                                key={item.facilityID}>
                                {item.facilityName}
                              </option>
                              {item.subFacilityList.length > 0 &&
                                item.subFacilityList.map((itemInner, index) => {
                                  return (
                                    <option
                                      value={itemInner.id}
                                      className='child'
                                      style={{ textAlign: 'center' }}
                                      key={itemInner.id}>
                                      --{itemInner.name}
                                    </option>
                                  );
                                })}
                            </>
                          );
                        })}
                      </select>
                    </div>

                    <span className='search-icon'></span>
                  </div>
                  <div
                    style={{ margin: '0 10px', width: '200px' }}
                    className='custom-search-box'>
                    <div className='custom-select'>
                      <select
                        className='full-width-elem'
                        id='Facility'
                        onChange={handlePaymentChange}>
                        <option value='ACH' style={{ fontWeight: 'bold' }}>
                          ACH Payments
                        </option>
                        <option
                          value='POSITIVE_PAY'
                          style={{ fontWeight: 'bold' }}>
                          Check Payments
                        </option>
                      </select>
                    </div>
                    <span className='search-icon'></span>
                  </div>
                  <Button
                    style={{
                      cursor: 'pointer',
                    }}
                    color='primary'
                    id='dt'
                    onClick={onGenerateFile}>
                    Generate{' '}
                    {paymentType === 'ACH'
                      ? 'ACH'
                      : paymentType === 'POSITIVE_PAY'
                      ? 'Positive pay'
                      : ''}{' '}
                    file
                  </Button>
                </div>
              </div>
              <Table width='100%' id='template'>
                <TableHead>
                  <TableRow>
                    <TableCell width='5%' align='left'>
                      <input
                        type='checkbox'
                        id='allSelect'
                        name='allSelect'
                        checked={isAllChecked()}
                        disabled={!payments.length || isAllCompleted()}
                        onChange={onAllCheck}
                        style={styles.inputCursor}
                      />
                    </TableCell>
                    <TableCell width='10%' align='left'>
                      Date
                    </TableCell>
                    <TableCell width='15%' align='left'>
                      Customer
                    </TableCell>
                    <TableCell align='left'>Email</TableCell>
                    <TableCell align='left'>Phone</TableCell>
                    <TableCell align='left'>Amount($)</TableCell>
                    <TableCell align='left'>Reference</TableCell>
                    <TableCell align='left'>Payment Status</TableCell>
                  </TableRow>
                </TableHead>
                {payments && payments.length > 0 ? (
                  <TableBody>
                    {(rowsPerPage > 0
                      ? payments.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : payments
                    ).map((obj, index) => (
                      <TableRow className='txtalign' key={index}>
                        <TableCell>
                          <input
                            type='checkbox'
                            id={obj.id}
                            name={obj.first_name}
                            style={
                              obj.refund_status == 'Completed'
                                ? styles.input
                                : styles.inputCursor
                            }
                            checked={
                              obj.refund_status == 'Completed'
                                ? true
                                : obj.isChecked ?? false
                            }
                            disabled={obj.refund_status == 'Completed'}
                            onChange={() => handleOnCheck(index, obj.isChecked)}
                          />
                        </TableCell>
                        <TableCell>
                          {obj && obj.status_updatedat && (
                            <Moment format='MM-DD-YYYY'>
                              {obj && obj.status_updatedat}
                            </Moment>
                          )}
                        </TableCell>
                        <TableCell>
                          {obj && obj.first_name} {obj && obj.last_name}
                        </TableCell>
                        <TableCell>{obj && obj.email}</TableCell>
                        <TableCell>{obj && obj.phone}</TableCell>
                        <TableCell>
                          {parseFloat(obj && obj.refund_amount).toFixed(2)}
                        </TableCell>
                        <TableCell>{obj.external_id}</TableCell>
                        <TableCell>
                          {obj && obj.transaction_status && (
                            <div
                              style={{
                                cursor: 'pointer',
                                color:
                                  obj && obj.transaction_status == 'Completed'
                                    ? ''
                                    : '#ff0000',
                              }}>
                              {obj && obj.transaction_status}
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell>No ACH Transaction Data Found</TableCell>
                    </TableRow>
                  </TableBody>
                )}
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: 'All', value: -1 },
                      ]}
                      colSpan={3}
                      count={payments.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default AchPayments;
