import { Box, Dialog } from '@mui/material';
import { Button } from '@material-ui/core';

const EconsentModal = ({
  econsentAgreed,
  econsent,
  agreeEconsent,
  onClose,
}) => {
  return (
    <Dialog
      open={econsentAgreed === false && econsent?.enabled}
      onClose={onClose}
      scroll='body'
      aria-labelledby='form-dialog-title'
      className='otp-dialog'>
      <Box>
        <div style={{ maxWidth: '600px', padding: '20px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
            className='email-box-header'>
            <h1>
              <strong>{econsent?.title}</strong>
            </h1>
            {econsent?.logo && (
              <img
                style={{ maxHeight: '45px', maxWidth: '300px' }}
                src={econsent?.logo}
              />
            )}
          </div>
          <p className='less-spacing'>{econsent?.heading}</p>
          <div style={{ maxHeight: '350px', overflow: 'auto' }}>
            <p
              style={{ fontSize: '12px' }}
              dangerouslySetInnerHTML={{ __html: econsent?.body }}></p>{' '}
          </div>
          <div
            style={{
              marginTop: '35px',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Button
              onClick={agreeEconsent}
              variant='contained'
              className='btn-solid'>
              I Agree
            </Button>
          </div>
        </div>
      </Box>
    </Dialog>
  );
};

export default EconsentModal;
