import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useParams, Redirect } from 'react-router-dom';
import loader from '../../assets/img/loader.svg';
import PropTypes from 'prop-types';
import { Base64 } from 'js-base64';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { API_BASE_URL, API_BASE_IMG_URL } from '../../config';
import Client from './Client';
import axios from 'axios';
import logo from '../../assets/img/logo.png';
import { decode } from 'js-base64';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import tickmethod from '../../assets/img/tick-method.svg';
import charityIcon from '../../assets/img/charity-icon.svg';
import PaymentModal from 'components/Card/PaymentModal';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={4}>
          <Typography component={'span'} variant={'body2'}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
export default function AmazonGiftCard() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [refundName, setRefundName] = React.useState(null);
  const [msg, setMsg] = React.useState(null);
  const [errormsg, setErrormsg] = React.useState(null);
  const [loaded, setloaded] = React.useState(false);

  const [open3, setOpen3] = React.useState(false);
  const [charity, setCharity] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const params = useParams();

  const handleClickPopup = () => {
    setOpen3(true);
  };

  const handleCancel = () => {
    setCharity(false);
    setOpen3(true);
  };
  const handleDonation = () => {
    setCharity(true);
    setOpen3(false);
  };
  const handleAmazon = async () => {
    setloaded(true);
    let slug = params && params.id;
    const formData = new FormData();
    formData.append(
      'verificationToken',
      sessionStorage.getItem('otpForPayment')
    );
    try {
      const response = await axios.post(
        API_BASE_URL + '/add_giftcard/' + slug,
        formData
      );
      console.log(response);
      window.location.href = `/client/thankyou/` + slug;
      setMsg(response.data.message);
    } catch (err) {
      setErrormsg(err.response.data.message);
    }
  };

  return (
    <div className='submit-mail-wrapper'>
      {open3 && <Client open3={true} />}
      <PaymentModal
        charity={charity}
        handleCancel={handleCancel}
        open3={open3}
        handleDonation={handleDonation}
      />
      <header className='s-header'>
        <div className='container'>
          <div className='s-header_inner'>
            <div className='inner-brand'>
              <img src={logo} alt='' />
            </div>
            <div className='site-links'>
              <ul className='n-links'>
                {/* <li>
                  <Link to='/' className='n-link'>
                    FAQ
                  </Link>
                </li> */}
                <li>
                  <a
                    href='https://www.clientpaydirect.com/contact'
                    className='n-link'
                    target='_blank'>
                    Contact us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <p><a href="javascript:void(0)" style={{ "margin-left": "100px" }} onClick={handleClickPopup} > Select Different Payment Option </a></p> */}
      </header>
      <div className='main-content p-b-30'>
        <div className='container'>
          {loaded && (
            <div
              className='loaders'
              style={{
                alignSelf: 'center',
                justifySelf: 'center',
                marginLeft: '550px',
              }}>
              <img src={loader} />
            </div>
          )}
          <div className='payment'>
            <div className='payment-amazonleft'></div>
            <div className='payment-right'>
              <div className='payment-right_inner'>
                <h2 className='page-heading'>Refund Via Amazon Gift Card</h2>
                {/*<!--<p className="page-desc">Please confirm your address below and click 'Submit' to receive your funds in 7 - 10 business days</p>-->*/}

                <p className='page-desc'>
                  {' '}
                  <b>{refundName}</b>
                </p>
                <p className='page-desc'>
                  Please click <b>'submit'</b> and you will be mailed an Amazon
                  gift card code to your email address on file in 5-7 days. No
                  further action is needed. Thank you for using Client Pay
                  Direct.
                </p>
                {msg && <p style={{ color: 'green' }}>{msg}</p>}
                {errormsg && <p style={{ color: 'red' }}>{errormsg}</p>}
                <div className='tab-section'>
                  <TabPanel value={value} index={0}>
                    <div className='payment-form'>
                      <form noValidate>
                        <div className='form-row'>
                          <div className='w100'>
                            <label className='form-label'></label>
                            <div className='form-field'>
                              {/*<!--<input type="text" className="form-control" value={address1} readOnly />-->*/}
                            </div>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div className='w100'>
                            <label className='form-label'></label>
                            <div className='form-field'>
                              {/*<!--<input type="text" className="form-control" value={address2} readOnly />-->*/}
                            </div>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div className='w33'>
                            <label className='form-label'></label>
                            <div className='form-field'>
                              {/*<!--<input type="text" className="form-control" value={city} readOnly />-->*/}
                            </div>
                          </div>
                          <div className='w33'>
                            <label className='form-label'></label>
                            <div className='form-field'>
                              {/*<!--<input type="text" className="form-control" value={state} readOnly />-->*/}
                            </div>
                          </div>
                          <div className='w33'>
                            <label className='form-label'></label>
                            <div className='form-field'>
                              {/*<!--<input type="text" className="form-control" value={zip} readOnly />-->*/}
                            </div>
                          </div>
                        </div>

                        <div className='form-row'>
                          <div className='w50'>
                            <div className='form-btn'>
                              <Button
                                variant='contained'
                                className='form-save'
                                onClick={handleAmazon}>
                                Submit
                              </Button>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className='mt-2'>
                        <button
                          variant='contained'
                          className='btn-green-outline'
                          onClick={handleClickPopup}>
                          <ArrowBackIosIcon />
                          Select Different Payment Option
                        </button>
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
