const isUat = process.env.REACT_APP_STAGE.trim() === 'uat';
const isProduction = process.env.REACT_APP_STAGE.trim() === 'prod';
const isProductionTest = process.env.REACT_APP_STAGE.trim() === 'prodtest';

export const API_BASE_URL = isProduction
  ? 'https://secureapi.clientpaydirect.com'
  : isProductionTest
  ? 'https://secureapi.clientpaydirect.com'
  : isUat
  ? 'https://api.uat.clientpaydirect.com'
  : 'http://localhost:3003';
export const API_BASE_IMG_URL = API_BASE_URL + '/uploads/';
console.log(process.env.REACT_APP_STAGE, 'new');

export const PUBLIC_ASSETS_BASE_URL = isProduction
  ? 'https://assets.clientpaydirect.com/'
  : isUat
  ? 'https://assets.uat.clientpaydirect.com/'
  : 'https://assets.uat.clientpaydirect.com/';

export const CLAIM_CLIENT_URL = isProduction
  ? 'https://claim.clientpaydirect.com'
  : isUat
  ? 'https://claim.uat.clientpaydirect.com'
  : 'http://localhost:5173';

export const RECAPTCHA_KEY = isProduction
  ? '6LdfBz8pAAAAAJEbouVU4BZAzjxJ1OvOIFYvWGD2'
  : isUat
  ? '6LfiqTgpAAAAAKZ6VHQCoIxfO4SbSOI42a6oRA6H'
  : '6LesEzgpAAAAAOS6xK9u6FeOhPMOnhREyVVYtuEk';

export const POSTHOG_KEY = isProduction
  ? 'phc_ml7OWfCHOjAKLq0GS5VZJf5KpkDRfVbYEw8qimGGPM3'
  : 'phc_QRHAJcnkHfh6t9OAm24REasop1DzT5SfYyZhdjjptGv';

export const POSTHOG_HOST = 'https://app.posthog.com';

export const defaultLetterTemplate = {
  name: 'Dear {{first_name}} {{last_name}}, ',
  date: 'Date {{date}}',

  line1:
    'Due to an identified overpayment for recent medical procedures, we are writing to inform you that {{facility_name}} owes you a refund in the amount of ${{refund_amount}}',
  line2:
    '{{facility_name}} uses our services to reach out to refunds like you and make getting your refunds easier.',
  line3: `To claim your refund:

  1.    Please visit {{claim_url}} and enter {{unique_code}}
  
  OR
  
  2.    Email us at hello@clientpaydirect.com and have your unique code of {{unique_code}} available to reference.`,
  disclaimer: `Please do not share this code with anyone outside of ClientPay Direct or {{facility_name}}. This is a unique code for you to claim your refund.
Please note that this code will be valid for 60 days after this letter has been sent. Please feel free to email us with any questions or concerns.`,

  signature: `Thank you.
Client Pay Direct
hello@clientpaydirect.com`,

  logo: null,
  logoPreview: null,
};

export const bankNames = Object.freeze({
  JPMORGAN: 'jpmorgan',
  CAPITAL_ONE: 'capitalone',
  BOA: 'BOA',
  First_Fed_Bank: 'First Fed Bank',
});
