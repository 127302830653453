import { AgGridReact } from 'ag-grid-react';
// import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useEffect, useState } from 'react';

const ReportPreview = ({ refundReport }) => {
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    setColumns(
      refundReport.length
        ? Object.keys(refundReport[0]).map((key) => {
            return {
              headerName: key,
              field: key,
              sortable: true,
            };
          })
        : []
    );
  }, [refundReport]);

  return (
    <div
      className='ag-theme-alpine'
      style={{
        height: '100%',
        width: '100%',
      }}>
      <AgGridReact
        defaultColDef={{ resizable: true }}
        columnDefs={columns}
        rowData={refundReport}
        icons={{
          sortAscending:
            '<i style="font-size:8px;"  className="fa fa-sort-up">ASC</i>',
          sortDescending:
            '<i  style="font-size:8px;" className="fa fa-sort-down">DSC</i>',
        }}></AgGridReact>
    </div>
  );
};

export default ReportPreview;
