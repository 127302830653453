import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import axios from 'axios';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import { API_BASE_URL } from 'config';
import React, { useEffect, useState } from 'react';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import Moment from 'react-moment';
import { DeleteForeverRounded } from '@material-ui/icons';
import moment from 'moment';
import { utcToLocal } from 'utility';
import FileProcessingErrorModal from 'components/FileProcessingErrorModal.jsx/FileProcessingErrorModal';
import { Typography } from '@mui/material';

const UploadedFiles = () => {
  const [files, setFiles] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [facility, setFacility] = useState('');
  const [fileProcessingErrors, setFileProcessingErrors] = useState(null);
  const selectFacility = (event) => {
    setFacility(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, files.length - page * rowsPerPage);
  const getFiles = async () => {
    const filesList = await axios.post(API_BASE_URL + '/adminGetFilesList', {
      facilityId: facility,
    });
    setFiles(filesList.data);
  };
  useEffect(() => {
    getFiles();
  }, [facility]);
  useEffect(async () => {
    const response = await axios.get(API_BASE_URL + '/facilitiesPro');

    setFacilities(response.data);
  }, []);

  const processFile = async (fileId) => {
    try {
      await axios.get(API_BASE_URL + '/processFileById/' + fileId);
      getFiles();
    } catch (err) {
      console.log(err);
      if (err.response.status === 400 && err.response.data.processingErrors) {
        console.log(err.response.data.processingErrors);
        setFileProcessingErrors(err.response.data.processingErrors);
      }
    }
  };

  const deleteCsvFile = async (id) => {
    //confirm

    if (!window.confirm('Are you sure you want to delete this file?')) {
      return;
    }

    await axios.delete(API_BASE_URL + '/deleteCsvFile/' + id);
    getFiles();
  };

  const clearErrors = () => {
    setFileProcessingErrors(null);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color='primary'>
            <h4>Files Uploaded</h4>
          </CardHeader>

          <CardBody>
            <div>
              <div className='t-header'>
                <div className='t-header-right'>
                  <div className='custom-search-box'>
                    <div className='custom-select'>
                      <select
                        className='full-width-elem'
                        id='Facility'
                        onChange={selectFacility}>
                        <option value='' style={{ fontWeight: 'bold' }}>
                          Facilities
                        </option>
                        {facilities.map((item) => {
                          return (
                            <>
                              <option
                                value={item.facilityID}
                                className='parent'
                                style={{ fontWeight: 'bold' }}
                                key={item.facilityID}>
                                {item.facilityName}
                              </option>
                              {item.subFacilityList.length > 0 &&
                                item.subFacilityList.map((itemInner, index) => {
                                  return (
                                    <option
                                      value={itemInner.id}
                                      className='child'
                                      style={{ textAlign: 'center' }}
                                      key={itemInner.id}>
                                      --{itemInner.name}
                                    </option>
                                  );
                                })}
                            </>
                          );
                        })}
                      </select>
                    </div>
                    <span className='search-icon'></span>
                  </div>
                </div>
              </div>
              <Table width='100%' id='template'>
                <TableHead>
                  <TableRow>
                    <TableCell>File Uploaded Date</TableCell>
                    <TableCell>Facility name</TableCell>
                    <TableCell>File status</TableCell>
                    <TableCell>Uploaded By</TableCell>
                    <TableCell>File Name</TableCell>
                    <TableCell>Record Count</TableCell>
                    <TableCell>Total Amount</TableCell>
                    <TableCell>Processed date</TableCell>
                    <TableCell width='5%'></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {files && files.length > 0 ? (
                  <TableBody>
                    {(rowsPerPage > 0
                      ? files.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : files
                    ).map((obj, index) => (
                      <TableRow className='txtalign' key={index}>
                        <TableCell>
                          {obj.created_at
                            ? utcToLocal(obj.created_at, 'MM/DD/YYYY')
                            : '-'}
                        </TableCell>
                        <TableCell>{obj && obj.name}</TableCell>
                        <TableCell>
                          {obj.process_status === 1
                            ? 'Processed'
                            : 'Unprocessed'}
                        </TableCell>
                        <TableCell>{obj.uploaded_by}</TableCell>

                        <TableCell>
                          <span title={obj.filename}>
                            <Typography fontSize={14} width={130} noWrap>
                              {obj.filename}
                            </Typography>
                          </span>
                        </TableCell>
                        <TableCell>
                          {obj.total_records === null
                            ? 'N/A'
                            : obj.total_records}
                        </TableCell>
                        <TableCell>
                          {obj.total_amount === null ? 'N/A' : obj.total_amount}
                        </TableCell>
                        <TableCell>
                          {obj.processed_at ? (
                            <Moment
                              style={{ textAlign: 'center', width: '100%' }}
                              format='MM-DD-YY'>
                              {obj.processed_at}
                            </Moment>
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell width='5%'>
                          <a
                            href={
                              API_BASE_URL +
                              '/getFile?filePath=' +
                              obj.file_location
                            }>
                            <div>
                              <CloudDownloadIcon />
                            </div>
                          </a>
                          {obj.process_status !== 1 && (
                            <a onClick={() => deleteCsvFile(obj.id)}>
                              <div>
                                <DeleteForeverRounded />
                              </div>
                            </a>
                          )}
                        </TableCell>
                        <TableCell width='15%'>
                          <Button
                            onClick={processFile.bind(null, obj.id)}
                            color='primary'
                            disabled={obj.process_status === 1}>
                            {' '}
                            Process Files{' '}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell>No Transaction Data Found</TableCell>
                    </TableRow>
                  </TableBody>
                )}
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: 'All', value: -1 },
                      ]}
                      colSpan={3}
                      count={files.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <FileProcessingErrorModal
        errors={fileProcessingErrors}
        close={clearErrors}
      />
    </GridContainer>
  );
};

export default UploadedFiles;
