import { useEffect, useState } from 'react';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import loader from '../../assets/img/loader.svg';
import { toast } from 'react-toastify';
import axios from 'axios';
import { API_BASE_URL } from 'config';
import { Link, useParams, useHistory } from 'react-router-dom';
import { PUBLIC_ASSETS_BASE_URL } from 'config';
import { Delete } from '@material-ui/icons';

export default function CreateOridBusinessUnit() {
  // useParams
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [formInputs, setFormInputs] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zipcode: '',
    logo: '',
    logoPreview: '',
    identifiers: [],
  });

  useEffect(async () => {
    if (!id) return;
    const businessUnit = (
      await axios.get(API_BASE_URL + '/business-units/' + id)
    ).data;
    setFormInputs({
      name: businessUnit.name,
      email: businessUnit.email,
      phone: businessUnit.phone,
      address: businessUnit.address,
      city: businessUnit.city,
      state: businessUnit.state,
      zipcode: businessUnit.zipcode,
      logoPreview: businessUnit.logo
        ? PUBLIC_ASSETS_BASE_URL + businessUnit.logo
        : null,
      identifiers: businessUnit.identifiers,
    });
  }, []);

  const handleInputChange = (e) => {
    setFormInputs({
      ...formInputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogoUpload = (e) => {
    setFormInputs({
      ...formInputs,
      logoPreview: URL.createObjectURL(e.target.files[0]),
      logo: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      if (!formInputs.name || !formInputs.logoPreview) {
        toast.error('Name and Logo is required');
        setLoading(false);
        return;
      }

      const formData = new FormData();
      formData.append('name', formInputs.name);
      formData.append('email', formInputs.email);
      formData.append('phone', formInputs.phone);
      formData.append('address', formInputs.address);
      formData.append('city', formInputs.city);
      formData.append('state', formInputs.state);
      formData.append('zipcode', formInputs.zipcode);
      formData.append('identifiers', JSON.stringify(formInputs.identifiers));

      if (formInputs.logo)
        formData.append('logo', formInputs.logo, formInputs.logo.name);

      if (id) {
        await axios.put(API_BASE_URL + '/business-units/' + id, formData);
        setLoading(false);
        history.push('/facility/business-units');

        return;
      }

      await axios.post(API_BASE_URL + '/business-units', formData);
      setLoading(false);

      history.push('/facility/business-units');
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const addIdentifier = () => {
    setFormInputs({
      ...formInputs,
      identifiers: [...formInputs.identifiers, ''],
    });
  };

  const onIdentifierChange = (e, index) => {
    const newIdentifiers = formInputs.identifiers.map((identifier, i) =>
      i === index ? e.target.value : identifier
    );
    setFormInputs({
      ...formInputs,
      identifiers: newIdentifiers,
    });
  };

  const removeIdentifier = (index) => {
    const newIdentifiers = formInputs.identifiers.filter(
      (identifier, i) => i !== index
    );
    setFormInputs({
      ...formInputs,
      identifiers: newIdentifiers,
    });
  };

  const removeLogo = () => {
    setFormInputs({
      ...formInputs,
      logo: '',
      logoPreview: '',
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color='primary'>
                <h4>{id ? 'Edit' : 'Create'} Business Unit</h4>
              </CardHeader>
              <CardBody>
                {loading && (
                  <div className='loaders'>
                    <img src={loader} />
                  </div>
                )}

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      type='text'
                      labelText='Name'
                      onChange={handleInputChange}
                      value={formInputs.name}
                      id='name'
                      name='name'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Email'
                      id='email'
                      name='email'
                      onChange={handleInputChange}
                      value={formInputs.email}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Phone'
                      id='phone'
                      name='phone'
                      onChange={handleInputChange}
                      value={formInputs.phone}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Address'
                      id='autocomplete'
                      name='address'
                      onChange={handleInputChange}
                      value={formInputs.address}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='City'
                      id='city'
                      name='city'
                      onChange={handleInputChange}
                      value={formInputs.city}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='State'
                      id='state'
                      name='state'
                      onChange={handleInputChange}
                      value={formInputs.state}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Zipcode'
                      id='zipcode'
                      name='zipcode'
                      onChange={handleInputChange}
                      value={formInputs.zipcode}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}></GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <GridContainer>
                      {formInputs.identifiers.map((identifier, index) => (
                        <GridItem xs={12} sm={12} md={12} key={index}>
                          <CustomInput
                            onChange={(e) => onIdentifierChange(e, index)}
                            labelText={`Business Unit Identifier ${index + 1}`}
                            formControlProps={{}}
                            value={identifier}
                          />
                          <Delete
                            onClick={() => removeIdentifier(index)}
                            style={{ cursor: 'pointer' }}
                          />
                        </GridItem>
                      ))}
                    </GridContainer>
                    <div xs={12} sm={12} md={6} style={{ padding: 0 }}>
                      <Button color='primary' onClick={addIdentifier}>
                        Add Business Unit Identifier
                      </Button>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className='custom-upload m-b-30 m-t-27'>
                      <label htmlFor='logo'>Upload Business Unit Logo</label>
                      <br />
                      <label htmlFor='logo' className='custom-upload-btn'>
                        <span>Browse</span>
                        <CustomInput
                          label='Upload Facility Logo'
                          id='logo'
                          onChange={handleLogoUpload}
                          type='file'
                          accept='image/*'
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </label>
                    </div>
                    {formInputs.logoPreview ? (
                      <div className='preview-image' width='130'>
                        <img src={formInputs.logoPreview} width='130' />
                      </div>
                    ) : (
                      ''
                    )}

                    {formInputs.logoPreview ? (
                      <span
                        className='material-icons'
                        style={{ cursor: 'pointer' }}
                        onClick={() => removeLogo()}>
                        delete
                      </span>
                    ) : (
                      ''
                    )}
                  </GridItem>
                </GridContainer>
                {message && (
                  <GridContainer>
                    <GridItem xs={8} sm={8} md={6}>
                      <p style={{ color: 'red', padding: '10px 0' }}>
                        {message}
                      </p>
                    </GridItem>
                  </GridContainer>
                )}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Button color='primary' type='submit'>
                      {id ? 'Save' : 'Create'}
                    </Button>
                    <Link to='/facility/business-units'>
                      <Button
                        color='primary'
                        className='btn-cancel'
                        style={{ marginLeft: '5px' }}>
                        Cancel
                      </Button>
                    </Link>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}></GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}
