import React, { Component } from 'react';

import ReactDOM from 'react-dom';
import { Link, Redirect } from 'react-router-dom';
import { API_BASE_URL, API_BASE_IMG_URL } from '../../config';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import axios from 'axios';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardAvatar from 'components/Card/CardAvatar.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import avatar from 'assets/img/faces/marc.jpg';
import logoimg from 'assets/img/imgpsh_fullsize_anim_front.png';

const md5 = require('md5');
const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

//const useStyles = makeStyles(styles);

//const classes = useStyles();

export default class Resetpassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      template_name: '',
      heading: '',
      subheading: '',
      contact_number: '',
      message: '',
      selectedTemplateimage: null,
      template_image: '',
      logo_image: '',
      selectedLogoimage: null,
      background_image: '',
      selectedBackgroundImage: null,
      redirect: false,
      dellogo: false,
      newpassword: '',
      confirmpassword: '',
      otp: '',
      isLoggedIn: false,
      user: {},
    };
    this.handleNewpassword = this.handleNewpassword.bind(this);
    this.handleConfirmpassword = this.handleConfirmpassword.bind(this);

    this.handleMessage = this.handleMessage.bind(this);
  }

  handleNewpassword(event) {
    this.setState({ newpassword: event.target.value });
  }
  handleOTP = (event) => {
    this.setState({ otp: event.target.value });
  };
  handleConfirmpassword(event) {
    this.setState({ confirmpassword: event.target.value });
  }

  resetpassword = (e) => {
    e.preventDefault();
    let slug =
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id;

    var newPassword = this.state.newpassword;
    var minNumberofChars = 8;
    var maxNumberofChars = 25;
    var regularExpression = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    /*const requestOptions = {
            body: JSON.stringify({ email: this.state.email, password: md5(this.state.password) })
        };*/
    if (this.state.newpassword == '') {
      alert('Please enter new password');
    } else if (
      newPassword.length < minNumberofChars ||
      newPassword.length > maxNumberofChars
    ) {
      alert('Please enter minimum 8 character and maximum 25 character');
    } else if (!regularExpression.test(newPassword)) {
      alert(
        'Password should contain atleast one upper,one lower,one number and one special character'
      );
    } else if (this.state.confirmpassword == '') {
      alert('Please enter confirm password');
    } else if (this.state.confirmpassword != this.state.newpassword) {
      alert('Password does not match');
    } else {
      const formData = new FormData();

      formData.append('password', this.state.newpassword);
      formData.append('email', localStorage.getItem('forgotEmail'));
      formData.append('otp', this.state.otp);

      axios
        .post(API_BASE_URL + '/resetpassword', formData)
        .then((response) => {
          return response;
        })
        .then((res) => {
          // this.setState({ message: 'Password has been changed successfully.' });
          console.log(res.data.redirect);
          window.location.href = `/user/${
            res.data.redirect === 'admin' ? 'login' : 'Facilitylogin'
          }`;
        })
        .catch((err) => {
          console.log('dfdf', err);
          this.setState({ message: err.response.data.msg });
        });
    }
  };

  /*** functions to set input values in state***/

  handleTempName(event) {
    this.setState({ template_name: event.target.value });
  }
  handleHeading(event) {
    this.setState({ heading: event.target.value });
  }
  handleSubheading(event) {
    this.setState({ subheading: event.target.value });
  }
  handleContactNum(event) {
    this.setState({ contact_number: event.target.value });
  }
  handleMessage(event) {
    this.setState({ message: event.target.value });
  }

  onTempFileChange = (event) => {
    this.setState({ selectedTemplateimage: event.target.files[0] });
  };

  onLogoFileChange = (event) => {
    this.setState({ selectedLogoimage: event.target.files[0] });
  };

  onBackgroundFileChange = (event) => {
    this.setState({ selectedBackgroundImage: event.target.files[0] });
  };

  render() {
    // console.log("redirectredirect", this.state.user);
    // if (this.state.redirect) {
    //     window.location.href = "/admin/dashboard";

    // }

    return (
      <form onSubmit={this.resetpassword}>
        <GridContainer alignItems="center" justify="center">
          <GridItem xs={8} sm={8} md={8}>
            <Card>
              <CardHeader color="primary">
                <GridContainer alignItems="center" justify="space-between">
                  <GridItem xs={8} sm={8} md={6}>
                    <h4>Reset Password</h4>
                  </GridItem>
                  <GridItem xs={8} sm={8} md={6}>
                    <img src={logoimg} width="130" />
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                {this.state.message && (
                  <GridContainer>
                    <GridItem xs={8} sm={8} md={6}>
                      <p style={{ color: 'red' }}>{this.state.message}</p>
                    </GridItem>
                  </GridContainer>
                )}
                <GridContainer>
                  <GridItem xs={8} sm={8} md={6}>
                    <CustomInput
                      labelText="New Password"
                      id="heading"
                      name="heading"
                      type="password"
                      onChange={this.handleNewpassword}
                      value={this.state.newpassword}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={8} sm={8} md={6}>
                    <CustomInput
                      labelText="Confirm Password"
                      id="subheading"
                      type="password"
                      name="subheading"
                      onChange={this.handleConfirmpassword}
                      value={this.state.confirmpassword}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>

                  <GridItem xs={8} sm={8} md={6}>
                    <CustomInput
                      labelText="OTP"
                      id="OTP"
                      name="OTP"
                      type="OTP"
                      onChange={this.handleOTP}
                      value={this.state.otp}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer alignItems="center">
                  <GridItem xs={8} sm={8} md={6}>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    );
  }
}
