import { Box, Modal } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Button from 'components/CustomButtons/Button';
import { useMemo } from 'react';
import { modalStyle } from 'views/Clients/Claim';

export default function FileProcessingErrorModal({ errors, close }) {
  const processedErrors = useMemo(() => {
    if (!errors) return null;
    const uniqueErrorTypes = [...new Set(errors.map((error) => error.type))];

    return uniqueErrorTypes?.map((errorType) => {
      return {
        type: errorType,
        errors: errors.filter((error) => error.type === errorType),
      };
    });
  });

  return (
    <Modal open={!!errors} onClose={close}>
      <Box sx={modalStyle}>
        <CardHeader style={{ transform: 'translateY(-50%)' }} color='primary'>
          <h4>Errors While Processing File </h4>
        </CardHeader>
        <Box overflow='auto' maxHeight='80vh'>
          <CardBody style={{ marginTop: '-20px' }}>
            <div>
              {processedErrors?.map((processedError, index) => (
                <Accordion
                  key={processedError.type}
                  defaultExpanded={index === 0}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel2-content'
                    id='panel2-header'>
                    <Typography>{processedError.type}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {processedError.errors.map((error, index) => (
                      <Box
                        mb={1}
                        p={0.7}
                        borderRadius={1}
                        bgcolor={index % 2 === 0 ? '#dae3dc' : '#ededed'}>
                        <Typography fontSize={16}>
                          Invalid {processedError.type} In Row: {error.row}
                        </Typography>
                        <Typography mt={0.2} ml={1} fontSize={12}>
                          {/* {error.relevantInfo.length && 'Value In File : '} */}
                          {error.relevantInfo.map((relevantInfo, index) => (
                            <span>
                              {relevantInfo.dbColumnName} (
                              {relevantInfo.mappedToColumn || '<N/a>'}):{' '}
                              {relevantInfo.value || '<N/A>'}
                              {index < error.relevantInfo.length - 1 && ' | '}
                            </span>
                          ))}
                        </Typography>
                      </Box>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </CardBody>
        </Box>
        <Box m={2} display='flex' justifyContent='flex-end'>
          <Button color='primary' onClick={close}>
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
