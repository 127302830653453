import React, { useState } from 'react';

const ImageLoader = ({ src, alt }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div>
      {isLoading && <p>Loading...</p>}
      <img
        src={src}
        alt={alt}
        style={{ display: isLoading ? 'none' : 'block' }}
        onLoad={handleImageLoad}
      />
    </div>
  );
};

export default ImageLoader;
