import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import loader from 'assets/img/loader.svg';
import uploadIcon from 'assets/img/upload.svg';
import { useEffect, useState } from 'react';
import { getFacilities } from 'views/Facilities/Facilities';
import axios from 'axios';
import { API_BASE_URL } from 'config';
import Moment from 'react-moment';
import { utcToLocal } from 'utility';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

export default function ReconProcessing() {
  const [loaded, setLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadFileText, setUploadFileText] = useState('Choose File');
  const [uploadStatus, setUploadStatus] = useState(false);
  const [file, setFile] = useState(null);
  const [facilities, setFacilities] = useState([]);
  const [facilityId, setFacilityId] = useState('');
  const [reconFiles, setReconFiles] = useState([]);

  const handleUploadFile = (e) => {
    setFile(e.target.files[0]);
    setUploadFileText(e.target.files[0].name);
    setUploadStatus(true);
  };

  const getReconFiles = async () => {
    const reconFiles = (await axios.get(API_BASE_URL + '/getReconFiles')).data;
    setReconFiles(reconFiles);
  };

  useEffect(async () => {
    const facilities = (await getFacilities({ facility: true })).data;
    console.log(facilities);
    setFacilities(facilities);
    getReconFiles();
  }, []);

  const onFacilitySelect = (e) => {
    setFacilityId(e.target.value);
  };

  const processReconFile = async () => {
    try {
      const selectedFacility = facilities.find(
        (item) => item.id === +facilityId,
      );
      console.log(selectedFacility);

      if (!facilityId) {
        setErrorMessage('Please select a facility');
        return;
      }

      const confirm = window.confirm(
        `Are you sure you want to process the file for ${selectedFacility.name} and bank ${selectedFacility.bank_name}?`,
      );

      if (!confirm) {
        return;
      }

      const formData = new FormData();
      formData.append('file', file);
      formData.append('facility_id', facilityId);
      await axios.post(API_BASE_URL + '/uploadAndProcessReconFile', formData);

      setUploadStatus(false);
      setUploadFileText('Choose File');
      setErrorMessage('');
      setFacilityId('');
      getReconFiles();
    } catch (e) {
      console.log(e);

      setErrorMessage(e.response.data.message);
    }
  };

  return (
    <div>
      <h2 className='subHeading'>Process Recon File</h2>
      <Card className='dashboardCard table_card_height'>
        <CardBody>
          <div>
            {loaded && (
              <div className='loaders'>
                <img src={loader} />
              </div>
            )}

            <div className='t-header uploadCard'>
              <div className='t-header-left' style={{ minWidth: '20vw' }}>
                <input
                  type='file'
                  onChange={handleUploadFile}
                  value=''
                  id='logoimg'
                />
                <img src={uploadIcon} />
                <p style={{ fontSize: '12px' }}>{uploadFileText}</p>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '-20px',
            }}>
            <div className='custom-search-box'>
              <div className='custom-select'>
                <select
                  value={facilityId}
                  className='full-width-elem'
                  id='status'
                  onChange={onFacilitySelect}>
                  <option style={{ fontWeight: 'bold' }}>Facilities</option>
                  {facilities.map((item) => {
                    return (
                      <>
                        <option
                          value={item.id}
                          className='parent'
                          style={{ fontWeight: 'bold' }}
                          key={item.id}>
                          {item.name}
                        </option>
                      </>
                    );
                  })}
                </select>
              </div>
              <span className='search-icon'></span>
            </div>
          </div>
          {errorMessage && (
            <div className='uploadTxt'>
              <p style={{ color: 'red' }}>{errorMessage}</p>{' '}
            </div>
          )}
          <div className='uploadTxt'>
            {uploadStatus && (
              <button
                className='btn'
                onClick={processReconFile}
                style={{ marginTop: '15px' }}>
                Process
              </button>
            )}
          </div>
        </CardBody>
      </Card>
      <Card className='dashboardCard table_card_height'>
        <CardBody>
          <table width='100%' id='template' className='paymentTable'>
            <thead>
              <tr>
                <th>Uploaded Date</th>
                <th>File Name</th>
                <th>File Status</th>
                <th>Facility Name</th>
                <th>Uploaded By</th>
                <th>Total Amount</th>
                <th>Record Count</th>
                <th>Processed Date</th>
                <th width='5%'></th>
              </tr>
            </thead>
            {reconFiles.length > 0 ? (
              <tbody>
                {reconFiles.map((reconFile, index) => (
                  <tr className='txtalign'>
                    <td>{utcToLocal(reconFile.created_at, 'MM/DD/YYYY')}</td>
                    <td>{reconFile.filename}</td>
                    <td>
                      {reconFile.is_processed ? 'Processed' : 'Not Processed'}
                    </td>
                    <td>{reconFile.facility_name}</td>
                    <td>{reconFile.uploaded_by}</td>
                    <td>
                      {reconFile.processed_amount === null
                        ? 'N/A'
                        : reconFile.processed_amount}
                    </td>
                    <td>
                      {reconFile.refunds_processed === null
                        ? 'N/A'
                        : reconFile.refunds_processed}
                    </td>
                    <td>
                      {reconFile.processed_at ? (
                        <Moment format='MM-DD-YYYY HH:mm'>
                          {reconFile.processed_at}
                        </Moment>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td>
                      <a
                        style={{ cursor: 'pointer' }}
                        href={
                          API_BASE_URL +
                          '/getFile?filePath=' +
                          reconFile.file_location
                        }>
                        <div>
                          <CloudDownloadIcon />
                        </div>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td>No File Uploaded</td>
                </tr>
              </tbody>
            )}
          </table>
        </CardBody>
      </Card>
    </div>
  );
}
