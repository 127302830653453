import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import axios from 'axios';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import { API_BASE_URL } from 'config';
import React, { useEffect, useState } from 'react';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import Moment from 'react-moment';
import { DeleteRounded } from '@material-ui/icons';

const ACHFiles = () => {
  const [files, setFiles] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [facility, setFacility] = useState('');
  const [paymentType, setPaymentType] = useState('ACH');

  const handlePaymentChange = (e) => {
    setPaymentType(e.target.value);
  };
  const selectFacility = (event) => {
    setFacility(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const deleteFile = async (id) => {
    if (confirm('Are you sure you want to delete that file?') == true) {
      await axios.delete(API_BASE_URL + '/nachfile/' + id);
      getFiles();
    }
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, files.length - page * rowsPerPage);
  const getFiles = async () => {
    const filesList = await axios.post(API_BASE_URL + '/adminACHFilesList', {
      facilityId: facility,
      paymentType: paymentType,
    });
    setFiles(filesList.data);
  };
  useEffect(() => {
    getFiles();
  }, [facility, paymentType]);
  useEffect(async () => {
    const response = await axios.get(API_BASE_URL + '/facilitiesPro');

    setFacilities(response.data);
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color='primary'>
            <h4>Download ACH / Positive Pay files</h4>
          </CardHeader>

          <CardBody>
            <div>
              <div className='t-header'>
                <div className='t-header-right'>
                  <div className='custom-search-box'>
                    <div className='custom-select'>
                      <select
                        className='full-width-elem'
                        id='Facility'
                        onChange={selectFacility}>
                        <option value='' style={{ fontWeight: 'bold' }}>
                          Facilities
                        </option>
                        {facilities.map((item) => {
                          return (
                            <>
                              <option
                                value={item.facilityID}
                                className='parent'
                                style={{ fontWeight: 'bold' }}
                                key={item.facilityID}>
                                {item.facilityName}
                              </option>
                              {item.subFacilityList.length > 0 &&
                                item.subFacilityList.map((itemInner, index) => {
                                  return (
                                    <option
                                      value={itemInner.id}
                                      className='child'
                                      style={{ textAlign: 'center' }}
                                      key={itemInner.id}>
                                      --{itemInner.name}
                                    </option>
                                  );
                                })}
                            </>
                          );
                        })}
                      </select>
                    </div>
                    <span className='search-icon'></span>
                  </div>
                  <div
                    style={{ margin: '0 10px', width: '200px' }}
                    className='custom-search-box'>
                    <div className='custom-select'>
                      <select
                        className='full-width-elem'
                        id='Facility'
                        onChange={handlePaymentChange}>
                        <option value='ACH' style={{ fontWeight: 'bold' }}>
                          ACH Payments
                        </option>
                        <option
                          value='POSITIVE_PAY'
                          style={{ fontWeight: 'bold' }}>
                          Positive Pay
                        </option>
                      </select>
                    </div>
                    <span className='search-icon'></span>
                  </div>
                </div>
              </div>
              <Table width='100%' id='template'>
                <TableHead>
                  <TableRow>
                    <TableCell width='15%'>Created At</TableCell>
                    <TableCell width='10%'>Facility name</TableCell>
                    <TableCell width='10%'>Generated By</TableCell>
                    <TableCell width='5%'>No.refunds</TableCell>
                    <TableCell width='10%'>Reference</TableCell>
                    <TableCell width='5%'>Total Amount</TableCell>
                    <TableCell width='15%'>File Name</TableCell>
                    <TableCell width='5%'></TableCell>
                  </TableRow>
                </TableHead>
                {files && files.length > 0 ? (
                  <TableBody>
                    {(rowsPerPage > 0
                      ? files.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : files
                    ).map((obj, index) => (
                      <TableRow className='txtalign' key={index}>
                        <TableCell>
                          {obj.created_at ? (
                            <Moment format='MM-DD-YYYY:hh:mm A'>
                              {obj.created_at}
                            </Moment>
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell>{obj && obj.name}</TableCell>
                        <TableCell>{obj && obj.created_by}</TableCell>
                        <TableCell>{obj.no_of_refunds}</TableCell>
                        <TableCell>{obj.reference_code ?? ''}</TableCell>
                        <TableCell>
                          $ {parseFloat(obj.total_amount ?? 0).toFixed(2)}
                        </TableCell>
                        <TableCell>{obj.file_name}</TableCell>
                        <TableCell>
                          <a
                            href={
                              API_BASE_URL + '/getFile?filePath=' + obj.file_url
                            }>
                            <div>
                              <CloudDownloadIcon />
                            </div>
                          </a>
                          <a onClick={() => deleteFile(obj.id)}>
                            <div>
                              <DeleteRounded />
                            </div>
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell>No Transaction Data Found</TableCell>
                    </TableRow>
                  </TableBody>
                )}
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: 'All', value: -1 },
                      ]}
                      colSpan={3}
                      count={files.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ACHFiles;
