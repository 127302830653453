import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CustomInput from 'components/CustomInput/CustomInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import loader from '../../assets/img/loader.svg';
import Button from 'components/CustomButtons/Button';
import { useState } from 'react';
import axios from 'axios';
import CardBody from 'components/Card/CardBody';
import { API_BASE_URL } from 'config';
import { useHistory } from 'react-router-dom';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

export default function CreateMoovAccount() {
  const [loaded, setLoaded] = useState(false);
  const [formInputs, setFormInputs] = useState({
    legalBusinessName: '',
    businessType: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    stateOrProvince: '',
    postalCode: '',
    email: '',
    phone: '',
    dateOfBirth: '',
    last4OfSSN: '',
    jobTitle: '',
    isOwner: false,
    ownershipPercentage: '',
    firstName: '',
    lastName: '',
    middleName: '',
    website: '',
    description: '',
    ein: '',
  });

  const [message, setMessage] = useState('');
  const onInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormInputs((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const history = useHistory();

  const CreateMoovAccount = async (e) => {
    e.preventDefault();
    setLoaded(true);
    console.log(formInputs);
    const id = (
      await axios.post(API_BASE_URL + '/createMoovAccount', formInputs)
    ).data.id;
    setLoaded(false);
    history.push('/admin/MoovPage');
  };

  const cancelfrm = () => {
    history.push('/admin/MoovPage');
  };

  // Required information	Owner	Controller
  // Legal name	yes	yes
  // Personal address	yes	yes
  // Email or phone #	yes	yes
  // Date of birth	yes	yes
  // Last 4 of SSN	yes	yes
  // isController	not required	yes
  // Job title	not required	yes
  // isOwner	yes	not required
  // Ownership percentage	yes	not required

  // add a checkbox for isController

  return (
    <div>
      <form onSubmit={CreateMoovAccount}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color='primary'>
                <h4>Moov Account KYC</h4>
              </CardHeader>

              <CardBody>
                {loaded && (
                  <div className='loaders'>
                    <img src={loader} />
                  </div>
                )}

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      type='text'
                      labelText='Legal name'
                      onChange={onInputChange}
                      value={formInputs.legalBusinessName}
                      name='legalBusinessName'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      type='text'
                      labelText='EIN'
                      onChange={onInputChange}
                      value={formInputs.ein}
                      name='ein'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {/* <CustomInput
                      type='text'
                      labelText='Business type'
                      onChange={onInputChange}
                      value={formInputs.businessType}
                      name='businessType'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    /> */}
                    {/* soleProprietorship, unincorporatedAssociation, trust, publicCorporation, privateCorporation, privateCorporation, llc, partnership, unincorporatedNonProfit, incorporatedNonProfit */}
                    <div className='custom-select'>
                      <FormControl>
                        <InputLabel id='demo-simple-select-tango'>
                          Account Type
                        </InputLabel>
                        <Select
                          labelId='demo-simple-select-tango'
                          id='demo-simple-select'
                          value={formInputs.businessType}
                          onChange={onInputChange}
                          name='businessType'
                          className='full-width-elem'>
                          <MenuItem value='soleProprietorship'>
                            soleProprietorship
                          </MenuItem>
                          <MenuItem value='unincorporatedAssociation'>
                            unincorporatedAssociation
                          </MenuItem>
                          <MenuItem value='trust'>trust</MenuItem>
                          <MenuItem value='publicCorporation'>
                            publicCorporation
                          </MenuItem>
                          <MenuItem value='privateCorporation'>
                            privateCorporation
                          </MenuItem>
                          <MenuItem value='llc'>llc</MenuItem>
                          <MenuItem value='partnership'>partnership</MenuItem>
                          <MenuItem value='unincorporatedNonProfit'>
                            unincorporatedNonProfit
                          </MenuItem>
                          <MenuItem value='incorporatedNonProfit'>
                            incorporatedNonProfit
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </GridItem>
                  {/* <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      type='text'
                      labelText='First name'
                      onChange={onInputChange}
                      value={formInputs.firstName}
                      name='firstName'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem> */}
                  {/* <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      type='text'
                      labelText='Middle name'
                      onChange={onInputChange}
                      value={formInputs.middleName}
                      name='middleName'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      type='text'
                      labelText='Last name'
                      onChange={onInputChange}
                      value={formInputs.lastName}
                      name='lastName'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem> */}
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='addressLine1'
                      name='addressLine1'
                      onChange={onInputChange}
                      value={formInputs.addressLine1}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='addressLine2'
                      name='addressLine2'
                      onChange={onInputChange}
                      value={formInputs.addressLine2}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='city'
                      name='city'
                      onChange={onInputChange}
                      value={formInputs.city}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='stateOrProvince'
                      name='stateOrProvince'
                      onChange={onInputChange}
                      value={formInputs.stateOrProvince}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='postalCode'
                      name='postalCode'
                      onChange={onInputChange}
                      value={formInputs.postalCode}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      type='text'
                      labelText='Email'
                      onChange={onInputChange}
                      value={formInputs.emailOrPhone}
                      name='email'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      type='text'
                      labelText='Phone #'
                      onChange={onInputChange}
                      value={formInputs.emailOrPhone}
                      name='phone'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  {/* <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Date of birth - MM/DD/YYYY'
                      name='dateOfBirth'
                      onChange={onInputChange}
                      value={formInputs.dateOfBirth}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem> */}

                  {/* <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Last 4 of SSN'
                      onChange={onInputChange}
                      value={formInputs.last4OfSSN}
                      name='last4OfSSN'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem> */}
                  {/* <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Job title'
                      onChange={onInputChange}
                      value={formInputs.jobTitle}
                      name='jobTitle'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer> */}
                  {/* 
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      type='number'
                      labelText='Ownership percentage'
                      onChange={onInputChange}
                      value={formInputs.ownershipPercentage}
                      name='ownershipPercentage'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem> */}
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      type='text'
                      labelText='Website'
                      onChange={onInputChange}
                      value={formInputs.website}
                      name='website'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      type='text'
                      labelText='Description'
                      onChange={onInputChange}
                      value={formInputs.description}
                      name='description'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                {/* <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <div
                      style={{
                        display: 'flex',

                        gap: '5px',
                      }}>
                      <input
                        type='checkbox'
                        name='isOwner'
                        labelText=''
                        onChange={onInputChange}
                        value={formInputs.isOwner}
                      />
                      <label>Is Owner</label>
                    </div>
                  </GridItem>
                </GridContainer> */}

                {message && (
                  <GridContainer>
                    <GridItem xs={8} sm={8} md={6}>
                      <p style={{ color: 'red', padding: '10px 0' }}>
                        {message}
                      </p>
                    </GridItem>
                  </GridContainer>
                )}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Button color='primary' type='submit'>
                      Create
                    </Button>
                    <Button
                      color='danger'
                      onClick={cancelfrm}
                      className='btn-cancel'
                      style={{ marginLeft: '5px' }}>
                      Cancel
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}></GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}
