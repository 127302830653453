import { useState } from 'react';
import needHelp from '../../assets/img/needHelp.png';
import './NeedHelp.scss';
export default function NeedHelp() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className='container-need-help'>
      <div className='wrapper-need-help' onClick={() => setIsOpen(!isOpen)}>
        <div className='need-help-img'>
          {isOpen ? (
            <svg
              fill='#ffffff'
              width='48'
              height='48'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'>
              <path d='M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z'></path>
            </svg>
          ) : (
            <img src={needHelp} alt='Need Help' />
          )}
        </div>
        <div
          className={
            isOpen ? 'need-help-box need-help-box-active' : 'need-help-box'
          }>
          <h2>Need Help?</h2>
          <p>
            Please email us at{' '}
            <a href='mailto:hello@clientpaydirect.com'>
              hello@clientpaydirect.com
            </a>
            . Please note our offices are open from 9am EST to 6pm EST Monday
            through Friday.
          </p>
        </div>
      </div>
    </div>
  );
}
