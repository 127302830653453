import { useEffect, useState } from 'react';

const { Bar } = require('react-chartjs-2');

const BarChart = ({ showCount, data }) => {
  // data = [
  //   amount
  //   :
  //   "0.00"
  //   count
  //   :
  //   0
  //   method
  //   :
  //   "Digital"]

  const [labels, setLabels] = useState([]);
  const [amount, setAmount] = useState([]);
  const [count, setCount] = useState([]);

  useEffect(() => {
    data.map((item) => {
      setLabels(data.map((item) => item.method));
      setAmount(data.map((item) => item.amount));
      setCount(data.map((item) => item.count));
    });
  }, [data]);

  return (
    <Bar
      options={{
        plugins: {
          tooltip: {
            callbacks: {
              label: function (tooltipItem, data) {
                console.log();
                // let value = data.datasets[0].data[tooltipItem.index];
                return showCount
                  ? tooltipItem.formattedValue
                  : '$' + (tooltipItem.formattedValue ?? '0.00');
              },
            },
          },

          legend: {
            display: false,
            labels: {
              boxWidth: 5,
            },

            position: 'top',
            textDirection: 'rtl',
          },
        },

        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              precision: 0,
              callback: function (value, index, values) {
                return showCount ? value : '$' + value;
              },
            },
            grid: {
              color: 'rgba(0, 0, 0, 0)',
            },
          },
          x: {
            grid: {
              color: 'rgba(0, 0, 0, 0)',
            },
          },
        },
      }}
      data={{
        labels,
        datasets: [
          {
            data: showCount ? count : amount,
            backgroundColor: ['#0766AD', '#FF6C22', '#FFCE56', '#495E57'],
            borderWidth: 0,
            borderRadius: 5,
          },
        ],
      }}
    />
  );
};

export default BarChart;
