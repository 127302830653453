import { withStyles } from '@material-ui/styles';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import { PUBLIC_ASSETS_BASE_URL } from '../../config';
import React, { useEffect, useState } from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import axios from 'axios';
import { API_BASE_URL } from 'config';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Dialog,
} from '@mui/material';
import BusinessUnitLogoPlaceholder from 'assets/img/BusinessUnitLogoPlaceholder.png';

import { fileToDataUri } from 'views/Facilities/CreateFacility';
import Card from 'components/Card/Card';
export const useStyles = makeStyles({
  root: {
    // input label when focused
    '& label.Mui-focused': {
      color: '#3AB795',
    },

    // focused color for input with variant='standard'
    '& .MuiInput-underline:after': {
      borderBottomColor: '#3AB795',
    },
    // focused color for input with variant='filled'
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: '#3AB795',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3AB795',
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3AB795',
    },
    // focused color for input with variant='outlined'
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#3AB795',
      },
    },
  },
});
let facilityLogo;
const TemplateModal = ({
  templateModal,
  closeModal,
  defaultValues,
  facilityId = null,
}) => {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    width: '50vw',
    maxHeight: '93vh',
    overflowY: 'auto',
    position: 'fixed',
    paddingTop: '20px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-47%)',
    borderRadius: '5px',
    background: '#fff',

    // '& .MuiTextField-root': { m: 1, width: '25ch' },
  };

  return (
    <Modal
      open={templateModal}
      onClose={closeModal}
      aria-labelledby='parent-modal-title'
      aria-describedby='parent-modal-description'>
      <Card style={{ marginTop: '-10px' }}>
        <Box
          sx={style}
          className='makeStyles-card-96'
          noValidate
          autoComplete='off'
          st>
          <CardHeader
            style={{ position: 'absolute', width: '96%' }}
            color='primary'>
            <h4>Status History</h4>
          </CardHeader>
          <LetterTemplateForm
            facilityId={facilityId}
            defaultValues={defaultValues}
            onSave={closeModal}
          />
        </Box>
      </Card>
    </Modal>
  );
};

export default TemplateModal;

export const LetterTemplateForm = ({
  defaultValues,
  isFacility = false,
  facilityId = null,
  onSave = () => {},
}) => {
  const [formInputs, setFormInputs] = useState({ ...defaultValues });
  const [pdfFile, setPdfFile] = useState(null);
  const inputFieldOnChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    setFormInputs({ ...formInputs, [fieldName]: fieldValue });
  };
  const [isPreview, setIsPreview] = useState(false);
  const classes = useStyles();

  const labelStyle = {
    fontSize: '12px',
    color: 'grey',
  };
  const previewTemplate = () => {
    const formData = new FormData();

    formData.append('name', formInputs.name);
    formData.append('date', formInputs.date);
    formData.append('line1', formInputs.line1);
    formData.append('line2', formInputs.line2);
    formData.append('line3', formInputs.line3);
    formData.append('disclaimer', formInputs.disclaimer);
    formData.append('signature', formInputs.signature);
    formData.append('logo', formInputs.logo || (formInputs.logoPreview ?? ''));
    formData.append('include_cpd_logo', formInputs.include_cpd_logo ?? false);
    formData.append('use_business_unit_logo', false);
    axios
      .post(
        API_BASE_URL +
          (isFacility ? '/previewTemplateFacility' : '/previewTemplate'),
        formData,
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: 'application/pdf',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        console.log(res);
        setPdfFile(res.data);
        setIsPreview(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', formInputs.name);
    formData.append('date', formInputs.date);
    formData.append('line1', formInputs.line1);
    formData.append('line2', formInputs.line2);
    formData.append('line3', formInputs.line3);
    formData.append('disclaimer', formInputs.disclaimer);
    formData.append('signature', formInputs.signature);
    if (formInputs.logo) formData.append('logo', formInputs.logo);
    formData.append('include_cpd_logo', formInputs.include_cpd_logo ?? false);
    formData.append(
      'use_business_unit_logo',
      formInputs.use_business_unit_logo ?? false
    );

    if (facilityId) {
      formData.append('facility_id', facilityId);
    }

    await axios.post(
      API_BASE_URL + (facilityId ? '/updateTemplate_admin' : '/updateTemplate'),
      formData
    );
    onSave(formInputs);
  };

  const onLogoChange = async (e) => {
    const base64Image = await fileToDataUri(e.target.files[0]);
    setFormInputs({
      ...formInputs,
      ['logo']: base64Image,
      logoPreview: base64Image,
      use_business_unit_logo: false,
    });
  };

  const clearImage = () => {
    setFormInputs({ ...formInputs, ['logo']: '', logoPreview: null });
  };

  const onCheckInputChange = (e) => {
    const { name } = e.target;
    if (name === 'cpdLogo') {
      return setFormInputs({
        ...formInputs,
        include_cpd_logo: !formInputs.include_cpd_logo,
      });
    }

    if (name === 'businessUnitLogo') {
      return setFormInputs({
        ...formInputs,
        use_business_unit_logo: !formInputs.use_business_unit_logo,
        logo: null,
        logoPreview: e.target.checked ? BusinessUnitLogoPlaceholder : null,
      });
    }

    setFormInputs({
      ...formInputs,
      ['logo']: name === 'facilityLogo' ? facilityLogo : '',
      logoPreview:
        name === 'facilityLogo' && PUBLIC_ASSETS_BASE_URL + facilityLogo,
      use_business_unit_logo: false,
    });
  };

  useEffect(() => {
    const AppState = JSON.parse(localStorage['appState'] ?? '{}');
    const logo = AppState?.user?.image_upload;

    facilityLogo = logo;
  }, []);
  return (
    <>
      <form onSubmit={onSubmit}>
        <CardBody
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '20px',
            height: '100%',
            overflowY: 'scroll',
            marginTop: '-20px',
          }}>
          <p style={{ fontSize: '14px' }}>
            “Please do not change anything within the curly brackets {'{{}}'}.
            These are specific fields that are populated automatically based on
            the facility and refund.”
          </p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}>
              <label style={labelStyle}>Refund name</label>
              <TextField
                className={classes.root}
                placeholder={defaultValues.name}
                autoCorrect='off'
                multiline
                inputProps={{
                  style: { fontSize: '14px', lineHeight: '1.3' },
                }}
                name='name'
                style={{ width: '80%' }}
                maxRows={2}
                //   value={formInputs.name}
                onChange={inputFieldOnChange}
                variant='outlined'
                value={formInputs.name}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'flex-end',
              }}>
              <label style={labelStyle}>Date</label>
              <TextField
                className={classes.root}
                name='date'
                placeholder={defaultValues.date}
                multiline
                inputProps={{
                  style: { fontSize: '14px', lineHeight: '1.3' },
                }}
                style={{ width: '80%' }}
                maxRows={2}
                variant='outlined'
                value={formInputs.date}
                onChange={inputFieldOnChange}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}>
            <label style={labelStyle}>First line - context for customer</label>
            <TextField
              variant='outlined'
              className={classes.root}
              name='line1'
              placeholder={defaultValues.line1}
              multiline
              inputProps={{
                style: { fontSize: '14px', lineHeight: '1.3' },
              }}
              style={{ width: '99%' }}
              maxRows={4}
              minRows={2}
              value={formInputs.line1}
              onChange={inputFieldOnChange}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}>
            <label style={labelStyle}>
              Second line - introducing CPD context
            </label>
            <TextField
              variant='outlined'
              className={classes.root}
              placeholder={defaultValues.line2}
              multiline
              inputProps={{
                style: { fontSize: '14px', lineHeight: '1.3' },
              }}
              style={{ width: '99%' }}
              minRows={2}
              maxRows={4}
              value={formInputs.line2}
              onChange={inputFieldOnChange}
              name='line2'
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}>
            <label style={labelStyle}>Third line - Getting your refund</label>
            <TextField
              variant='outlined'
              className={classes.root}
              placeholder={defaultValues.line3}
              multiline
              inputProps={{
                style: { fontSize: '14px', lineHeight: '1.3' },
              }}
              style={{ width: '99%' }}
              minRows={8}
              maxRows={9}
              value={formInputs.line3}
              onChange={inputFieldOnChange}
              name='line3'
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}>
            <label style={labelStyle}>Disclaimer - Terms and Conditions</label>
            <TextField
              variant='outlined'
              className={classes.root}
              placeholder={defaultValues.disclaimer}
              multiline
              inputProps={{
                style: { fontSize: '14px', lineHeight: '1.3' },
              }}
              style={{ width: '99%' }}
              minRows={2}
              maxRows={4}
              value={formInputs.disclaimer}
              onChange={inputFieldOnChange}
              name='disclaimer'
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}>
              <label style={labelStyle}>Signature</label>
              <TextField
                variant='outlined'
                className={classes.root}
                placeholder={defaultValues.signature}
                multiline
                inputProps={{
                  style: { fontSize: '14px', lineHeight: '1.3' },
                }}
                style={{ width: '30%' }}
                minRows={3}
                maxRows={5}
                value={formInputs.signature}
                onChange={inputFieldOnChange}
                name='signature'
              />
            </div>
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className='custom-upload m-b-30 m-t-27'>
                <label htmlFor='signature'>Upload Logo</label>
                <br />
                <label htmlFor='signature' className='custom-upload-btn'>
                  <span>Browse</span>
                  <CustomInput
                    id='signature'
                    name='signature'
                    onChange={onLogoChange}
                    type='file'
                    value={''}
                    accept='image/*'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </label>
              </div>
              {
                <div className='preview-image' width='130'>
                  {formInputs?.logoPreview && (
                    <img src={formInputs?.logoPreview} width='130' />
                  )}
                </div>
              }
              {formInputs?.logoPreview ? (
                <span
                  className='material-icons'
                  style={{ cursor: 'pointer' }}
                  onClick={clearImage}>
                  delete
                </span>
              ) : (
                ''
              )}
              <div style={{ display: 'flex', marginBottom: '20px' }}>
                {facilityId ? null : (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='facilityLogo'
                          onChange={onCheckInputChange}
                          checked={
                            formInputs?.logoPreview ===
                            PUBLIC_ASSETS_BASE_URL + facilityLogo
                          }
                        />
                      }
                      label='Use existing logo'
                    />
                  </FormGroup>
                )}
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='cpdLogo'
                        onChange={onCheckInputChange}
                        checked={formInputs?.include_cpd_logo ?? false}
                      />
                    }
                    label='Include CPD Logo'
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='businessUnitLogo'
                        onChange={onCheckInputChange}
                        checked={formInputs?.use_business_unit_logo ?? false}
                      />
                    }
                    label='Use Business Unit Logo'
                  />
                </FormGroup>
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Button
                color='primary'
                onClick={previewTemplate}
                style={{ marginRight: '30px' }}>
                Preview Pdf
              </Button>
              <Button color='primary' type='submit'>
                Save
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
        <Dialog
          open={isPreview}
          onClose={() => setIsPreview(false)}
          style={{ margin: 0 }}>
          {pdfFile && (
            <Document
              file={pdfFile}
              onLoadSuccess={() => console.log('success')}>
              <Page pageNumber={1} />
            </Document>
          )}
        </Dialog>
      </form>
    </>
  );
};
