import React, { Component, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import Moment from 'react-moment';
import fileDownload from 'js-file-download';

// react-CSV downloader
import { CSVLink } from 'react-csv';

import { API_BASE_URL, API_BASE_IMG_URL } from '../../config';

import GridItem from 'components/Grid/GridItem.js';

import GridContainer from 'components/Grid/GridContainer.js';

import Card from 'components/Card/Card.js';

import CardBody from 'components/Card/CardBody.js';
import Button from '../../components/CustomButtons/Button';

import loader from '../../assets/img/loader.svg';
import DatePicker from 'react-datepicker';
import { Dialog, Modal } from '@mui/material';
import { Box } from '@mui/system';

//const useStyles = makeStyles(styles);
const paymentTypes = [
  { id: 4, name: 'Amazon GiftCards' },
  { id: 5, name: 'Check' },
  { id: 6, name: 'Donation' },
  { id: 7, name: 'ACH' },
];
export default function AdminReport() {
  const [loaded, setLoaded] = useState(false);
  const [refundReport, setRefundReport] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [facilities, setFacilities] = useState([]);
  const [facility, setFacility] = useState('');
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [headers, setHeaders] = useState([]);
  const changeDate = (event) => {
    setStartDate(event ?? '');
  };

  /*** Change end date */
  const changeEndDate = (event) => {
    setEndDate(event ?? '');
  };

  const csvReport = {
    data: refundReport,
    headers,
    filename: 'Aggregate_Refund_Report.csv',
  };
  const getReport = async () => {
    try {
      setLoaded(true);
      const response = await axios.get(
        `${API_BASE_URL}/getreportforadmin?startDate=${startDate}&endDate=${endDate}&paymentType=${paymentType}&facilityId=${facility}`
      );
      setLoaded(false);
      setRefundReport(response.data.data ?? []);
      const selectedLocal = JSON.parse(
        localStorage.getItem('selectedColumns') ?? '[]'
      );

      const findIfSelected = (name) => {
        const found = selectedLocal.find((item) => {
          return item.key === name;
        });
        if (found) return found.selected;
        return true;
      };
      const allSelected = [];
      if (response.data.data.length) {
        for (const key of Object.keys(response.data.data[0])) {
          allSelected.push({ key: key, selected: findIfSelected(key) });
        }
      }
      console.log(allSelected);
      setSelected(allSelected);
    } catch (err) {
      console.log(err);
    }
  };
  const selectFacility = (event) => {
    setFacility(event.target.value);
  };
  useEffect(() => {
    setLoaded(true);
    axios
      .get(API_BASE_URL + '/facilitiesPro')
      .then((response) => {
        return response;
      })
      .then((facility) => {
        setFacilities(facility && facility.data);
      });
  }, []);
  const onOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    const headers = selected
      .filter((item) => {
        return item.selected;
      })
      .map((item) => {
        return { label: item.key, key: item.key };
      });
    setHeaders(headers);
    if (selected.length)
      localStorage.setItem('selectedColumns', JSON.stringify(selected));
  }, [selected]);
  useEffect(() => {
    getReport();
  }, [startDate, endDate, paymentType, facility]);
  // const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <h2 className='subHeading'>Download Transactions</h2>
        <Card className='dashboardCard'>
          <CardBody>
            <div>
              {loaded && (
                <div className='loaders'>
                  <img src={loader} />
                </div>
              )}
              <div className='t-header'>
                <div className='t-header-left'>
                  From:&nbsp;&nbsp;
                  <DatePicker
                    className='full-width-elem-date-picker'
                    id='dt'
                    autoComplete='off'
                    selected={startDate}
                    onChange={changeDate}
                  />
                </div>
                <div className='t-header-left'>
                  To:&nbsp;&nbsp;
                  <DatePicker
                    className='full-width-elem-date-picker'
                    id='dt'
                    autoComplete='off'
                    selected={endDate}
                    onChange={changeEndDate}
                  />
                </div>
                <div className='t-header-right'>
                  <div className='custom-search-box'>
                    <div className='custom-select'>
                      <select
                        className='full-width-elem'
                        id='status'
                        onChange={(e) => setPaymentType(e.target.value)}
                        value={paymentType}>
                        <option value=''>All</option>
                        {paymentTypes.map((type) => (
                          <option key={type.id} value={type.id + ''}>
                            {type.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <span className='search-icon'></span>
                  </div>
                </div>
                <div className='t-header-right'>
                  <div className='custom-search-box'>
                    <div className='custom-select'>
                      <select
                        className='full-width-elem'
                        id='Facility'
                        onChange={selectFacility}>
                        <option value='' style={{ fontWeight: 'bold' }}>
                          Facilities
                        </option>
                        {facilities.map((item) => {
                          return (
                            <>
                              <option
                                value={item.facilityID}
                                className='parent'
                                style={{ fontWeight: 'bold' }}
                                key={item.facilityID}>
                                {item.facilityName}
                              </option>
                              {item.subFacilityList.length > 0 &&
                                item.subFacilityList.map((itemInner, index) => {
                                  return (
                                    <option
                                      value={itemInner.id}
                                      className='child'
                                      style={{ textAlign: 'center' }}
                                      key={itemInner.id}>
                                      --{itemInner.name}
                                    </option>
                                  );
                                })}
                            </>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <span className='search-icon'></span>
                </div>
              </div>
              <div className='t-header'>
                <div style={{ width: 'max-content' }} className='t-header-left'>
                  {!loaded && (
                    <Button color='primary' onClick={onOpen}>
                      Select Columns
                    </Button>
                  )}
                </div>
                <div className='t-header-left' style={{ width: 'max-content' }}>
                  {!loaded && (
                    <Button color='primary'>
                      <CSVLink
                        {...csvReport}
                        style={{ cursor: 'pointer', color: '#fff' }}>
                        Download Report
                      </CSVLink>
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <Modal
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={open}
        onClose={() => setOpen(false)}>
        <Box padding={3} style={{ borderRadius: '7px', width: '60vw' }}>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}>
            {selected.map((key) => {
              return (
                <Button
                  key={key.key}
                  style={{ width: '200px' }}
                  color={key.selected ? 'primary' : ''}
                  onClick={() => {
                    const newSelected = [...selected];
                    newSelected[selected.indexOf(key)].selected = !key.selected;
                    setSelected(newSelected);
                  }}>
                  {' '}
                  {key.key}
                </Button>
              );
            })}
          </div>
        </Box>
      </Modal>
    </GridContainer>
  );
}
