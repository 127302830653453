import React, { Component } from 'react';

import ReactDOM from 'react-dom';
import { Link, Redirect } from 'react-router-dom';
import {
  API_BASE_URL,
  API_BASE_IMG_URL,
  defaultLetterTemplate,
  bankNames,
} from '../../config';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import axios from 'axios';
import $ from 'jquery';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardAvatar from 'components/Card/CardAvatar.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import loader from '../../assets/img/loader.svg';

import avatar from 'assets/img/faces/marc.jpg';
import { Box, Modal, TextField } from '@mui/material';
import { withStyles } from '@material-ui/styles';
import { display, width } from '@mui/system';
import TemplateModal from 'components/TemplateModal/TemplateModal';
import { FormControl, MenuItem, Select } from '@material-ui/core';
const md5 = require('md5');

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

//const useStyles = makeStyles(styles);

//const classes = useStyles();
export const fileToDataUri = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });
export default class CreateFacility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      message: '',
      email: '',
      address: '',
      phone: '',
      redirect: false,
      file: null,
      signatureImg: '',
      signature: null,
      city: '',
      state: '',
      zipcode: '',
      fileimg: '',
      loaded: false,
      aptno: '',
      bankAcc: '',
      bankName: '',
      allowAddressUpdate: false,
      achMethod: 'moov',
      tangoGroups: [],
      tangoAccounts: [],
      tango_group_id: '',
      tango_account_id: '',
      moovAccounts: [],
      moovPaymentMethods: [],
      moov_account_id: '',
      moov_payment_method_id: '',
      routingNo: '',
      bankAccAlias: '',
      achId: '',
      achCompanyName: '',
      templateModal: false,
      pdfTemplate: defaultLetterTemplate,
      organization_id: '',
      organizations: [],
    };
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.autocomplete = null;
    this.handleEmail = this.handleEmail.bind(this);

    this.handleAddress = this.handleAddress.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handleCity = this.handleCity.bind(this);
    this.handleState = this.handleState.bind(this);
    this.handleZipcode = this.handleZipcode.bind(this);
    this.handleAptNo = this.handleAptNo.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    //this.props.dispatch(addParlor(this.state))
    this.clearForm();
  }

  handleBankName = (event) => {
    this.setState({ bankName: event.target.value });
  };

  handleAchMethod = (event) => {
    this.setState({ achMethod: event.target.value });
  };

  handleAddressUpdate = (event) => {
    this.setState({ allowAddressUpdate: event.target.value });
  };

  handlePlaceSelect() {
    let addressObject = this.autocomplete.getPlace();
    console.log(addressObject);
    let address = addressObject.address_components;
    console.log('addressaddressaddress', address);
    let addressdata = addressObject && addressObject.adr_address;
    let zip_code;
    let city_data;
    let state_data;
    let streetNo;
    let route;
    this.setState({ city: '', state: '', zipcode: '' });

    for (let i = 0; i < address.length; i++) {
      //console.log("objectdataff",address[i])
      if (address[i] && address[i].types[0] == 'postal_code') {
        zip_code = address[i].long_name;
        this.setState({ zipcode: zip_code });
      }
      if (address[i] && address[i].types[0] == 'street_number') {
        streetNo = address[i].long_name;
      }
      if (address[i] && address[i].types[0] == 'route') {
        route = address[i].long_name;
      }
      if (
        (address[i] && address[i].types[0] == 'locality') ||
        (address[i] && address[i].types[0] == 'sublocality_level_1')
      ) {
        city_data = address[i].long_name;
        this.setState({ city: city_data });
      }
      if (address[i] && address[i].types[0] == 'administrative_area_level_1') {
        state_data = address[i].long_name;
        this.setState({ state: state_data });
      }
    }

    let requiredAddress;
    if (route && streetNo) {
      requiredAddress = streetNo + ' ' + route;
    } else if (route) {
      requiredAddress = route;
    } else if (streetNo) {
      requiredAddress = streetNo;
    }

    this.setState({ address: requiredAddress });
  }

  handleChange = (address) => {
    this.setState({ address });
  };
  /*** functions to set input values in state***/

  handleName(event) {
    this.setState({ name: event.target.value });
  }
  handleEmail(event) {
    this.setState({ email: event.target.value });
  }
  handleAddress(event) {
    this.setState({ address: event.target.value });
  }
  handlePhone(event) {
    this.setState({ phone: event.target.value });
  }

  handleCity(event) {
    this.setState({ city: event.target.value });
  }

  handleState(event) {
    this.setState({ state: event.target.value });
  }
  handleZipcode(event) {
    this.setState({ zipcode: event.target.value });
  }
  handleBankAcc = (event) => {
    this.setState({ bankAcc: event.target.value });
  };
  handleRoutingNo = (event) => {
    this.setState({ routingNo: event.target.value });
  };
  handleBankAccAlias = (event) => {
    const alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    if (alphaNumericRegex.test(event.target.value))
      return this.setState({ bankAccAlias: event.target.value });
  };
  handleUploadFile = (event) => {
    this.setState({
      file: event.target.files[0],
      fileimg: URL.createObjectURL(event.target.files[0]),
    });
  };
  handleAchId = (event) => {
    this.setState({ achId: event.target.value });
  };
  handleAchCompanyName = (event) => {
    this.setState({ achCompanyName: event.target.value });
  };

  handleSignatureFile = (event) => {
    this.setState({
      signature: event.target.files[0],
      signatureImg: URL.createObjectURL(event.target.files[0]),
    });
  };
  handleAptNo(event) {
    this.setState({ aptno: event.target.value });
  }
  remove() {
    this.setState({ fileimg: false });
  }
  removeSignature() {
    this.setState({ signatureImg: false, signature: null });
  }

  cancelfrm = () => {
    this.setState({ redirect: true });
  };
  /*** function to integrate api to create facility*/
  componentDidMount() {
    axios.get(API_BASE_URL + '/listTangoGroups').then((res) => {
      this.setState({ tangoGroups: res.data.groups });
    });

    axios.get(API_BASE_URL + '/getMoovAccounts').then((res) => {
      console.log('moovAccounts', res.data);
      this.setState({ moovAccounts: res.data });
    });

    axios.get(API_BASE_URL + '/getOrganizations').then((res) => {
      console.log('organization', res.data.organizations);
      this.setState({ organizations: res.data.organizations });
    });

    const google = window.google;
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      {}
    );

    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
    let state = localStorage['appState'];
    let AppState;
    if (state) {
      AppState = JSON.parse(state);
    }
    if (AppState && AppState.user && AppState.user.admin_type != 'admin') {
      window.location.href = '/client/error';
    }
  }
  onPdfTemplateSave = (data) => {
    this.setState({ pdfTemplate: data });
  };
  createFacility = async (e) => {
    e.preventDefault();
    console.log(this.state);

    if (this.state.name == '') {
      alert('Please enter Facility name');
      $('#name').focus();
      return false;
    } else if (this.state.email == '') {
      alert('Please enter Facility email');
      $('#email').focus();
      return false;
    } else if (this.state.phone == '') {
      alert('Please enter Facility phone');
      $('#phone').focus();
    } else if (this.state.city == '') {
      alert('Please enter city');
      $('#city').focus();
    } else if (this.state.state == '') {
      alert('Please enter state');
      $('#state').focus();
    } else if (this.state.zipcode == '') {
      alert('Please enter zipcode');
      $('#phone').focus();
    } else if (this.state.address == '') {
      alert('Please enter Facility address');
      $('#address').focus();
      return false;
    } else if (!Number(this.state.phone)) {
      alert('Phone should be in numbers only');
      $('#phone').focus();
      return false;
    } else if (!Number(this.state.phone)) {
      alert('Phone should be in numbers only');
      $('#phone').focus();
      return false;
    } else if (this.state.phone.length > 12) {
      alert('Phone number length should not be greater than 12');
      $('#phone').focus();
      return false;
    } else if (
      !this.state.moov_account_id ||
      !this.state.moov_payment_method_id
    ) {
      alert('Please select moov account and payment method');
      return false;
    } else {
      this.setState({ loaded: true });

      const currentAddress = this.state.address;
      const requiredAddress = currentAddress + ', ' + this.state.aptno;
      const formData = new FormData();
      formData.append('file', this.state.file);
      formData.append('name', this.state.name);
      formData.append('email', this.state.email);
      formData.append('address', requiredAddress);
      formData.append('phone', this.state.phone);
      formData.append('city', this.state.city);
      formData.append('state', this.state.state);
      formData.append('zipcode', this.state.zipcode);
      formData.append('bankAcc', this.state.bankAcc);
      formData.append('routingNo', this.state.routingNo);
      formData.append('bankAccAlias', this.state.bankAccAlias);
      formData.append('achCompanyName', this.state.achCompanyName);
      formData.append('achId', this.state.achId);
      formData.append('tango_group_id', this.state.tango_group_id);
      formData.append('tango_account_id', this.state.tango_account_id);
      formData.append('moov_account_id', this.state.moov_account_id);
      formData.append(
        'moov_payment_method_id',
        this.state.moov_payment_method_id
      );
      formData.append('organization_id', this.state.organization_id);
      formData.append('bank_name', this.state.bankName);
      formData.append('allow_address_update', this.state.allowAddressUpdate);
      formData.append('ach_method', this.state.achMethod);

      formData.append('pdfTemplate', JSON.stringify(this.state.pdfTemplate));

      if (this.state.signature) {
        console.log(await fileToDataUri(this.state.signature));
        formData.append(
          'signatureImg',
          await fileToDataUri(this.state.signature)
        );
      }

      let state = localStorage['appState'];
      let AppState;
      if (state) {
        AppState = JSON.parse(state);
      }

      let token = AppState && AppState.user && AppState.user.accessToken;

      const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      };
      axios
        .post(API_BASE_URL + '/create_facility', formData, { headers: headers })
        .then((response) => {
          return response;
        })
        .then((res) => {
          console.log('sss', res, res && res.data);
          if (res && res.data && !res.data.err) {
            this.setState({ redirect: true, loaded: false });
          } else {
            console.log(res.data);
            alert(res && res.data && res.data.msg);
            this.setState({ loaded: false, message: res.data.msg });
          }
        })
        .catch((err) => {
          this.setState({ loaded: false, message: err.response?.data?.msg });
          console.log(err);
        });
    }
  };

  openModal = () => {
    this.setState({
      templateModal: true,
    });
  };
  closeModal = () => {
    this.setState({
      templateModal: false,
    });
  };
  onMoonAccountChange = (e) => {
    this.setState({ moov_account_id: e.target.value });

    axios
      .get(API_BASE_URL + '/getMoovPaymentMethods/' + e.target.value)
      .then((res) => {
        this.setState({ moovPaymentMethods: res.data });
      });
  };

  onMoovPaymentMethodChange = (e) => {
    this.setState({ moov_payment_method_id: e.target.value });
  };

  onOrganizationIdChange = (e) => {
    this.setState({ organization_id: e.target.value });
  };

  onTangoGroupChange = (e) => {
    this.setState({ tango_group_id: e.target.value });
    axios
      .get(API_BASE_URL + '/listTangoAccounts/' + e.target.value)
      .then((res) => {
        this.setState({ tangoAccounts: res.data.accounts });
      });
  };

  onTangoAccountChange = (e) => {
    this.setState({ tango_account_id: e.target.value });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to='/admin/facilities' />;
    }
    console.log('uploadimg', this.state.uploadimg);
    return (
      <div>
        <form onSubmit={this.createFacility}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color='primary'>
                  <h4>Create Facility</h4>
                </CardHeader>
                <CardBody>
                  {this.state.loaded && (
                    <div className='loaders'>
                      <img src={loader} />
                    </div>
                  )}

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        type='text'
                        labelText='Name'
                        onChange={this.handleName}
                        value={this.state.name}
                        id='name'
                        name='name'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Email'
                        id='email'
                        name='email'
                        onChange={this.handleEmail}
                        value={this.state.email}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Phone'
                        id='phone'
                        name='phone'
                        onChange={this.handlePhone}
                        value={this.state.phone}
                        type='text'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Address'
                        id='autocomplete'
                        name='address'
                        ref='input'
                        onChange={this.handleAddress}
                        value={this.state.address}
                        type='text'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Apt/Unit No.'
                        id='aptno'
                        name='aptno'
                        onChange={this.handleAptNo}
                        value={this.state.aptno}
                        type='text'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='City'
                        id='city'
                        name='city'
                        onChange={this.handleCity}
                        value={this.state.city}
                        type='text'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='State'
                        id='state'
                        name='state'
                        onChange={this.handleState}
                        value={this.state.state}
                        type='text'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Zipcode'
                        id='zipcode'
                        name='zipcode'
                        onChange={this.handleZipcode}
                        value={this.state.zipcode}
                        type='text'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Ach Company Name'
                        id='achCompanyName'
                        name='achCompanyName'
                        onChange={this.handleAchCompanyName}
                        value={this.state.achCompanyName}
                        type='text'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Ach Id'
                        id='achId'
                        name='achId'
                        onChange={this.handleAchId}
                        value={this.state.achId}
                        type='text'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className='custom-select'>
                        <FormControl>
                          <InputLabel id='demo-simple-select-tango'>
                            Tango Group
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-tango'
                            value={this.state.tango_group_id}
                            onChange={this.onTangoGroupChange}
                            name='group_id'
                            className='full-width-elem'>
                            {this.state.tangoGroups.map((obj, index) => (
                              <MenuItem value={obj && obj.id}>
                                {obj && obj.group_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className='custom-select'>
                        <FormControl>
                          <InputLabel id='demo-simple-select-tango-acc'>
                            Tango Account
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-tango-acc'
                            value={this.state.tango_account_id}
                            onChange={this.onTangoAccountChange}
                            name='group_id'
                            className='full-width-elem'>
                            {this.state.tangoAccounts.map((obj, index) => (
                              <MenuItem value={obj && obj.id}>
                                {obj && obj.account_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className='custom-select'>
                        <FormControl>
                          <InputLabel id='allow-address-update'>
                            Allow Address Update
                          </InputLabel>
                          <Select
                            labelId='allow-address-update'
                            value={this.state.allowAddressUpdate}
                            onChange={this.handleAddressUpdate}
                            name='allowAddressUpdate'
                            className='full-width-elem'>
                            <MenuItem value={'true'}>Yes</MenuItem>
                            <MenuItem value={'false'}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className='custom-select'>
                        <FormControl>
                          <InputLabel id='ach-method'>ACH Method</InputLabel>
                          <Select
                            labelId='ach-method'
                            value={this.state.achMethod}
                            onChange={this.handleAchMethod}
                            name='achMethod'
                            className='full-width-elem'>
                            <MenuItem value={'moov'}>Moov</MenuItem>
                            <MenuItem value={'default'}>Default</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className='custom-select'>
                        <FormControl>
                          <InputLabel id='demo-simple-select-moov'>
                            Moov Account
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-moov'
                            value={this.state.moov_account_id}
                            onChange={this.onMoonAccountChange}
                            name='group_id'
                            className='full-width-elem'>
                            {this.state.moovAccounts.map((obj, index) => (
                              <MenuItem value={obj && obj.id}>
                                {obj && obj.account_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className='custom-select'>
                        <FormControl>
                          <InputLabel id='demo-simple-select-moov-acc'>
                            Moov Payment Methods
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-moov-acc'
                            value={this.state.moov_payment_method_id}
                            onChange={this.onMoovPaymentMethodChange}
                            name='group_id'
                            className='full-width-elem'>
                            {this.state.moovPaymentMethods.map((obj, index) => (
                              <MenuItem value={obj && obj.id}>
                                {obj && obj.holder_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className='custom-select'>
                        <FormControl>
                          <InputLabel id='bank-name'>Bank Name</InputLabel>
                          <Select
                            labelId='bank-name'
                            value={this.state.bankName}
                            onChange={this.handleBankName}
                            name='bankName'
                            className='full-width-elem'>
                            {Object.values(bankNames).map((obj, index) => (
                              <MenuItem value={obj}>{obj}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className='custom-select'>
                        <FormControl>
                          <InputLabel id='demo-simple-select-org'>
                            Organizations
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-org'
                            value={this.state.organization_id}
                            onChange={this.onOrganizationIdChange}
                            name='organization_id'
                            className='full-width-elem'>
                            {this.state.organizations.map((obj, index) => (
                              <MenuItem value={obj && obj.id}>
                                {obj && obj.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Bank Account'
                        id='bankAcc'
                        name='bankAcc'
                        onChange={this.handleBankAcc}
                        value={this.state.bankAcc}
                        type='number'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Routing No'
                        id='routingNo'
                        name='routingNo'
                        onChange={this.handleRoutingNo}
                        value={this.state.routingNo}
                        type='number'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Bank Acc Alias'
                        id='bankAccAlias'
                        name='bankAccAlias'
                        onChange={this.handleBankAccAlias}
                        value={this.state.bankAccAlias}
                        type='text'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem style={{ alignSelf: 'end' }}>
                      <Button color='primary' onClick={this.openModal}>
                        Open Template Editor
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className='custom-upload m-b-30 m-t-27'>
                        <label htmlFor='logoimg'>Upload Facility Logo</label>
                        <br />
                        <label htmlFor='logoimg' className='custom-upload-btn'>
                          <span>Browse</span>
                          <CustomInput
                            label='Upload Facility Logo'
                            id='logoimg'
                            name='logoimg'
                            onChange={this.handleUploadFile}
                            type='file'
                            accept='image/*'
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </label>
                      </div>
                      {this.state && this.state.fileimg ? (
                        <div className='preview-image' width='130'>
                          <img
                            src={this.state && this.state.fileimg}
                            width='130'
                          />
                        </div>
                      ) : (
                        ''
                      )}

                      {this.state && this.state.fileimg ? (
                        <span
                          className='material-icons'
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.remove()}>
                          delete
                        </span>
                      ) : (
                        ''
                      )}
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <div className='custom-upload m-b-30 m-t-27'>
                        <label htmlFor='signature'>Upload Signature</label>
                        <br />
                        <label
                          htmlFor='signature'
                          className='custom-upload-btn'>
                          <span>Browse</span>
                          <CustomInput
                            label='Upload Signature'
                            id='signature'
                            name='signature'
                            onChange={this.handleSignatureFile}
                            type='file'
                            accept='image/*'
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </label>
                      </div>
                      {this.state && this.state.signatureImg ? (
                        <div className='preview-image' width='130'>
                          <img
                            src={this.state && this.state.signatureImg}
                            width='130'
                          />
                        </div>
                      ) : (
                        ''
                      )}

                      {this.state && this.state.signatureImg ? (
                        <span
                          className='material-icons'
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.removeSignature()}>
                          delete
                        </span>
                      ) : (
                        ''
                      )}
                    </GridItem>
                  </GridContainer>
                  {this.state.message && (
                    <GridContainer>
                      <GridItem xs={8} sm={8} md={6}>
                        <p style={{ color: 'red', padding: '10px 0' }}>
                          {this.state.message}
                        </p>
                      </GridItem>
                    </GridContainer>
                  )}
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <Button color='primary' type='submit'>
                        Create
                      </Button>
                      <Button
                        color='primary'
                        onClick={this.cancelfrm}
                        className='btn-cancel'
                        style={{ marginLeft: '5px' }}>
                        Cancel
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}></GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </form>
        <TemplateModal
          defaultValues={this.state.pdfTemplate}
          onPdfTemplateSave={this.onPdfTemplateSave}
          templateModal={this.state.templateModal}
          closeModal={this.closeModal}
        />
      </div>
    );
  }
}
