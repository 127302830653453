import {
  AccountBalanceOutlined,
  AccountBalanceWalletOutlined,
  ApartmentOutlined,
  OutdoorGrill,
  OutlinedFlagOutlined,
} from '@material-ui/icons';
import Card from 'components/Card/Card';
import { Link } from 'react-router-dom';

export default function TangoPage() {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  };
  return (
    <div style={{ display: 'flex', gap: '20px' }}>
      <Link style={{ width: '100%' }} to={'/admin/createTangoGroup'}>
        <Card style={style}>
          <ApartmentOutlined style={{ fontSize: '100px' }} />
          <h1> Create Tango Group </h1>
          <p> This is the Create Tango Group page </p>
        </Card>
      </Link>
      <Link style={{ width: '100%' }} to={'/admin/createTangoAccount'}>
        <Card style={style}>
          <AccountBalanceWalletOutlined style={{ fontSize: '100px' }} />
          <h1> Create Tango Account </h1>
          <p> This is the Create Tango Account page </p>
        </Card>
      </Link>
    </div>
  );
}
