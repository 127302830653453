import React, { useState } from 'react'
import Button from 'components/CustomButtons/Button.js';
import { Card, CardContent, styled, TextField, Typography } from '@material-ui/core';
import axios from 'axios';
import { API_BASE_URL } from 'config';
const CssTextField = styled(TextField, {
	shouldForwardProp: (props) => props !== "focusColor"
})((p) => ({
	// input label when focused
	"& label.Mui-focused": {
		color: p.focusColor
	},
	// focused color for input with variant='standard'
	"& .MuiInput-underline:after": {
		borderBottomColor: p.focusColor
	},
	// focused color for input with variant='filled'
	"& .MuiFilledInput-underline:after": {
		borderBottomColor: p.focusColor
	},
	// focused color for input with variant='outlined'
	"& .MuiOutlinedInput-root": {
		"&.Mui-focused fieldset": {
			borderColor: p.focusColor
		}
	}
}));
const UnsubscribeEmail = ({ match }) => {
	const [success, setSuccess] = useState(false)
	const [value, setValue] = useState('');

	const handleChange = (event) => {
		setValue(event.target.value);
	};

	const id = match.params.id
	const unsubscribeEmail = () => {
		const formData = new FormData();
		formData.append('value', value);	

		axios
			.post(API_BASE_URL + `/unsubscribe-email/${id}`, formData).then(result => {
				// console.log(result);
				setSuccess(result.data.success)
			})
	}
	return (
		<div style={{ left: '50%', top: '50%', transform: 'translate(-50%,-50%)', position: 'fixed', fontFamily: 'Roboto' }}>
			<Card style={{ minWidth: '400px', width: '60vw', padding: '20px' }}>
				<CardContent>
					<Typography style={{ fontSize: '20px', color: 'rgb(2 42 2)' }} gutterBottom>
						Are you sure you want to unsubscribe? You will not receive any more emails related to refund in the future.
					</Typography>
					<div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', columnGap: '20px' }}>
						<Typography style={{ fontSize: '17px' }} variant="p" component="div">
							Tell us how we can improve before unsubscribing:
						</Typography>

						<CssTextField
							style={{ width: '50%' }}
							id="outlined-success"
							multiline
							maxRows={4}
							label="feedback"
							variant="outlined"
							focusColor="#3AB795"
							value={value}
							onChange={handleChange}
						/>
					</div>

				</CardContent>
				{success ? <Typography style={{ fontSize: '20px',marginLeft:'20px', color: 'rgb(2 42 2)' }} gutterBottom>
					You have successfully unsubscribed. No further action is needed.
				</Typography> : null}

				<CardContent style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '20px' }}>
					<div style={{ display: 'flex', columnGap:'30px'}}>
						<Button color="primary" disabled={success} type="submit" onClick={unsubscribeEmail}>
							{success ? 'Done' : 'Unsubscribe'}
						</Button>

						<Button color="danger" type="submit" onClick={() => window.location.href = 'https://www.clientpaydirect.com'}>
							Cancel
						</Button>
					</div>
					
				</CardContent>
				<Typography style={{ fontSize: '13px',fontStyle:'italic',marginTop:'20px' }} variant="p" component="div">
					*By unsubscribing you are authorizing Client Pay Direct to escheat any money owed to you, to the appropriate state.No further action is required.
				</Typography>
			</Card>

		</div>


	)
}

export default UnsubscribeEmail