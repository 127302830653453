import React, { useState, useEffect } from 'react';

import { CSVLink } from 'react-csv';
import { API_BASE_URL, API_BASE_IMG_URL } from '../../config';
import axios from 'axios';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';

// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';

import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import Button from 'components/CustomButtons/Button.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import search from '../../assets/img/search.svg';
import moment from 'moment';
import {
  AddComment,
  CardGiftcardOutlined,
  RefreshOutlined,
} from '@material-ui/icons';
import { Modal } from '@mui/material';
import { Box } from '@material-ui/core';
import { modalStyle } from 'views/Clients/Claim';
import BarChart from './BarChart';
import QuarterBarChart from './QuarterBarChart';
import DoughnutChart from './DoughnutChart';
import { usePostHog } from 'posthog-js/react';
import { getBusinessUnits } from 'views/Facilities/BusinessUnits';
import { BUSINESS_UNITS_FEATURE_FLAG } from 'utility';

const options = {
  scales: {
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: 'Y text',
        },
      },
    ],
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: 'X text',
        },
      },
    ],
  },
};
const state2 = {
  labels: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ],
  datasets: [
    {
      label: 'Refund sent vs refund claimed graph',
      backgroundColor: '#3AB795',
      borderWidth: 2,
      data: [65, 59, 80, 81, 10, 20, 40, 200, 50, 70, 80, 90],
    },
  ],
};

const useStyles = makeStyles(styles);

export default function Facilitydashboard() {
  const posthog = usePostHog();
  const [businessUnits, setBusinessUnits] = useState([]);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState('');
  useEffect(async () => {
    setBusinessUnits(await getBusinessUnits());
  }, []);

  const classes = useStyles();
  const dates = new Date();
  const [count, setCount] = useState(null);

  const [startdate, setStartdate] = useState(
    moment().subtract(1, 'month').toDate()
  );
  const [enddate, setEnddate] = useState(new Date());
  const [subfacility, setSubfacility] = useState(null);

  const [tangoBalance, setTangoBalance] = useState(null);
  const [radiovalue, setRadiovalue] = useState('transaction');
  const [isModalOpen, setIsModalOpen] = useState(false);

  // useState hooks for graphs
  const [refundMethodsTransaction, setRefundMethodsTransaction] = useState([]);
  const [subFacilityID, setSubFacilityID] = useState(null);
  const [account, setAccount] = useState('');
  const [pieChartData, setPieChartData] = useState({
    claimed: 0,
    unclaimed: 0,
    escheated: 0,
  });
  const [moovAccount, setMoovAccount] = useState(null);

  const handleBarClick = (element, id) => {
    console.log(`The bin ${element.text} with id ${id} was clicked`);
  };

  const handleClicks = (event) => {
    setRadiovalue(event.target.value);
  };
  /************************** Change start date *********************/
  const changeDate = (event) => {
    console.log(event);
    setStartdate(event);
  };

  /**************************  Change end date **************************/
  const changeEndDate = (event) => {
    console.log(event);
    setEnddate(event);
  };

  const handleSubFacility = (e) => {
    if (e.target.value === 'null') {
      setSubFacilityID(null);
    }

    setSubFacilityID(e.target.value);
  };
  //  function to format the  start and end dates
  const getTangoBalance = async () => {
    const account = (await axios.get(API_BASE_URL + '/getTangoAccount')).data;
    console.log(account);
    setAccount(account.accountNumber);
    setTangoBalance(account.currentBalance);
  };

  useEffect(() => {
    getTangoBalance();
  }, []);

  const formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  // Search function used to make API calls to backend

  const searchReport = () => {
    if (startdate > enddate) {
      alert('Start date should be less than end date');
    } else {
      // sending the formdata with ID to backend and recieving respective data.
      axios
        .post(API_BASE_URL + '/search_refund_report', {
          start_date: startdate,
          end_date: enddate,
          business_unit_id: selectedBusinessUnit,
        })
        .then((res) => {
          setRefundMethodsTransaction(res.data.countAndTotalPerMethod);
          setPieChartData(res.data.totals);
          console.log(res.data);
        })
        .catch((err) => {
          console.log('request error ' + err.message);
        });
    }
  };

  useEffect(() => {
    let state = localStorage['appState'];
    let AppState;
    if (state) {
      AppState = JSON.parse(state);
    }
    let token = AppState && AppState.user && AppState.user.accessToken;
    if (AppState && AppState.user && AppState.user.admin_type == 'admin') {
      window.location.href = '/client/error';
    }

    let slug = AppState && AppState.user && AppState.user.id;

    if (!slug) return (window.location.href = '/user/facilitylogin');

    searchReport();
  }, [count]);

  // header for CSV file graphs.
  const headers1 = [
    { label: 'Refund Type', key: 'method' },
    { label: 'No. of Transaction', key: 'count' },
    { label: 'Refunded Amount', key: 'amount' },
  ];
  const headers2 = [
    {
      label: 'Month',
      key: 'month',
    },
    {
      label: 'Refund Sent',
      key: 'refundSent',
    },
    {
      label: 'Refund Claimed',
      key: 'refundClaim',
    },
  ];
  const headers3 = [
    { label: 'Refund Claimed', key: 'claimed' },
    { label: 'Refund UnClaimed', key: 'unclaimed' },
    { label: 'Refund Escheated', key: 'escheated' },
  ];

  // input data for CSV file
  const csvReport1 = {
    data: refundMethodsTransaction,
    headers: headers1,
    filename: 'Distribution_of_refund_methods.csv',
  };
  const csvReport3 = {
    data: [pieChartData],
    headers: headers3,
    filename: 'Refund_Claim_VS_Refund_UnClaim.csv',
  };
  const getTOosToken = async () => {
    try {
      const response = await axios.get('https://api.moov.io/tos-token');

      const res = await axios.post(API_BASE_URL + '/acceptToc', {
        token: response.data.token,
      });

      setMoovAccount(res.data.moovAccount);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(async () => {
    const response = await axios.get(
      API_BASE_URL + '/isMoovTermsAndConditionsAccepted'
    );
    setMoovAccount(response.data);
  }, []);

  return (
    <>
      {/* {moovAccount?.is_terms_and_condition === true ? (
        <div
          style={{
            margin: '0 auto',
            marginBottom: '20px',
            position: 'relative',
            bottom: '40px',
            maxWidth: '90%',
            background: '#fff',
            padding: '10px 30px',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;',
            color: '#000',
            fontSize: '12px',
            borderRadius: '5px',
          }}>
          {console.log('trueeee')}
          Note: Moov's Terms and Conditions have been accepted by the user with
          email "{moovAccount.accepted_by}" at{' '}
          {moment(moovAccount.accepted_at).format('MM-DD-YYYY hh:mm')}.
        </div>
      ) : (
        ''
      )} */}
      {moovAccount?.is_terms_and_condition === false && (
        <div
          style={{
            width: '500px',
            background: '#fff',
            padding: '10px 30px',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;',
            display: 'flex',
            gap: '20px',
            position: 'fixed',
            top: '60px',
            right: '20px',
            color: '#000',
            zIndex: 10,
            fontSize: '12px',
            borderRadius: '5px',
          }}>
          <p>
            By clicking agree you are agreeing to the Moov's{' '}
            <b>
              <a
                target='_blank'
                style={{ cursor: 'pointer' }}
                href='https://moov.io/legal/platform-agreement/'>
                Terms of Service
              </a>
            </b>
          </p>
          <Button variant='contained' color='primary' onClick={getTOosToken}>
            I agree
          </Button>
        </div>
      )}
      <div className='facilityDashboard'>
        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color='warning' stats icon>
                <CardIcon color='warning'>
                  <CardGiftcardOutlined />
                </CardIcon>
                <p className={classes.cardCategory}>
                  GiftCard & Donation Balance
                </p>
                <h4 className={classes.cardTitle}>
                  {/* balance should have commas using internaltional api */}
                  <small>
                    $
                    {parseFloat(tangoBalance ?? 0).toLocaleString('us', {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </small>
                </h4>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <h5 style={{ cursor: 'pointer' }} onClick={getTangoBalance}>
                    Reload
                    <RefreshOutlined />
                  </h5>
                </div>
                <div className={classes.stats}>
                  <h5
                    style={{ cursor: 'pointer' }}
                    onClick={() => setIsModalOpen(true)}>
                    Replenish Balance
                    <AddComment />
                  </h5>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer className='filterCardContainer'>
          <GridItem xs={12} sm={12} md={12} lg={9}>
            <Card className='filterCard'>
              <p className='filter_txt'>Filter By:</p>
              <div>
                {' '}
                <div className='t-header search-facility'>
                  <div className='t-header-left'>
                    From:&nbsp;&nbsp;
                    <DatePicker
                      className='full-width-elem-date-picker'
                      id='dt'
                      selected={startdate}
                      onChange={changeDate}
                    />
                  </div>
                  <div className='t-header-left'>
                    To:&nbsp;&nbsp;
                    <DatePicker
                      className='full-width-elem-date-picker'
                      id='dt'
                      selected={enddate}
                      onChange={changeEndDate}
                    />
                  </div>

                  {posthog.isFeatureEnabled(BUSINESS_UNITS_FEATURE_FLAG) && (
                    <div className='t-header-right'>
                      <div>
                        <select
                          id='status'
                          className='full-width-elem'
                          onChange={(e) =>
                            setSelectedBusinessUnit(e.target.value)
                          }>
                          <option value={''}>Business Unit</option>
                          {businessUnits.map((obj) => (
                            <option value={obj.id} key={obj.id}>
                              {obj.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                  <div className='search_t_head'>
                    <Button
                      type='button'
                      color='primary'
                      onClick={searchReport}>
                      <img src={search} className='search_ico' />
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={6}>
            <Card className='dashboardCard'>
              <h3 className='chart_heads'>Distribution of Refund Methods</h3>
              <div style={{ width: '100%', height: '80%', paddingTop: '10%' }}>
                <BarChart
                  showCount={radiovalue === 'transaction'}
                  data={refundMethodsTransaction}
                />
              </div>

              <div className='dashboardRadio'>
                <div style={{ fontSize: '12px' }}>
                  <input
                    type='radio'
                    value='transaction'
                    onChange={handleClicks}
                    checked={radiovalue == 'transaction'}
                    name='radiocheck'
                  />{' '}
                  Number of Transactions
                </div>{' '}
                <div style={{ fontSize: '12px' }}>
                  <input
                    type='radio'
                    value='refund'
                    checked={radiovalue == 'refund'}
                    onChange={handleClicks}
                    name='radiocheck'
                  />{' '}
                  Amount by Refund
                </div>
              </div>
              <CSVLink
                {...csvReport1}
                style={{ cursor: 'pointer' }}
                className='dashboardBtn'>
                Download Report
              </CSVLink>
            </Card>
          </GridItem>

          {/* third graph component Refund claimed VS unclaimed  */}
          <GridItem xs={12} sm={6}>
            <Card className='dashboardCard'>
              <h3 className='chart_heads'>Refunded Vs Unclaimed($)</h3>

              <div
                style={{
                  width: '100%',
                  position: 'relative',
                  height: '80%',
                  paddingTop: '10%',
                }}>
                <DoughnutChart
                  claimed={pieChartData.claimed}
                  unclaimed={pieChartData.unclaimed}
                  escheated={pieChartData.escheated}
                />
              </div>
              <CSVLink
                {...csvReport3}
                style={{ cursor: 'pointer' }}
                className='dashboardBtn'>
                Download Report
              </CSVLink>
            </Card>
          </GridItem>
        </GridContainer>
        <Modal
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}>
          <Box
            sx={modalStyle}
            className='makeStyles-card-96'
            noValidate
            autoComplete='off'>
            <CardHeader
              style={{ transform: 'translateY(-50%)' }}
              color='primary'>
              <h4>Replenish Gift Card Balance</h4>
            </CardHeader>

            <CardBody style={{ marginTop: '-20px' }}>
              <b>
                Wells Fargo Bank <br />
                ABA routing #: 121000248 <br />
                Bank Account #: 4945710978 <br />
                Beneficiary: Tango Card <br />
                Physical Address: 4700 42nd Ave. S.W., Suite 430a
                <br />
                Seattle, WA 98116
                <br /> <br />
                Please provide the following Tango account number in the memo
                section
                <br />
                Tango Account #: {account}
              </b>
            </CardBody>
          </Box>
        </Modal>
      </div>
    </>
  );
}
