import { Box, Modal, Dialog } from '@mui/material';
import Button from '../../components/CustomButtons/Button.js';

import axios from 'axios';
import { API_BASE_URL } from 'config';
import { Base64 } from 'js-base64';
import React, { useEffect, useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { modalStyle } from 'views/Clients/Claim';

import charityIcon from '../../assets/img/charity-icon.svg';
import tickmethod from '../../assets/img/tick-method.svg';
import CardBody from './CardBody';
import CardHeader from './CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import CustomInput from 'components/CustomInput/CustomInput';
import GridItem from 'components/Grid/GridItem.js';

const PaymentModal = ({ charity, open3, handleCancel, id }) => {
  const params = useParams();
  const slug = id ? Base64.encode(id) : params.id;
  const [charityId, setCharityId] = React.useState(null);
  const [redirect, setRedirect] = useState('');
  const [charities, setCharities] = useState([]);
  // const [otpToken, setOtpToken] = useState('');
  const otpToken = sessionStorage.getItem('otpForPayment');
  const [errorMsgDonation, setErrorMsgDonation] = useState('');
  const [msgDonation, setMsgDonation] = useState('');
  const [amount, setAmount] = useState(0);
  const [email, setEmail] = useState('');
  const [modalErr, setModalErr] = useState('');
  const [isEmailInputOpen, setIsEmailInputOpen] = useState(false);
  const [facilityName, setFacilityName] = useState('');
  const [checkConfirmation, setCheckConfirmation] = useState(false);
  const [isDocument, setIsDocument] = useState(false);
  const [emailInputs, setEmailInputs] = useState({
    newEmail: '',
    confirmEmail: '',
  });

  const handleInstant = () => {
    setRedirect('paymentdetail/');
  };
  const handleCheque = () => {
    setRedirect('chequedetail/');
  };
  const handleAmazonGiftCard = () => {
    if (!email) return;
    setRedirect('choose-giftcards/giftcards/');
  };

  const handleDonation = () => {
    setRedirect('choose-donations/donations/');
  };

  const handleCharityid = (event) => {
    setCharityId(event.target.value);
  };

  const handleDonationAOnSubmit = (charityId) => {
    const verificationToken = otpToken;
    if (!verificationToken) {
      return setErrorMsgDonation('Try again after sometime');
    }
    const formData = new FormData();
    formData.append(
      'charityName',
      charities.find((charity) => charity.id === +charityId).name
    );
    formData.append('verificationToken', verificationToken);
    axios
      .post(API_BASE_URL + '/add_donation/' + charityId + '/' + slug, formData)
      .then((response) => {
        localStorage.removeItem('otpForPayment');
        setMsgDonation(response.data?.message);
        setRedirect('thankyou/');
      })
      .catch((err) => {
        setErrorMsgDonation(err.response?.data?.message);
      });
  };

  const handleDonate = () => {
    if (!charityId) {
      setErrorMsgDonation('Please select a charity');
      return;
    }
    handleDonationAOnSubmit(charityId);
  };
  useEffect(() => {
    if (!open3) return;
    setIsDocument(sessionStorage.getItem('isDocument'));
    const otpForPayment = sessionStorage.getItem('otpForPayment');
    axios
      .post(API_BASE_URL + '/charitiesFromrefundId/' + slug, {
        verificationToken: otpForPayment,
      })
      .then((res) => {
        setCharities(res.data.charities);
        setEmail(res.data.refund.email);
        setFacilityName(res.data.refund.facility_name);
        setAmount(res.data.refund.refund_amount);
      });
  }, [open3]);

  const onChangeEmailInputs = (e, name) => {
    const { value } = e.target;

    setEmailInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateEmail = (e) => {
    e.preventDefault();
    if (
      emailInputs.newEmail !== emailInputs.confirmEmail &&
      emailInputs.newEmail
    ) {
      return setModalErr('Email does not match');
    }
    const formData = new FormData();
    formData.append('email', emailInputs.newEmail);
    formData.append('verificationToken', otpToken);
    axios
      .post(API_BASE_URL + '/updateRefundEmail/' + slug, formData)
      .then((res) => {
        setEmail(emailInputs.newEmail);
        setIsEmailInputOpen(false);
        localStorage.removeItem('zipCode');
      })
      .catch((err) => {
        setModalErr(err.response?.data?.message);
      });
  };
  const messageStyle = {
    textAlign: 'center',
    width: '100%',
    fontSize: '10px',
    fontWeight: 'normal',
    textTransform: 'none',
    lineHeight: '1.5',
  };

  const selectedCharity = charities.find(
    (charity) => charity.id === +charityId
  );
  const isAllowed = otpToken && isDocument != 'true';
  const style = (notAllowed = !isAllowed) => {
    return {
      ...(notAllowed
        ? {
            cursor: 'not-allowed',
            backgroundColor: 'grey',
          }
        : {}),
      ...{ outline: 'none', border: 'none' },
    };
  };
  const condition = !email && isDocument != 'true' && amount ? true : false;
  return (
    <>
      {redirect && <Redirect to={`/client/${redirect}${slug}`} />}
      {condition && <Redirect to={`/claim`} />}
      {email && !otpToken && amount && (
        <Redirect to={`/client/clientdetail/${slug}`} />
      )}
      <Dialog
        open={open3}
        maxWidth='md'
        scroll='body'
        aria-labelledby='form-dialog-title'
        className='otp-dialog big-dialog'>
        <div className='dialog-body'>
          <div className='dialog-inner-wrppaer'>
            <div className='dialog-inner-left method-banner'></div>
            <div className='dialog-inner-right'>
              <div className='dialog-right'>
                <h4 className='owned-amount'>
                  Your Refund is
                  <strong>
                    {' '}
                    $
                    {parseFloat(amount + '').toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </strong>
                </h4>
                <h4 className='method-type-heading'>
                  How would you like to receive your refund?
                </h4>
                <div className='all-methods'>
                  <div className='available-methods'>
                    <div className='method'>
                      {/* <div className='dialogue-wrapper'>
                        <div className='dialogue'>
                          <svg width='200' viewBox='0 0 472 460' fill='none'>
                            <path
                              d='M229.713 1.21436C198.794 2.52162 168.347 7.82873 140.112 16.8325C111.877 25.8364 86.406 38.3605 65.155 53.6894C43.9041 69.0184 27.2891 86.852 16.2591 106.171C5.22921 125.491 0.000430662 145.918 0.871484 166.285C2.41159 202.171 22.8003 236.142 58.5612 262.405C94.3221 288.667 143.241 305.596 196.977 310.304C213.482 368.488 166.06 416.526 93.4903 459.411C182.598 442.349 280.302 388.283 294.538 305.12C346.252 295.172 391.945 274.041 424.127 245.192C456.308 216.343 473.069 181.487 471.663 146.337C470.806 125.972 463.867 106.064 451.244 87.7485C438.62 69.4335 420.559 53.0708 398.092 39.5946C375.624 26.1184 349.19 15.7929 320.3 9.20754C291.409 2.62221 260.627 -0.0938857 229.713 1.21436Z'
                              fill='#86baa1'
                            />
                          </svg>
                          <p>Fastest ways to receive your refunds</p>
                        </div>
                      </div> */}
                      <p
                        style={{
                          color: '#fff',
                          marginTop: '-10px',
                          fontWeight: 'bold',
                          marginBottom: '10px',
                        }}>
                        {' '}
                        Fastest ways to receive your refunds{' '}
                      </p>
                      <div className='payment-method'>
                        <button
                          style={{ outline: 'none', border: 'none' }}
                          onClick={handleInstant}
                          className='method-type'>
                          <span>
                            <img src={tickmethod} alt='' />
                          </span>
                          <h5 style={{ textAlign: 'center', width: '100%' }}>
                            Direct Deposit to Checking
                            <p style={messageStyle}>(5-7 Business Days)</p>
                          </h5>
                        </button>
                      </div>
                      <div
                        className='payment-method'
                        title={
                          !isAllowed
                            ? 'This option is not available for letter based refunds.'
                            : ''
                        }>
                        <button
                          onClick={handleAmazonGiftCard}
                          className='method-type'
                          disabled={!isAllowed}
                          style={
                            !isAllowed
                              ? {
                                  cursor: 'not-allowed',
                                  backgroundColor: 'grey',
                                }
                              : {}
                          }>
                          <span>
                            <img src={tickmethod} alt='' />
                          </span>
                          <h5 style={{ textAlign: 'center', width: '100%' }}>
                            Gift Card{' '}
                            <p
                              style={{
                                ...messageStyle,
                                fontStyle: 'italic',
                              }}>
                              Quickest method of refund – receive gift card code
                              minutes after claiming your refund.
                            </p>
                          </h5>
                        </button>
                      </div>

                      <div
                        className='payment-method'
                        title={
                          !isAllowed
                            ? 'This option is not available for letter based refunds.'
                            : ''
                        }>
                        <button
                          style={style()}
                          disabled={!isAllowed}
                          onClick={handleDonation}
                          className='method-type'>
                          <span>
                            <img src={tickmethod} alt='' />
                          </span>
                          <h5 style={{ textAlign: 'center', width: '100%' }}>
                            Donate Refund to Charity
                          </h5>
                        </button>
                      </div>
                    </div>
                    <div style={{ padding: '10px 20px 0 20px' }}>
                      <div className='payment-method'>
                        <button
                          onClick={() => setCheckConfirmation(true)}
                          className='method-type'>
                          <span>
                            <img src={tickmethod} alt='' />
                          </span>
                          <h5 style={{ textAlign: 'center', width: '100%' }}>
                            Check by Mail
                            <p style={messageStyle}>(10-15 Business Days)</p>
                          </h5>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={charity}
        scroll='body'
        aria-labelledby='form-dialog-title'
        className='otp-dialog'>
        <div className='dialog-head'>
          <div className='dialog-icon'>
            <span>
              <img src={charityIcon} alt='' />
            </span>
          </div>
          <h3 className='dialog-title'>Donate to charity</h3>
          <p className='dialog-desc'>
            Pick a charity to donate your funds. Charities below are associated
            <br /> with {facilityName} and funds are directly transferred to the
            charity.
          </p>
        </div>
        <div className='dialog-body' style={{ padding: '35px' }}>
          {errorMsgDonation && (
            <p style={{ color: '#ff0000' }}>{errorMsgDonation}</p>
          )}
          {msgDonation && <p style={{ color: 'green' }}>{msgDonation}</p>}
          <div className='alternate-text'>
            {/* <p className="dialog-desc"><b>Charity</b>&nbsp;&nbsp;  <select id="donate" name="donate" style={{ width: "280px", height: "33px" }} onChange={handleCharityid}>
								<option value="">Please choose charity</option>
								{charities && charities.length > 0 && charities.map((obj, index) => (
									<option value={obj.id}>{obj.name}</option>
								))}
							</select></p> */}
            <div
              className='charity-selector'
              style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className='charity-selector-left'>
                  <label className='c-label'>Charity</label>
                </div>
                <div className='charity-selector-right'>
                  <div className='custom-dropdown'>
                    <select
                      id='donate'
                      name='donate'
                      onChange={handleCharityid}>
                      <option value=''>Please choose charity</option>
                      {charities &&
                        charities.length > 0 &&
                        charities.map((obj, index) => (
                          <option key={obj.id} value={obj.id}>
                            {obj.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>

              {selectedCharity && (
                <div>
                  <p
                    className='dialog-desc'
                    style={{ marginTop: '10px' }}
                    dangerouslySetInnerHTML={{
                      __html: selectedCharity?.description ?? '',
                    }}></p>
                  <p className='dialog-desc'>
                    <b>
                      <a href={selectedCharity?.url} target='_blank'>
                        {selectedCharity?.url ?? ''}
                      </a>
                    </b>
                  </p>
                </div>
              )}
            </div>

            <div className='v-action text-center'>
              <Button
                variant='contained'
                className='btn-solid mb-1'
                onClick={handleDonate}>
                Submit
              </Button>
              <Button
                variant='contained'
                className='ml-2 mb-1'
                onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <Modal open={isEmailInputOpen}>
        <form onSubmit={updateEmail}>
          <Box
            sx={modalStyle}
            className='makeStyles-card-96'
            noValidate
            autoComplete='off'>
            <CardHeader
              style={{ transform: 'translateY(-50%)' }}
              color='primary'>
              <h4>Enter Your Email</h4>
            </CardHeader>
            <CardBody style={{ marginTop: '-20px' }}>
              {modalErr && (
                <p
                  style={{
                    color: 'red',
                    padding: '0',
                    margin: 0,
                    fontSize: '15px',
                  }}>
                  {modalErr}
                </p>
              )}
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='email'
                    labelText='Email'
                    onChange={(e) => onChangeEmailInputs(e, 'newEmail')}
                    value={emailInputs.newEmail}
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='email'
                    labelText='Confirm Email'
                    onChange={(e) => onChangeEmailInputs(e, 'confirmEmail')}
                    value={emailInputs.confirmEmail}
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <div>
                <Button variant='contained' color='primary' type='submit'>
                  Update
                </Button>
              </div>
            </CardBody>
          </Box>
        </form>
      </Modal>

      <Modal
        open={checkConfirmation}
        onClose={() => setCheckConfirmation(false)}>
        <Box
          sx={{ ...modalStyle, maxWidth: '400px' }}
          className='makeStyles-card-96'
          noValidate
          autoComplete='off'>
          <CardHeader style={{ transform: 'translateY(-50%)' }} color='primary'>
            <h4>CHECK BY MAIL</h4>
          </CardHeader>
          <CardBody style={{ marginTop: '-20px' }}>
            {modalErr && (
              <p
                style={{
                  color: 'red',
                  padding: '0',
                  margin: 0,
                  fontSize: '15px',
                }}>
                {modalErr}
              </p>
            )}
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <p>
                  It can take up to 15 days to receive your refund. Do you want
                  to continue?
                </p>
              </GridItem>
            </GridContainer>
            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'center',
                gap: '20px',
              }}>
              <Button
                onClick={handleCheque}
                style={{ backgroundColor: '#86baa1', color: '#fff' }}
                variant='contained'
                color='warning'
                type='submit'>
                Continue
              </Button>
              <Button
                style={{ maxWidth: '50%' }}
                onClick={() => setCheckConfirmation(false)}
                variant='contained'
                color='primary'
                type='submit'>
                No, I will pick a faster method
              </Button>
            </div>
          </CardBody>
        </Box>
      </Modal>
    </>
  );
};

export default PaymentModal;
