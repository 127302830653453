import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import DatePicker from 'react-datepicker';

import { API_BASE_URL, API_BASE_IMG_URL } from '../../config';
// @material-ui/core components
import { makeStyles, useTheme } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import MenuItem from '@material-ui/core/MenuItem';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import GridContainer from 'components/Grid/GridContainer.js';
// import Table from "components/Table/Table.js";
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import Button from 'components/CustomButtons/Button.js';
import loader from '../../assets/img/loader.svg';
// components for table.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';
import { getFacilities } from 'views/Facilities/Facilities';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

//const useStyles = makeStyles(styles);
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'>
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'>
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'>
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'>
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default class Refunds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [{}],
      refundsdata: [{}],
      template_delete_count: [{}],
      facilities: [{}],
      startDate: '',
      endDate: '',
      loaded: false,
      page: 0,
      status: '',
      facility: '',
      rowsPerPage: 10,
    };

    this.selectFacility = this.selectFacility.bind(this);
    this.changeDate = this.changeDate.bind(this);
    this.changeEndDate = this.changeEndDate.bind(this);
    this.selectstatus = this.selectstatus.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleRowsPerPage = this.handleRowsPerPage.bind(this);
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  getRefunds() {
    this.setState({ loaded: true });
    const formData = new FormData();
    formData.append('startDate', this.state.startDate);
    formData.append('endDate', this.state.endDate);
    formData.append('status', this.state.status);
    formData.append('facility', this.state.facility);

    axios
      .post(API_BASE_URL + '/refundlist', formData)
      .then((refund) => {
        this.setState({
          refundsdata: refund && refund.data && refund.data.data,
          loaded: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  selectstatus = (event) => {
    this.setState({ status: event.target.value }, () => {
      this.getRefunds();
    });
  };
  /*** Change start date */
  changeDate(event) {
    this.setState({ startDate: event ?? '' }, () => {
      this.getRefunds();
    });
  }

  /*** Change end date */
  changeEndDate(event) {
    this.setState({ endDate: event ?? '' }, () => {
      this.getRefunds();
    });
  }

  selectFacility(event) {
    this.setState({ facility: event.target.value }, () => {
      this.getRefunds();
    });
  }

  //   functions for table pagination
  handleChangePage(event, newPage) {
    this.setState({ page: newPage });
  }
  handleRowsPerPage(event) {
    let rows = parseInt(event.target.value);
    this.setState({ rowsPerPage: rows });
    this.setState({ page: 0 });
  }

  componentDidMount() {
    this.setState({ loaded: true });
    this.getRefunds();
    getFacilities({ facility: true })
      .then((facility) => {
        //console.log("facilities",facility && facility.data)
        this.setState({ facilities: facility && facility.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // const classes = useStyles();
  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color='primary'>
              <h4>Refunds</h4>
            </CardHeader>

            <CardBody>
              <div>
                {this.state.loaded && (
                  <div className='loaders'>
                    <img src={loader} />
                  </div>
                )}
                <div className='t-header'>
                  <div className='t-header-left'>
                    From:&nbsp;&nbsp;
                    <DatePicker
                      className='full-width-elem-date-picker'
                      id='dt'
                      autoComplete='off'
                      selected={this.state.startDate}
                      onChange={this.changeDate}
                    />
                  </div>
                  <div className='t-header-left'>
                    To:&nbsp;&nbsp;
                    <DatePicker
                      className='full-width-elem-date-picker'
                      id='dt'
                      autoComplete='off'
                      selected={this.state.endDate}
                      onChange={this.changeEndDate}
                    />
                  </div>

                  <div className='t-header-right'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          id='status'
                          className='full-width-elem'
                          onChange={this.selectstatus}>
                          <option value=''>Choose Payment Status</option>
                          <option value='Completed'>Completed</option>
                          <option value='Pending'>Pending</option>
                          <option value='Pending-sent'>Pending-sent</option>
                          <option value='Escheated'>Escheated</option>
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>

                  <div className='t-header-right'>
                    <div className='custom-search-box'>
                      <div className='custom-select'>
                        <select
                          id='Facility'
                          className='full-width-elem'
                          onChange={this.selectFacility}>
                          <option value=''>Facilities</option>
                          {this.state.facilities &&
                            this.state.facilities.length > 0 &&
                            this.state.facilities.map((obj, index) => (
                              <option key={index} value={obj && obj.id}>
                                {obj && obj.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <span className='search-icon'></span>
                    </div>
                  </div>
                </div>
                <Table width='100%' id='template'>
                  <TableHead>
                    <TableRow>
                      <TableCell align='left'>Name</TableCell>
                      <TableCell align='left'>Email</TableCell>
                      <TableCell align='left'>Phone</TableCell>
                      <TableCell align='left'>Refund Amount($)</TableCell>
                      <TableCell align='left'>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {this.state.refundsdata &&
                  this.state.refundsdata.length > 0 ? (
                    <TableBody>
                      {(this.state.rowsPerPage > 0
                        ? this.state.refundsdata.slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          )
                        : this.state.refundsdata
                      ).map((obj, index) => (
                        <TableRow className='txtalign' key={index}>
                          <TableCell align='left'>
                            {obj && obj.first_name} &nbsp;
                            {obj && obj.last_name}
                          </TableCell>
                          <TableCell align='left'>{obj && obj.email}</TableCell>
                          <TableCell align='left'>{obj && obj.phone}</TableCell>
                          <TableCell align='left'>
                            {/* {obj && obj.refund_amount} */}
                            {parseFloat(
                              obj && obj.refund_amount
                            ).toStringtoLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}
                          </TableCell>
                          <TableCell align='left'>
                            <Link
                              to={`/admin/viewrefund/${obj.id}`}
                              title='Update Charity'>
                              <span className='material-icons'>
                                remove_red_eye
                              </span>
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell>No Refund Data Found</TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  <TableFooter>
                    <TableRow align='center'>
                      <TablePagination
                        rowsPerPageOptions={[
                          5,
                          10,
                          25,
                          { label: 'All', value: -1 },
                        ]}
                        colSpan={3}
                        count={this.state.refundsdata.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        SelectProps={{
                          inputProps: { roboto: 'rows per page' },
                          native: true,
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
